import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { CreatePortfolioMain } from 'widgets/CreatePortfolio';
import PortfolioRouter from './PortfolioRouter';
import CreateNewPortfolioRouter from './pages/CreateNewPortfolio/Router';
import { CommonAllocationProvider } from './context/CommonAllocationDataProvider';

const Router = () => (
    <CommonAllocationProvider>
        <Routes>
            <Route path=":portfolioId/*" element={<PortfolioRouter/>} />
            {globalThis.AppConfig.ADVISORY_PROCESS_FEATURE_FLAG === 'true' ? (
                <>
                    <Route path="new-portfolio/*" element={<CreatePortfolioMain/>}/>
                    <Route
                        path=""
                        element={<Navigate to="new-portfolio" replace />}
                    />
                </>
            ) : (
                <>
                    <Route path="new-portfolio-legacy/*" element={<CreateNewPortfolioRouter/>} />
                    <Route
                        path=""
                        element={<Navigate to="new-portfolio-legacy" replace />}
                    />
                </>
            )}
            <Route
                path="*"
                element={<Navigate to="/404" replace />}
            />
        </Routes>
    </CommonAllocationProvider>
);

Router.displayName = 'Router';

export default Router;
