import { adaptAnswers } from 'adaptors/adaptAnswers';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import { useCreatePortfolioSelectors, useCreatePortfolioState } from 'datasource/CreatePortfolio';
import HandlerError from 'errors/HandlerError';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useClientProfile } from 'prodivers/clientProfile';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Infobox } from 'ui-library';
import { getClientId } from 'utils';
import { removeNonUniqueEntries } from 'utils/formatting';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptQualityCheckQuestionnaire } from 'widgets/QualityCheck/adapters/adaptQuestionnaire';
import { formDataSelector, questionnaireSelector } from './selectors';
import { useSelector } from './useSelector';
import useQualityCheckQuestionnaire from './hooks/useQualityCheckQuestionnaire';

function QualityCheck() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);
    const { validateQuestionnaire } = useQualityCheckQuestionnaire();

    const { t } = useTranslation();

    // Client Provider
    const { ...information } = useClientProfile();
    const {
        currentInvestmentApplicationId,
        applications,
    } = useCreatePortfolioState();
    const {
        isAdvisoryProcessSelector,
        is3bProductGroupSelector,
        containerIdSelector,
        selectedProductSelector,
        isJointAccountSelector,
        targetInvestorProfileClientSelector,
        targetInvestorProfileOtherClientSelector,
    } = useCreatePortfolioSelectors();
    const [pageErrors, setPageErrors] = useState();
    const {
        data, isLoading, getQuestionnaire,
        isLoadingPost, postQuestionnaire, error: postuseQuestionnaireError,
    } = useSelector(questionnaireSelector);
    const qualityCheckProfileGroup = data?.Groups?.[1];
    const groupId = qualityCheckProfileGroup?.Id;
    const {
        data: formData, initializeData, saveData, isInitDone,
    } = useSelector(formDataSelector);

    const answersToValidate = groupId ? formData[groupId] || {} : Object.values(formData || {}).reduce((acc, group) => ({ ...acc, ...group }), {});
    const financialServiceQuestion = useMemo(
        () => qualityCheckProfileGroup?.Steps?.[0].Questions?.find(
            ({ Type }) => (Type === 'SingleAnswer'),
        ),
        [qualityCheckProfileGroup?.Steps?.[0].Questions],
    );

    const isOthersSelected = answersToValidate?.[238]?.includes(766);
    const filteredQuestionnaireBasedOnProduct = useMemo(() => {
        const questionsToHide = [];

        if (!isOthersSelected) {
            questionsToHide.push(258);
        }

        if (['3a', 'FZ']?.includes(selectedProductSelector?.settings?.productKey)) {
            questionsToHide.push(259, 261);
        }
        if (['FZP']?.includes(selectedProductSelector?.settings?.productKey)) {
            questionsToHide.push(259, 260, 261);
        }

        if (is3bProductGroupSelector) {
            const questionsToExcludeFor3b = {
                ZIFD: [259, 261],
                ZIVV: [260, 261],
                AZP: [260, 261],
                AZPI: [260, 261],
                AZPD: [260, 261],
                ZIC: [259, 260],
                PK: [259, 260, 261],
            };
            const validApplications = Object.keys(applications)?.filter(app => app !== 'undefined');
            const questionIdsToHide = validApplications?.map(key => questionsToExcludeFor3b[applications?.[key]?.investmentDescription?.productExternalId])?.flat();

            questionsToHide.push(...removeNonUniqueEntries(questionIdsToHide, validApplications?.length));
        }
        const newData = JSON.parse(JSON.stringify(data?.Groups?.[1] || ''));

        newData?.Steps?.forEach(step => {
            step.Questions = step?.Questions?.filter(question => !questionsToHide?.includes(question?.Id));
        });

        return newData;
    }, [data?.Groups?.[1], selectedProductSelector, applications, is3bProductGroupSelector, isOthersSelected]);

    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(
        () => validateQuestionnaire({ data: filteredQuestionnaireBasedOnProduct, answersToValidate }),
        [answersToValidate, groupId, validateQuestionnaire, filteredQuestionnaireBasedOnProduct],
    );

    useEffect(() => {
        if (containerIdSelector) {
            getQuestionnaire({
                id: is3bProductGroupSelector ? containerIdSelector : currentInvestmentApplicationId,
                isContainer: is3bProductGroupSelector,
                ...{ adapter: adaptQualityCheckQuestionnaire },
            });
        }
    }, [getQuestionnaire, currentInvestmentApplicationId, containerIdSelector, is3bProductGroupSelector]);
    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data.Groups.map(({ Id }) => Id);

            initializeData({ groupIds });
        }
    }, [data, formData, initializeData]);
    useEffect(() => {
        if (isInitDone) {
            const questions = [];

            if (!financialServiceQuestion?.Value?.Ids?.length) {
                const answerIdToSelect = isAdvisoryProcessSelector ? 1 : 0;

                questions.push({ questionId: financialServiceQuestion?.Id, value: financialServiceQuestion?.Answers[answerIdToSelect].Id });
            }

            if (questions?.length) {
                saveData({ groupId, questions });
            }
        }
    }, [
        isInitDone,
        groupId,
        isAdvisoryProcessSelector,
    ]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            setSending(true);
            try {
                if (!isOthersSelected) {
                    formData[groupId][258] = null;
                }
                const answers = adaptAnswers({ data, values: formData });

                await postQuestionnaire({
                    answers,
                    id: is3bProductGroupSelector ? containerIdSelector : currentInvestmentApplicationId,
                    isContainer: is3bProductGroupSelector,
                });
                stepComplete();
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading: setSending,
                    }),
                )(err);
                setPageErrors();
            } finally {
                setSending(false);
                setIsSubmitted(false);
            }
        }
    }, [
        allQuestionsFilled, clientId, data,
        formData, postQuestionnaire, is3bProductGroupSelector,
        containerIdSelector, currentInvestmentApplicationId, groupId, isOthersSelected,
    ]);

    const onChange = useCallback(({ questionId, value }) => {
        saveData({ groupId, questions: [{ questionId, value }] });
    }, [groupId, saveData]);

    useEffect(() => {
        setPageErrors(postuseQuestionnaireError);
    }, [postuseQuestionnaireError]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        navigationDisabled: information?.isLoading || isLoading || isLoadingPost || isSending,
        pageErrors,
    });

    return (
        <Preloader
            isLoading={information?.isLoading || isLoading || isLoadingPost || isSending}
        >
            <div className="new-portfolio-content">
                <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                    <QuestionnaireGroup
                        data={filteredQuestionnaireBasedOnProduct}
                        title={data?.Groups?.[1]?.Name}
                        value={formData?.[groupId] || {}}
                        errors={isSubmitted ? errors : {}}
                        onChange={onChange}
                    />
                </EmptyContent>

                {isJointAccountSelector && (
                    <Infobox className="ja-message">
                        {t('portfolio.createNewPortfolio.QualityCheck.jaInfoMessage', { clientFullName: targetInvestorProfileOtherClientSelector?.name, clientOwnerFullName: targetInvestorProfileClientSelector?.name })}
                    </Infobox>
                )}
            </div>
        </Preloader>
    );
}

export default QualityCheck;
