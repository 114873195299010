import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stepper } from '../../core/stepper';
import { stepsKeys, useCreatePortfolioFlow } from './useCreatePortfolioFlow';
import { PortfolioProcessLayoutManager } from '../../datasource/PortfolioProcessLayout';
import { useCreatePortfolioLayoutProcess } from '../../hooks/adaptors/useCreatePortfolioLayoutProcess';
import { PortfolioProcessLayout } from '../../components/PortfolioProcessLayout';
import { useClientLayout } from '../../pages/ClientOverview/hooks/useClientLayout';
import { useCreatePortfolioSelectors, useCreatePortfolioState } from '../../datasource/CreatePortfolio';
import { useClientProfile } from '../../prodivers/clientProfile';

export const CreatePortfolio = () => {
    const { t, i18n: { language } } = useTranslation();
    const navigate = useNavigate();
    const {
        clientName,
    } = useClientProfile();
    const {
        currentInvestmentApplicationSelector,
    } = useCreatePortfolioSelectors();
    const { stepsBasedOnState } = useCreatePortfolioFlow();
    const { dfsClientId } = useParams();
    const backURL = useMemo(() => (
        `/client/${dfsClientId}`
    ), [dfsClientId]);
    const exitURL = useMemo(() => (
        `/client/${dfsClientId}/container/${currentInvestmentApplicationSelector?.investmentDescription?.containerId
        }`), [dfsClientId, currentInvestmentApplicationSelector?.investmentDescription?.containerId]);
    const { is3B, changePlan } = useCreatePortfolioState();

    const initStep = useMemo(() => {
        if (changePlan) {
            return stepsKeys.AdvisoryTypeSelection;
        }
        if (is3B === true) {
            return stepsKeys.ShoppingCard;
        } if (is3B === false) {
            return stepsKeys.Non3BShoppingCard;
        }

        return stepsKeys.GeneralProductSelection;
    }, [is3B, changePlan]);

    const Layout = useMemo(() => ({ children }: { children: ReactNode }) => (
        <PortfolioProcessLayoutManager
            withProductGroupName
        >
            <PortfolioProcessLayout dataSource={useCreatePortfolioLayoutProcess}>
                {children}
            </PortfolioProcessLayout>
        </PortfolioProcessLayoutManager>
    ), []);

    const headerNavLeftButton = useMemo(
        () => ({ type: 'login', label: `${t('clientDashboard.backTo')} ${clientName}` }),
        [clientName, language],
    );

    const headerNavLeftButtonOnLeftButtonClick = useCallback(
        () => { navigate(`/client/${dfsClientId}/`); },
        [dfsClientId],
    );

    useClientLayout(
        false,
        false,
        headerNavLeftButton,
        headerNavLeftButtonOnLeftButtonClick,
    );

    return (
        <Stepper
            startStep={initStep}
            completeURL={exitURL}
            backURL={backURL}
            items={stepsBasedOnState}
            layout={Layout}
            withCache
        />
    );
};
