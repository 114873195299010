import { validateCheckboxGroup } from 'components/KYCForm/validation/validation';
import {
    validateDateOfBirth,
    validateEmailAddressUniqueness,
    validateEmailAdress,
    validateInputLength,
    validateInputNumber,
    validateInputString,
    validateMultipleInputsLength,
    validatePhoneNumber,
    validateRadio,
    validateSelect,
} from '../../../../../../validation/validation';
import { FIELD_LENGTH } from '../../../../../ClientOverview/constants';

export const errorModel = async (data, userRegisterId, isJunior = false) => ({
    mailRecipientAddress: isJunior ? validateInputNumber(data.mailRecipientAddressCountry) : null,
    gender: validateRadio(data.gender),
    maritalStatus: validateSelect(data.maritalStatus),
    firstName: validateInputString(data.firstName) || validateInputLength(data.firstName, FIELD_LENGTH.firstName),
    lastName: validateInputString(data.lastName) || validateInputLength(data.lastName, FIELD_LENGTH.lastName),
    dateOfBirth: (data.hideDatePickerValue !== undefined) ? validateDateOfBirth(data.dateOfBirth) : ' ',
    countryOfNationality: validateSelect(data.countryOfNationality),
    street: validateInputString(data.street) || validateInputLength(data.street, FIELD_LENGTH.street),
    PLZ: validateInputNumber(data.PLZ),
    ORT: (data.readOnly) ? null : validateInputString(data.ORT) || validateInputLength(data.ORT, FIELD_LENGTH.city),
    language: validateSelect(data.language),
    land: data.showSecondaryAdress ? validateSelect(data.land) : null,
    secondaryStreet: data.showSecondaryAdress ? validateInputString(data.secondaryStreet) || validateInputLength(data.secondaryStreet, FIELD_LENGTH.street) : null,
    secondaryPLZ: data.showSecondaryAdress ? validateInputNumber(data.secondaryPLZ) : null,
    secondaryORT: data.showSecondaryAdress ? ((data.readOnlySecondary) ? null : validateInputString(data.ORT)) || validateInputLength(data.ORT, FIELD_LENGTH.city) : null,
    primaryPhoneNumber: validatePhoneNumber(data.primaryPhoneNumber),
    primaryEmail: validateEmailAdress(data.primaryEmail) || await validateEmailAddressUniqueness(data.primaryEmail, userRegisterId),
    countryOfResidence: validateSelect(data.countryOfResidence),
    countryOfBirth: validateSelect(data.countryOfBirth),
    usCitizen: validateRadio(data.usCitizen),
    isUsPerson: validateRadio(data.isUsPerson),
    hasUsResidence: validateRadio(data.hasUsResidence),
    classedUsPerson: validateCheckboxGroup(data.classedUsPerson),
    firstLastLengthCombined: validateMultipleInputsLength(FIELD_LENGTH.firstAndLastNameCombined, 'clientValidation.firstAndLastNameLengthCombined', data.firstName, data.lastName),
});
