import ServiceManager from 'services/ServiceManager';
import { DEFAULT_PROJECTION_YEARS } from '../../../constants';

const getInvestmentProjection = (data) => {
    const payload = {
        modelPortfolioId: data.PortfolioId ?? data.modelPortfolioId,
        projectionYears: (data.projectionYears ?? 0) <= 0 ? DEFAULT_PROJECTION_YEARS : +data.projectionYears,
        initialInvestmentAmount: data.InitialInvestmentAmount ?? data.initialInvestment,
        yearlyContributionAmount: data.YearlyContributionAmount ?? data.yearlyPayments,
        investmentApplicationId: data?.investmentApplicationId,
    };

    if (data.individualPortfolio) {
        payload.individualPortfolio = data.individualPortfolio;
    }

    return ServiceManager.customInvestmentService('postData', [payload]);
};

export default getInvestmentProjection;
