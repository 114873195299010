import { useTranslation } from 'react-i18next';

const useQualityCheckQuestionnaire = () => {
    const { t } = useTranslation();
    const validateQuestionnaire = ({
        data,
        answersToValidate = {},
        errorMessage,
    } : {data: any; groupId: number | string; answersToValidate: any; errorMessage: string}) => {
        const errors = {};
        const questions = data?.Steps?.map((step) => step?.Questions.map((question) => question).flat()).flat();

        const isOthersSelected = answersToValidate?.[238]?.includes(766);

        questions?.forEach((question) => {
            const {
                Id, Type, MinimumNumberOfAnswers, MaximumNumberOfAnswers,
            } = question;
            const value = answersToValidate[Id];
            const isRequired = Type === 'MultiAnswer' ? MinimumNumberOfAnswers > 0 : (![262]?.includes(Id));
            const isOthersFieldRequired = (Id === 258 && isOthersSelected && !answersToValidate?.[258]);

            if ((!Object.keys(answersToValidate).includes(Id.toString()) || isOthersFieldRequired) && isRequired) {
                errors[Id] = errorMessage || t('validation.mandatoryField');
            }
            if (Type !== 'FreeText' && value?.length < MinimumNumberOfAnswers) {
                errors[Id] = `${t('validation.questionnaire.minimumNumberOfAnswers')}: ${MinimumNumberOfAnswers}`;
            }
            if (Type !== 'FreeText' && value?.length > MaximumNumberOfAnswers) {
                errors[Id] = `${t('validation.questionnaire.maximumNumberOfAnswers')}: ${MaximumNumberOfAnswers}`;
            }
        });
        const isValid = Object.keys(errors).length === 0;

        return { isValid, errors };
    };

    return { validateQuestionnaire };
};

export default useQualityCheckQuestionnaire;
