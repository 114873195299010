import { defaultProjectionChartOptions } from './constants';
import { tooltipPositioner } from '../../../utils/tooltip';

const adaptSeriesForArea = (data, labels) => {
    const seriesDivided = [
        { data: [], name: labels.bottomLabel },
        { data: [], name: labels.topLabel },
        { data: [], name: labels.middleLabel },
        { data: [], name: labels.middleLabel },
        { data: [], name: labels.middleTopLabel },
        { data: [], name: labels.middleBottomLabel },
    ];

    if (!data || data.length === 0) {
        return seriesDivided;
    }

    data[0].data.forEach(({ x, low, high }) => {
        const middle = data[2].data.find((item) => item.x === x);
        const middleRange = data[1].data.find((item) => item.x === x);

        seriesDivided[0].data.push({
            x, y: low, high: low, low: middleRange.high,
        });
        seriesDivided[1].data.push({
            x, y: high, high, low: middleRange.low,
        });
        seriesDivided[2].data.push({
            x, y: middle.y, high: middle.y, low: middleRange.low,
        });
        seriesDivided[3].data.push({
            x, y: middle.y, high: middle.y, low: middleRange.high,
        });
        seriesDivided[4].data.push({
            x, y: middleRange.high, high: middleRange.high, low: middleRange.high,
        });
        seriesDivided[5].data.push({
            x, y: middleRange.low, high: middleRange.low, low: middleRange.low,
        });
    });

    return [...seriesDivided].map((item) => ({
        ...item,
        type: 'area',
        data: item.data.map((i) => ({ ...i, y: i.y || i.low })),
    }));
};

export const getBarChartOptions = (
    min, max, data, dataExternal = [], height, yAxisFormat, tooltipFormat, labels,
) => {
    const areaData = adaptSeriesForArea(data, labels);
    const series = [...areaData, ...dataExternal];
    const values = data.map((item) => item.data).flat().map(({ x }) => x);
    const valuesY = areaData.map((item) => item.data).flat().map(({ y }) => y);
    const valuesYExternal = dataExternal?.map((item) => item.data).flat().map((item) => item[1]);
    const xMin = Math.min(...values);
    const xMax = Math.max(...values);
    const yMin = Math.min(...valuesY);
    const yMax = Math.max(...valuesY);
    const yExternalMin = Math.min(...valuesYExternal);
    const yExternalMax = Math.max(...valuesYExternal);
    const tickPositioner = () => {
        const TICKS_MAX_COUNT = 18;
        const positions = values.length ? [new Date(xMin).valueOf()] : [];
        let tick = xMin;
        let minYear = new Date(xMin).getFullYear();

        while (tick < xMax) {
            minYear += 1;
            tick = new Date(xMin).setFullYear(minYear).valueOf();
            positions.push(tick);
        }

        if (positions.length <= TICKS_MAX_COUNT) return positions;

        const delta = Math.ceil(positions.length / TICKS_MAX_COUNT);
        const selected = [];

        for (let i = 0; i < positions.length; i += delta) {
            selected.push(positions[i]);
        }

        return selected;
    };
    const yAxisMin = min || Math.min(yMin, yExternalMin);
    const yAxisMax = max || Math.max(yMax, yExternalMax);

    function yAxisFormatter() {
        if (!this) return null;
        if (this.value <= min) return yAxisFormat(this.value);
        if (this.value >= max) return yAxisFormat(this.value);

        return yAxisFormat(this.value);
    }
    function tooltipFormatter() {
        return `${tooltipFormat(this)}`;
    }
    function positioner(labelWidth, labelHeight, point) {
        return tooltipPositioner({
            labelWidth, labelHeight, point, chart: this.chart, yTitle: labels.yTitle,
        });
    }

    return {
        ...defaultProjectionChartOptions,
        chart: {
            ...defaultProjectionChartOptions.chart,
            height,
        },
        tooltip: {
            ...defaultProjectionChartOptions.tooltip,
            formatter: tooltipFormatter,
            positioner,
        },
        xAxis: {
            ...defaultProjectionChartOptions.xAxis,
            tickPositioner,
            labels: {
                ...defaultProjectionChartOptions.xAxis.labels,
                formatter() {
                    if (!this) return '';
                    const { isFirst, value } = this;

                    if (isFirst) return `<b>${labels.yearsText}</b>`;
                    const yearOffset = new Date(value).getFullYear() - new Date(xMin).getFullYear();

                    return `${yearOffset >= 0 ? '+' : ''}${yearOffset}`;
                },
            },
        },
        yAxis: {
            ...defaultProjectionChartOptions.yAxis,
            min: yAxisMin,
            max: yAxisMax,
            title: {
                ...defaultProjectionChartOptions.yAxis.title,
                text: labels.yTitle,
            },
            labels: {
                ...defaultProjectionChartOptions.yAxis.labels,
                formatter: yAxisFormatter,
            },
        },
        series,
    };
};
