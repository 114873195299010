import { useCallback, useMemo } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getDateFormat, getNumberFormat } from 'libs/additiv-intl-formatting';

import { getDateTimeSeparators, getUTCDate, getDateLocalFormat } from './utils';
import { dateTimeFormats } from './formatTypes';
import useLocale from './useLocale';

export default () => {
    const { locale } = useLocale();

    const getFormattedDate = useCallback(
        (date, options) => {
            if (Number.isNaN(Date.parse(date))) return date;
            const defaultOptions = {
                convertToUTC: true,
                ...options,
            };

            return getDateFormat({
                locale,
                date: defaultOptions.convertToUTC ? getUTCDate(date) : new Date(date),
                options: {
                    year: dateTimeFormats.NUMERIC,
                    month: dateTimeFormats.TWO_DIGIT,
                    day: dateTimeFormats.TWO_DIGIT,
                    ...defaultOptions,
                },
            });
        },
        [locale],
    );

    const getFormattedTime = useCallback(
        (date, options = {}) => {
            if (Number.isNaN(!Date.parse(date))) return date;

            return getDateFormat({
                locale,
                date: getUTCDate(date),
                options: {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                    ...options,
                },
            });
        },
        [locale],
    );

    const getFormattedCurrency = useCallback(
        (value, options = {}) => {
            const { currency, ...rest } = options;

            const formatted = getNumberFormat(
                {
                    locale,
                    number: value,
                    options: {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                        ...rest,
                    },
                },
            );

            return currency ? `${currency} ${formatted}` : formatted;
        },
        [locale],
    );

    const getFormattedNumber = useCallback(
        (number, options = {}) => {
            const formatted = typeof number === 'number'
                ? getNumberFormat(
                    {
                        locale,
                        number,
                        options: {
                            signDisplay: 'auto', // even tho documentation says its supported, 'negative' value is not supported by mozilla browser, therefor im changing this one to 'auto'
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                            ...options,
                        },
                    },
                )
                : number;

            return formatted;
        },
        [locale],
    );

    const getFormattedPhoneNumber = useCallback(
        (phone) => {
            // eslint-disable-next-line prefer-template
            const parsed = parsePhoneNumberFromString('+' + String(phone).replace(/\D/g, ''));

            return parsed?.formatInternational();
        },
        [locale],
    );

    const getFormattedXAxisFormat = useMemo(
        () => {
            const { dateSeparator, timeSeparator } = getDateTimeSeparators(locale.locale);
            const { firstElement, secondElement } = getDateLocalFormat(locale);

            return {
                millisecond: `%H${timeSeparator}%M${timeSeparator}%S.%L`,
                second: `%H${timeSeparator}%M${timeSeparator}%S`,
                minute: `%H${timeSeparator}%M`,
                hour: `%H${timeSeparator}%M`,
                day: `%${firstElement}${dateSeparator}%${secondElement}`,
                week: `%${firstElement}${dateSeparator}%${secondElement}`,
                month: '%b %y',
                year: '%Y',
            };
        },
        [locale],
    );

    const getAmountWithDecimals = (value, currency) => getFormattedCurrency(value, {
        currency: currency ?? 'CHF',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    return {
        getFormattedCurrency,
        getFormattedDate,
        getFormattedTime,
        getFormattedNumber,
        getFormattedXAxisFormat,
        getFormattedPhoneNumber,
        getAmountWithDecimals,
    };
};
