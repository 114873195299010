import { findMinAndMax, newAdaptLinearChartData, newAdaptProjectionData } from 'adaptors/adaptProjection';
import ProjectionTooltip from 'components/ProjectionTooltip';
import { projectionOptional } from 'constants/goalCreation';
import { isThreeBProduct, useThreeBProductType } from 'hooks/isThreeBProduct';
import { useFormatting } from 'locale';
import use3bStepper from 'pages/ClientOverview/pages/Portfolios/hooks/use3bStepper';
import {
    MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import {
    useInvestmentContributions,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/GoalAndPortfolioSetup/hooks/useInvestmentContributions';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ServiceManager from 'services/ServiceManager';
import { formatNumberRounding } from 'utils/formatting';
import { THREEBTYPES } from '../../../../../../../../../constants/constants';
import HandlerError from '../../../../../../../../../errors/HandlerError';
import handlerRequestCanceling from '../../../../../../../../../utils/handlerRequestCanceling';
import {
    IsAllDataNull,
    validateInputNumberWithZero,
    validateInputString,
} from '../../../../../../../../../validation/validation';
import { riskCategoriesByLang } from '../../../../../constants';
import { useCreatePortfolio } from '../../../context';
import { calculatePlannedInvestment } from '../../../utils';
import { errorModel } from '../models/errorModel';
import getInvestmentProjection from '../services/getInvestmentProjection';

let transferValueTimeoutId;
let yearlyPaymentTimeoutId;
let projectionYearsTimeoutId;

const INPUT_TIMEOUT = 200;
const MAX_INVESTMENT_FOR_NEXT_6_MONTHS = 200000;

export const useGoalAndPerformance = ({
    dfsClientId, modelPortfolioId, strategyId, disableLayoutData = false,
}) => {
    const navigate = useNavigate();
    const { i18n: { language }, t } = useTranslation();
    const [product, setProduct] = useState(undefined);
    const [rubrik, setRubrik] = useState();
    const [transferValue, setTransferValue] = useState(0);
    const [yearlyPayment, setYearlyPayment] = useState(0);
    const [minMaxTransferValue, setMinMaxTransferValue] = useState(undefined);
    const [minMaxYearlyPayment, setMinMaxYearlyPayment] = useState({ min: 0, max: 6683 });
    const [isFullTransfer, setFullTransfer] = useState('true');
    const [currency, setCurrency] = useState(0);
    const [riskCategoryId, setRiskCategoryId] = useState(3);
    const [chartData, setChartData] = useState([]);
    const [returnRates, setReturnRates] = useState(null);
    const [modelPortfolio, setModelPortfolio] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [prevPensionScheme, setPrevPensionScheme] = useState({});
    const [validationPrevPensionScheme, setValidationPrevPensionScheme] = useState({});
    const [plannedInvestment, setPlannedInvestment] = useState(0);
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const {
        newPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors },
    } = useCreatePortfolio();
    const isThreeB = isThreeBProduct(newPortfolio);
    const [projectionYears, setProjectionYears] = useState(null);
    const [paymentPeriod, setPaymentPeriod] = useState();
    const [periodicPayment, setPeriodicPayment] = useState();
    const [oneTimeInvestment, setOneTimeInvestment] = useState();
    const [isPaymentPeriodVisible, setPaymentPeriodVisibility] = useState(false);

    const riskCategoriesList = useMemo(() => (riskCategoriesByLang(t)), [t]);
    const productName = useMemo(() => (newPortfolio?.product?.name), [newPortfolio?.product?.name]);
    const productSettings = useMemo(() => (newPortfolio?.product?.settings), [newPortfolio?.product?.settings]);
    const affiliatedToPensionFund = useMemo(() => (newPortfolio?.applicationData?.investmentDescription?.personalDetails?.affiliatedToPensionFund), [newPortfolio?.applicationData?.investmentDescription?.personalDetails?.affiliatedToPensionFund]);
    const individualPortfolio = useMemo(() => (newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.individualPortfolio), [newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.individualPortfolio]);
    const showFullTransferQuestion = useMemo(() => (!productSettings?.showYearlyPayments), [productSettings?.showTransferQuestion]);
    const initialInvestmentAmount = useMemo(() => (newPortfolio?.goals?.InitialInvestmentAmount), [newPortfolio?.goals?.InitialInvestmentAmount]);
    const isWithdrawal = useMemo(() => (newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.withdrawalOption), [newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.withdrawalOption]);
    const {
        isAzp, isZic, isZifd,
    } = useThreeBProductType(newPortfolio);

    const withdrawalAzpInvestmentAmount = useMemo(() => (newPortfolio?.applicationData?.investmentDescription?.withdrawal?.investmentAmount), [newPortfolio?.applicationData?.investmentDescription?.withdrawal?.investmentAmount]);

    const { productConfigStepNumber } = use3bStepper();

    const {
        getYearlyContribution,
        getInvestmentForNextSixMonths,
    } = useInvestmentContributions(newPortfolio?.product?.externalId, productSettings, {
        periodicPayment,
        paymentPeriod,
        oneTimeInvestment,
    });

    const onPeriodicPaymentChange = useCallback((value) => {
        clearTimeout(yearlyPaymentTimeoutId);

        setPaymentPeriodVisibility(value !== 0);
        yearlyPaymentTimeoutId = setTimeout(() => {
            setPeriodicPayment(value);
            setPlannedInvestment(calculatePlannedInvestment(oneTimeInvestment, value, paymentPeriod));
        }, 500);
    }, [oneTimeInvestment, paymentPeriod, calculatePlannedInvestment]);

    const onOneTimePaymentChange = useCallback((value) => {
        clearTimeout(transferValueTimeoutId);

        transferValueTimeoutId = setTimeout(() => {
            setOneTimeInvestment(value);
            setPlannedInvestment(calculatePlannedInvestment(value, periodicPayment, paymentPeriod));
        }, INPUT_TIMEOUT);
    }, [periodicPayment, paymentPeriod, calculatePlannedInvestment]);

    const onPaymentPeriodChange = useCallback((value) => {
        setPaymentPeriod(value);
        setPlannedInvestment(calculatePlannedInvestment(oneTimeInvestment, periodicPayment, value));
    }, [oneTimeInvestment, periodicPayment, calculatePlannedInvestment]);

    const onProjectionYearsChange = useCallback((val) => {
        clearTimeout(projectionYearsTimeoutId);

        projectionYearsTimeoutId = setTimeout(() => {
            setProjectionYears(val);
        }, 500);
    }, []);

    const setProjectionYearsValue = useCallback((val) => {
        setProjectionYears(val);
    }, []);

    const isInvestmentAllowed = useMemo(() => {
        const plannedInvestmentForNext6Months = getInvestmentForNextSixMonths();

        return plannedInvestmentForNext6Months <= MAX_INVESTMENT_FOR_NEXT_6_MONTHS || (plannedInvestmentForNext6Months > MAX_INVESTMENT_FOR_NEXT_6_MONTHS && newPortfolio?.applicationData?.investmentDescription?.advisoryCompleted);
    }, [newPortfolio, getInvestmentForNextSixMonths]);

    const isProofOfAssetsRequired = useMemo(() => oneTimeInvestment > MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS && newPortfolio.product.externalId === THREEBTYPES.zic, [oneTimeInvestment, newPortfolio]);

    useEffect(() => {
        if (!plannedInvestment) {
            setPlannedInvestment(calculatePlannedInvestment(oneTimeInvestment, periodicPayment, paymentPeriod));
        }
    }, [oneTimeInvestment, paymentPeriod, periodicPayment, calculatePlannedInvestment, plannedInvestment]);

    const radioFullTransferOptions = useMemo(() => ([
        {
            label: t('portfolio.createNewPortfolio.configuration.options.fullTransfer'),
            value: 'true',
            disabled: false,
            error: false,
        }, {
            label: t('portfolio.createNewPortfolio.configuration.options.partialTransfer'),
            value: 'false',
            disabled: false,
            error: false,
        },
    ]), []);

    useEffect(() => {
        if (newPortfolio?.userClientAge) {
            setProjectionYears(65 - newPortfolio?.userClientAge);
        }
    }, [newPortfolio?.userClientAge]);

    useEffect(() => {
        if (productSettings) {
            setMinMaxTransferValue({
                min: productSettings?.transferAmount?.transferAmountMin,
                max: productSettings?.transferAmount?.transferAmountMax,
            });

            setMinMaxYearlyPayment({
                min: 0,
                max: affiliatedToPensionFund
                    ? productSettings?.maximumYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue
                    : productSettings?.maximumYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse,
            });

            const defaultYearlyPayment = affiliatedToPensionFund ? productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue : productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse;

            setYearlyPayment(newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.yearlyPayments || defaultYearlyPayment || 0);

            if (!productSettings?.showYearlyPayments) {
                changeTransferValue((initialInvestmentAmount || productSettings?.defaultTransferAmount) ?? 0);
            }
        }
    }, [productSettings, affiliatedToPensionFund, initialInvestmentAmount, newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.yearlyPayments]);

    useEffect(() => {
        if (isThreeB) {
            setRubrik(newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.rubrik);
            setOneTimeInvestment(newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.initialInvestment || productSettings.defaultOneTimeInvestment);
            setPeriodicPayment(newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.periodicalPaymentAmount || productSettings.defaultPeriodicPayment);
            setPaymentPeriod(newPortfolio?.applicationData?.investmentDescription?.productConfiguration?.periodicity || productSettings.defaultPaymentFrequency);
        }
    }, [newPortfolio, isThreeB]);

    useEffect(() => {
        if (newPortfolio?.product) {
            setProduct(newPortfolio?.product);
        }
        if (newPortfolio?.goals) {
            setTransferValue(initialInvestmentAmount);
        }
        if (showFullTransferQuestion && newPortfolio?.isFullTransfer !== null && newPortfolio?.isFullTransfer !== undefined) {
            setFullTransfer(`${newPortfolio?.isFullTransfer}`);
        }
        if (newPortfolio?.prevPensionScheme) {
            setPrevPensionScheme({
                ...newPortfolio?.prevPensionScheme,
            });
        }
    }, [newPortfolio]);

    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/${isAzp ? 'withdrawal-plan' : 'strategy-selection'}`);
    }, [dfsClientId, isAzp]);

    const onFieldChange = useCallback((field, validateCallback = validateInputString) => {
        const [fieldKey] = Object.keys(field);

        setPrevPensionScheme({
            ...prevPensionScheme,
            ...field,
        });
        setValidationPrevPensionScheme({
            ...validationPrevPensionScheme,
            ...{ [fieldKey]: validateCallback ? validateCallback(field[fieldKey]) : null },
        });
    }, [validationPrevPensionScheme, setValidationPrevPensionScheme, setPrevPensionScheme, prevPensionScheme]);

    const yAxisFormat = useCallback((num) => formatNumberRounding(num, getFormattedNumber),
        [getFormattedNumber]);

    const azpXAxisFormat = useCallback((context) => {
        if (!context) return '';
        const { isFirst, value } = context;

        if (isFirst) return `<b>${t('charts.years')}</b>`;
        const yearOffset = new Date(value).getFullYear() - new Date().getFullYear();

        return `${yearOffset >= 0 ? '+' : ''}${yearOffset}`;
    }, []);

    const azpYAxisFormat = useCallback((context) => {
        const { value } = context;

        return formatNumberRounding(value, getFormattedNumber);
    }, []);

    const tooltipFormat = useCallback(({ point, series }) => ReactDOMServer
        .renderToString(ProjectionTooltip({
            point, series, currency, t, getFormattedCurrency,
        })), [currency, getFormattedCurrency, t]);

    const optional = useMemo(() => ({
        ...projectionOptional(t, currency, yAxisFormat, tooltipFormat),
        ...(findMinAndMax([chartData])),
    }), [currency, t, yAxisFormat, chartData]);

    const onContinue = useCallback(async () => {
        try {
            const errors = errorModel({
                data: {
                    ...prevPensionScheme,
                    yearlyPayment,
                    transferValue,
                    rubrik,
                },
                showYearlyPayments: isThreeB ? false : productSettings?.showYearlyPayments,
                isThreeB,
                isAzp,
                isZic,
                isZifd,
            });

            if (!productSettings?.showYearlyPayments || transferValue > 0) {
                setValidationPrevPensionScheme(errors);
                if (IsAllDataNull(errors) === false) {
                    return;
                }
            }

            if (productSettings?.showYearlyPayments && validateInputNumberWithZero(yearlyPayment) !== null) {
                setValidationPrevPensionScheme(errors);

                return;
            }

            let periodicity = null;

            if (isThreeB) {
                periodicity = periodicPayment === 0 ? null : parseInt(paymentPeriod, 10);
            }

            const payload = {
                initialInvestment: isThreeB ? oneTimeInvestment : parseInt(transferValue, 10),
                periodicalPaymentAmount: isThreeB ? periodicPayment : null,
                periodicity,
                yearlyPayments: parseInt(yearlyPayment, 10),
                pensionScheme: isThreeB ? null : prevPensionScheme.nameOfPensionScheme,
                addressSuffix: prevPensionScheme.addressSupplement,
                street: prevPensionScheme.streetAndNumber,
                zip: prevPensionScheme.postcodeAndCity,
                policyNo: prevPensionScheme.policeNumber,
                bankAccountNo: prevPensionScheme.bankAccountNumber,
                investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
                rubrik: isThreeB ? rubrik : null,
            };

            if (showFullTransferQuestion) {
                payload.isFullTransfer = isFullTransfer === 'true';
            }

            const azpPayload = {
                initialInvestment: withdrawalAzpInvestmentAmount,
                investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
                yearlyPayments: 0,
                rubrik: isThreeB ? rubrik : null,
            };

            const result = await saveNewPortfolio({
                data: isAzp ? azpPayload : payload,
                method: 'saveProductData',
                additionalData: {
                    goals: {
                        InstrumentAllocations: modelPortfolio.Positions.map(
                            ({ Allocation, Security: { Id } }) => ({
                                Allocation,
                                InstrumentId: Id,
                            }),
                        ),
                        InitialInvestmentAmount: isThreeB ? oneTimeInvestment : parseInt(transferValue, 10),
                        YearlyContributionAmount: isThreeB ? getYearlyContribution() : parseInt(yearlyPayment, 10),
                        CurrencyId: modelPortfolio.BaseCurrency.Id,
                        PortfolioId: modelPortfolioId,
                        projectionYears: projectionYears < 1 ? 0 : projectionYears,
                        plannedInvestment,
                    },
                    isFullTransfer: isFullTransfer === 'true',
                    prevPensionScheme,
                    chartOptions: optional,
                    currency: modelPortfolio.BaseCurrency,
                },
            });

            if (!result) return;

            let nextStepURl = `/client/${dfsClientId}/portfolios/new-portfolio-legacy/agent-information`;

            if (isThreeB) {
                if (isAzp) {
                    nextStepURl = `/client/${dfsClientId}/portfolios/new-portfolio-legacy/fees-overview`;
                } else {
                    nextStepURl = isWithdrawal ? `/client/${dfsClientId}/portfolios/new-portfolio-legacy/withdrawal-plan` : `/client/${dfsClientId}/portfolios/new-portfolio-legacy/fees-overview`;
                }
            }
            navigate(nextStepURl);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setPageErrors,
                    setLoading,
                }),
            )(err);
        }
    }, [
        dfsClientId,
        modelPortfolio,
        transferValue,
        yearlyPayment,
        optional,
        isFullTransfer,
        showFullTransferQuestion,
        prevPensionScheme,
        isThreeB,
        oneTimeInvestment,
        periodicPayment,
        paymentPeriod,
        isWithdrawal,
        productSettings?.showYearlyPayments,
        rubrik,
        getYearlyContribution,
        isAzp,
        withdrawalAzpInvestmentAmount,
        plannedInvestment,
    ]);

    const changeTransferValue = useCallback((value) => {
        clearTimeout(transferValueTimeoutId);
        if (!productSettings?.showYearlyPayments) {
            setValidationPrevPensionScheme({
                ...validationPrevPensionScheme,
                ...{ transferValue: validateInputNumberWithZero(value) },
            });
        }
        transferValueTimeoutId = setTimeout(() => {
            setTransferValue(value);
        // console.log('InitialInvestmentAmount2', value);
        }, INPUT_TIMEOUT);
    }, [validationPrevPensionScheme, productSettings?.showYearlyPayments]);

    const changeYearlyPayment = useCallback((value) => {
        clearTimeout(yearlyPaymentTimeoutId);
        setValidationPrevPensionScheme({
            ...validationPrevPensionScheme,
            ...{ yearlyPayment: validateInputNumberWithZero(value) },
        });
        yearlyPaymentTimeoutId = setTimeout(() => {
            setYearlyPayment(value);
        }, INPUT_TIMEOUT);
    }, [validationPrevPensionScheme]);

    const changeFullTransfer = useCallback((e) => {
        setFullTransfer(e?.target?.value);
    }, [isFullTransfer]);

    const getSelectStrategy = () => newPortfolio?.riskScore;

    useEffect(() => {
        if (modelPortfolioId) {
            (async () => {
                try {
                    setLoading(true);
                    const response = await ServiceManager.portfolioManagement(
                        'getModelPortfolio',
                        [modelPortfolioId, { language }],
                    );

                    setCurrency(response.data?.BaseCurrency?.CurrencyCode);
                    setModelPortfolio(response.data);
                } catch (err) {
                    handlerRequestCanceling(
                        HandlerError({
                            setError: setPageErrors,
                            setLoading,
                        }),
                    )(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [language, newPortfolio]);

    useEffect(() => {
        if (modelPortfolio && (
            (transferValue && !productSettings?.showYearlyPayments)
    || (productSettings?.showYearlyPayments && (yearlyPayment || transferValue)) || isThreeB)) {
            (async () => {
                const performance = {
                    language,
                    InstrumentAllocations: modelPortfolio.Positions.map(
                        ({ Allocation, Security: { Id } }) => ({
                            Allocation,
                            InstrumentId: Id,
                        }),
                    ),
                    InitialInvestmentAmount: isThreeB ? oneTimeInvestment : parseInt(transferValue, 10),
                    YearlyContributionAmount: isThreeB ? getYearlyContribution() : parseInt(yearlyPayment, 10),
                    CurrencyId: modelPortfolio.BaseCurrency.Id,
                    PortfolioId: modelPortfolioId,
                    projectionYears: projectionYears < 1 ? 0 : projectionYears,
                    individualPortfolio,
                    investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
                };

                getInvestmentProjection(performance).then((response) => {
                    const chartGraphData = response.data?.projections;
                    const AdditionalValueArray1 = chartGraphData[1]?.values.map((k, i) => ({
                        ...k, AdditionalValue: chartGraphData[0]?.values[i]?.value, value: k?.value, date: k?.date,
                    }));
                    const AdditionalValueArray2 = chartGraphData[2]?.values.map((k, i) => ({
                        ...k, AdditionalValue: chartGraphData[1]?.values[i]?.value, value: k?.value, date: k?.date,
                    }));

                    const chart = newAdaptProjectionData([
                        { Values: [...AdditionalValueArray1], ProbabilityPercentage: chartGraphData[1]?.stdDevFactor, SeriesType: 'InstrumentSet' },
                        { Values: [...AdditionalValueArray1], ProbabilityPercentage: chartGraphData[1]?.stdDevFactor, SeriesType: 'InstrumentSet' },
                        { Values: [...AdditionalValueArray2], ProbabilityPercentage: chartGraphData[2]?.stdDevFactor, SeriesType: 'InstrumentSet' },
                    ]);

                    const { bestReturn, averageReturn, worstReturn } = response?.data || {};

                    setReturnRates([
                        { name: t('projectionLegend.bestCase'), value: `${bestReturn > 0 ? '+' : '-'} ${Math.abs(bestReturn * 100)?.toFixed(2)}%` },
                        { name: t('projectionLegend.averageCase'), value: `${averageReturn > 0 ? '+' : '-'} ${Math.abs(averageReturn * 100)?.toFixed(2)}%` },
                        { name: t('projectionLegend.worstCase'), value: `${worstReturn > 0 ? '+' : '-'} ${Math.abs(worstReturn * 100)?.toFixed(2)}%` },
                    ]);

                    setChartData(chart);
                });
            })();
        }
    }, [transferValue, yearlyPayment, modelPortfolio, language, productSettings?.showYearlyPayments, individualPortfolio, plannedInvestment, oneTimeInvestment, isThreeB, projectionYears, getYearlyContribution]);

    useEffect(() => {
        if (!disableLayoutData) {
            setLayoutData({
                stepNavBarActive: isThreeB ? productConfigStepNumber : 5,
                onBack,
                onContinue,
                disabled: isLoading,
            });
        }
    }, [onBack, onContinue, isLoading, isThreeB, productConfigStepNumber]);

    return {
        isPaymentPeriodVisible,
        projectionOptions: optional,
        riskCategoryId,
        currency,
        riskCategories: riskCategoriesList,
        t,
        showFullTransferQuestion,
        modelPortfolio,
        productSettings,
        productName,
        isFullTransfer,
        radioFullTransferOptions,
        changeFullTransfer,
        isLoading,
        chartData,
        returnRates,
        transferValue,
        yearlyPayment,
        changeTransferValue,
        changeYearlyPayment,
        minMaxTransferValue,
        minMaxYearlyPayment,
        onContinue,
        onBack,
        validation: validationPrevPensionScheme,
        prevPensionScheme,
        onFieldChange,
        getSelectStrategy,
        isThreeB,
        periodicPayment,
        oneTimeInvestment,
        onPeriodicPaymentChange,
        onOneTimePaymentChange,
        onPaymentPeriodChange,
        paymentPeriod,
        plannedInvestment,
        rubrik,
        setRubrik,
        projectionYears,
        onProjectionYearsChange,
        getInvestmentForNextSixMonths,
        isInvestmentAllowed,
        isProofOfAssetsRequired,
        azpXAxisFormat,
        azpYAxisFormat,
        setProjectionYearsValue,
        language,
    };
};
