import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
    Button, Icon, Loader, NumberInput, Select, TextInput,
} from 'ui-library';
import { LoanResponse } from 'core/types/api/Api';
import { IncomeAndLiquidityProps } from './IncomeAndLiquidity.types';

import './IncomeAndLiquidity.css';

const IncomeAndLiquidity:React.FC<IncomeAndLiquidityProps> = ({ datasource = () => {} }) => {
    const { t } = useTranslation();
    const {
        instituteOptions,
        fields, control, errors, addNewLoan, removeLoan,
    } = datasource() || {};

    if (instituteOptions?.status === 'pending') {
        return <Loader />;
    }

    return (
        <section className="income-and-liquidity">
            <h1 className="span title">{t('incomeAndLiquidity.savingsAmount')}</h1>
            <Controller
                name="grossAnnualIncome"
                control={control}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        data-cy="grossAnnualIncome"
                        name="grossAnnualIncome"
                        label={t('incomeAndLiquidity.grossAnnualIncome')}
                        error={errors?.grossAnnualIncome?.message}
                        thousandSeparator="’"
                        decimalSeparator="."
                        prefix="CHF "
                    />
                )}
            />
            <Controller
                name="annualSavedAmount"
                control={control}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        data-cy="annualSavedAmount"
                        label={t('incomeAndLiquidity.annualSavedAmount')}
                        error={errors?.annualSavedAmount?.message}
                        thousandSeparator="’"
                        decimalSeparator="."
                        prefix="CHF "
                    />
                )}
            />

            <h3 className="span title">
                {t('incomeAndLiquidity.freeLiquidity')}
            </h3>
            <Controller
                name="totalLiquidity"
                control={control}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        data-cy="totalLiquidity"
                        label={t('incomeAndLiquidity.totalCash')}
                        error={errors?.totalLiquidity?.message}
                        thousandSeparator="’"
                        decimalSeparator="."
                        prefix="CHF "
                    />
                )}
            />
            <h3 className="span title">
                {t('incomeAndLiquidity.capitalInflow')}
            </h3>
            <Controller
                name="plannedExpenses"
                control={control}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        data-cy="plannedExpenses"
                        label={t('incomeAndLiquidity.plannedExpensesAmount')}
                        error={errors?.plannedExpenses?.message}
                        thousandSeparator="’"
                        decimalSeparator="."
                        prefix="CHF "
                    />
                )}
            />

            <Controller
                name="intendedUse"
                control={control}
                render={({ field }) => (
                    <TextInput
                        {...field}
                        data-cy="intendedUse"
                        label={t('incomeAndLiquidity.plannedExpensesDesc')}
                        error={errors?.intendedUse?.message}
                    />
                )}
            />

            <Controller
                name="expectedAssetInflow"
                control={control}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        data-cy="expectedAssetInflow"
                        label={t('incomeAndLiquidity.expectedAssetsInflow')}
                        error={errors?.expectedAssetInflow?.message}
                        thousandSeparator="’"
                        decimalSeparator="."
                        prefix="CHF "
                    />
                )}
            />

            <Controller
                name="originOfAssetInflow"
                control={control}
                render={({ field }) => (
                    <TextInput
                        {...field}
                        data-cy="originOfAssetInflow"
                        label={t('incomeAndLiquidity.originOfAssetsInflow')}
                        error={errors?.originOfAssetInflow?.message}
                    />
                )}
            />

            <h3 className="span title">{t('incomeAndLiquidity.loansTitle')}</h3>
            {!fields?.length && <p className="span">{t('incomeAndLiquidity.noLoans')}</p>}
            {!!fields?.length && (
                <div className="span loans">
                    <p>{t('incomeAndLiquidity.institution')}</p>
                    <p>{t('incomeAndLiquidity.amount')}</p>
                    <div />
                    {fields.map((item: LoanResponse & {id: string}, index: number) => (
                        <Fragment key={item.id}>
                            <Controller
                                name={`loans.${index}.instituteListItemId`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        value={item?.instituteListItemId}
                                        data-cy={`loans.${index}.instituteListItemId`}
                                        options={instituteOptions?.data}
                                        placeholder={t('position.pleaseSelect')}
                                        error={errors?.[`loans[${index}].instituteListItemId`] ? t('validation.mandatoryField') : ''}
                                    />
                                )}
                            />
                            <Controller
                                name={`loans.${index}.amount`}
                                control={control}
                                render={({ field }) => (
                                    <NumberInput
                                        {...field}
                                        data-cy={`loans.${item.id}.amount`}
                                        error={errors?.[`loans[${index}].instituteListItemId`] ? t('validation.mandatoryField') : ''}
                                        thousandSeparator="’"
                                        decimalSeparator="."
                                        prefix="CHF "
                                    />
                                )}
                            />
                            <Icon className="delete" onClick={() => removeLoan(index)} type="delete" size={24}/>
                        </Fragment>
                    ))}
                </div>
            )}
            <Button className="add-loan" onClick={addNewLoan} >
                <Icon type="plus" size={24} />
                {t('incomeAndLiquidity.addLoan')}
            </Button>
        </section>
    );
};

export default IncomeAndLiquidity;
