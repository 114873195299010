import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import Error from 'components/AtomicStructure/atoms/Error';
import Input from 'components/AtomicStructure/atoms/Input';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import { useEditCustomer } from 'domain/ClientService';
import { useContact } from 'domain/ClientService/hooks/useContact';
import { withMainLayout } from 'hocs';
import { uploadDocumentsPageLink } from 'pages/ClientOnboardingFlow/helpers/links';
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ServiceManager from 'services/ServiceManager';
import { Loader } from 'ui-library';
import { compareDates } from 'utils/datetime';
import {
    date, number, object, string,
} from 'yup';
import ButtonsBlockRow from '../../../../../components/ButtonsBlockRow';

import { MailingAddress } from '../../../../../components/MailingAddress';
import { TARGET_FIELDS } from '../../../../../components/MailingAddress/MailingAddress';
import './NewPersonalInformationPage.css';
import { OPTION_LIST, STARTBIRTHDAY, switzLandCountryId } from '../../../../../constants/constants';
import { useOptionList } from '../../../../../hooks/useOptionList';
import {
    CheckboxGroup, DatePicker, Infobox, Modal, Title,
} from '../../../../../ui-library';
import { isEmpty } from '../../../../../utils';
import {
    IsAllDataNull,
    validateDateOfBirth,
    validateEmailAdress,
    validateInputLength,
    validateInputNumber,
    validateInputString,
    validateName,
    validatePhoneNumber,
    validateRadio,
    validateSelect,
} from '../../../../../validation/validation';
import { FIELD_LENGTH } from '../../../../ClientOverview/constants';
import { setUserRegisterId } from '../../../helpers/userRegisterId';
import { postCodeSwitzland } from './constants/options';
import { autocomplete } from './helpers/autocomplete';
import { convertDate, getOldness } from './helpers/convertDate';
import { errorModel } from './models/errorModel';
import { getModel } from './models/getModel';
import { sendModel } from './models/sendModel';
import { getModelByZKP } from './models/zkpClientModel';
import { getUserId } from './services/getUserId';

const zkpUserSchema = object({
    birthDate: date().nullable(true),
    city: string().nullable(true),
    civilStatus: number().nullable(true),
    countryOfResidence: number().integer().nullable(true),
    email: string().email().nullable(true),
    firstName: string().nullable(true),
    lastName: string().nullable(true),
    gender: number().integer().nullable(true),
    language: number().integer().nullable(true),
    mobileNumber: string().nullable(true),
    postCode: string().nullable(true),
    street: string().nullable(true),
    streetNumber: string().nullable(true),
});

const NewPersonalInformationPage = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const { state } = useLocation();
    const mounted = useRef(false);
    const contactExternalId = useMemo(() => (state?.externalClientId), [state?.externalClientId]);

    const usCitizenOption = [
        { label: i18n.t('clientDashboard.yes'), id: true },
        { label: i18n.t('clientDashboard.no'), id: false },
    ];
    const userRegisterId = getUserId();
    const { getContact } = useContact({ contactId: userRegisterId });
    const {
        lists: [optionGender, optionMaritalStatus, optionLanguage, optionCountry],
    } = useOptionList([OPTION_LIST.gender, OPTION_LIST.maritalStatus, OPTION_LIST.language, OPTION_LIST.country]);
    const { createContact } = useEditCustomer();

    const [isSubmitTry, setSubmitTry] = useState(false);
    const [isJunior, setJunior] = useState(false);
    const [data, setData] = useState({});
    const [cachedData, setCachedData] = useState({});

    const [dataReadOnly, setdataReadOnly] = useState(false);
    const [dataReadOnlySecondary, setdataReadOnlySecondary] = useState(false);

    const [error, setError] = useState(null);
    const [errorData, setErrorData] = useState({});
    const [errorPage, setErrorPage] = useState();

    const [isLoading, setLoading] = useState(true);
    const [loadingUserData, setloadingUserData] = useState(false);
    const [isLoadingAPICall, setLoadingAPICall] = useState(false);

    useEffect(() => {
        mounted.current = true;

        return () => { mounted.current = false; };
    }, []);

    const goBack = () => {
        navigate('/clients');
    };

    useEffect(() => {
        if (contactExternalId) {
            (async () => {
                setLoadingAPICall(true);
                try {
                    const response = await ServiceManager.customClientService('getContactDetails', [{ contactExternalId }]);
                    const validatedData = await zkpUserSchema.validate(response.data);
                    const adaptedData = getModelByZKP(validatedData);

                    const newData = { ...data, ...adaptedData };

                    setData(newData);
                    setCachedData(newData);
                } catch (err) {
                    delete state?.externalClientId;
                    setErrorPage(t('onboardingFlow.personalPage.zkp.error.clientDetailsNotAvailable'));
                } finally {
                    setLoadingAPICall(false);
                }
            })();
        }
    }, [contactExternalId]);

    const goToAction = async () => {
        setSubmitTry(true);
        try {
            setLoadingAPICall(true);
            const errors = await errorModel(data, userRegisterId, isJunior);

            setErrorData(errors);
            setErrorPage(t(errors.firstLastLengthCombined, { max: FIELD_LENGTH.firstAndLastNameCombined }));
            if (IsAllDataNull(errors) === false) {
                return;
            }

            if (userRegisterId) {
                /* Update user */
                await ServiceManager.clientService('patchContact', [userRegisterId, { details: sendModel(data) }]);
                navigate(uploadDocumentsPageLink);
            } else {
                /* Create new user */
                const memberId = localStorage.getItem('memberId');

                const dataUser = await createContact({
                    data: {
                        contactTypeId: 1, memberIds: [memberId], contactExternalId, details: sendModel(data),
                    },
                });

                setUserRegisterId(dataUser.id);
                navigate(uploadDocumentsPageLink);
            }
        } catch (err) {
            const errorMessage = [err?.response?.data?.properties?.ValidationErrors?.[0]?.message, err?.error?.response?.data?.errorMessages?.[0]].filter(f => !!f);

            setErrorPage(errorMessage?.[0] || err.message);
        } finally {
            setLoadingAPICall(false);
        }
    };

    useEffect(() => {
        /* Get user data */
        if (userRegisterId) {
            setloadingUserData(true);
            getContact({
                contactId: userRegisterId,
            }).then((contactDataForm) => {
                setData(getModel(contactDataForm));
            }).catch((err) => {
                setError({ message: err.message });
            }).finally(() => setloadingUserData(false));
        }
    }, [userRegisterId]);

    useEffect(() => {
        if (optionCountry !== undefined && optionMaritalStatus !== undefined && optionLanguage !== undefined && optionCountry !== undefined) {
            setLoading(false);
        }
    }, [optionGender, optionMaritalStatus, optionLanguage, optionCountry]);

    useEffect(() => {
        // TO DO: Refactor
        // eslint-disable-next-line eqeqeq
        if (data.countryOfResidence == switzLandCountryId) {
            // setdataReadOnly(true);
            setData({ ...data, readOnly: true });
            const index = autocomplete(data.countryOfResidence,
                postCodeSwitzland, switzLandCountryId, data.PLZ);

            if (index) {
                const ORT = postCodeSwitzland[index]?.Ortschaftsname;

                setErrorData({
                    ...errorData,
                    PLZ: validateInputString(data.PLZ),
                    ORT: ORT && (validateInputString(ORT) || validateInputLength(ORT, FIELD_LENGTH.city)),
                });
                setData({
                    ...data,
                    ORT,
                });
            } else {
                setData({ ...data, ORT: '' });
            }
        } else {
            setData({ ...data, readOnly: false });
            // setdataReadOnly(false);
        }
    }, [data.PLZ, data.countryOfResidence]);

    useEffect(() => {
        // TO DO: Refactor
        // eslint-disable-next-line eqeqeq
        if (data.land == switzLandCountryId) {
            setdataReadOnlySecondary(true);
            setData({ ...data, readOnlySecondary: true });
            const index = autocomplete(data.land, postCodeSwitzland,
                switzLandCountryId, data.secondaryPLZ);

            if (index) {
                setErrorData({
                    ...errorData,
                    secondaryPLZ: validateInputString(data.secondaryPLZ),
                });
                setData({
                    ...data,
                    secondaryORT:
                    postCodeSwitzland[index]?.Ortschaftsname,
                });
            } else {
                setData({ ...data, secondaryORT: '' });
            }
        } else {
            setData({ ...data, readOnlySecondary: false });
            setdataReadOnlySecondary(false);
        }
    }, [data.secondaryPLZ, data.land]);

    useEffect(() => {
        if (data.land !== switzLandCountryId && data.readOnlySecondary === false) {
            setData({ ...data, secondaryORT: '' });
        }
    }, [data.readOnlySecondary]);

    useEffect(() => {
        if (isSubmitTry) {
            (async () => {
                const errors = await errorModel(data, userRegisterId, isJunior);

                setErrorData(errors);
            })();
        }
    }, [i18n.language]);

    const changeMobileMask = (value, key, symbol) => {
        setErrorData({
            ...errorData,
            [key]: validatePhoneNumber(value),
        });
        if (value !== undefined) {
            if ((value).indexOf(symbol) === -1) {
                setData({ ...data, [key]: symbol.concat(value) });
            } else {
                setData({ ...data, [key]: value });
            }
        }
    };

    const passData = (value, key) => {
        setErrorData({ ...errorData, [key]: validateRadio(value) });

        const newData = { ...data };

        newData[key] = value;

        setData(newData);
    };

    const onDateClick = (chosenDate) => {
        const formatDateValue = convertDate(chosenDate);

        setData({ ...data, dateOfBirth: `${formatDateValue}`, hideDatePickerValue: false });
        setErrorData({
            ...errorData,
            dateOfBirth: validateDateOfBirth(formatDateValue),
        });
    };

    useEffect(() => {
        if (data?.dateOfBirth) {
            const fullYears = getOldness(data?.dateOfBirth);
            const isJuniorCase = fullYears < 18;

            setJunior(isJuniorCase);
            if (isSubmitTry) {
                setErrorData({
                    ...errorData,
                    mailRecipientAddress: isJuniorCase ? validateInputNumber(data.mailRecipientAddressCountry) : null,
                });
            }
        }
    }, [data?.dateOfBirth]);

    const handleSubmitMailingAddress = useCallback(async (mailingData, action) => {
        if (userRegisterId) {
            await ServiceManager.customClientService('patchMailingAddress', [{
                contactId: userRegisterId,
                data: mailingData,
                action,
            }]);
            if (action === 1) {
                Modal.success({
                    title: t('MailingAddress.successful.delete.title'),
                    content: t('MailingAddress.success.delete.content'),
                    okText: t('MailingAddress.success.okButton.label'),
                    onOk: () => { global.location.reload(); },
                });
            } else {
                global.location.reload();
            }
            global.location.reload();
        } else if (action === 1) {
            const dataCopy = { ...data };

            (TARGET_FIELDS.forEach((fieldName) => {
                delete dataCopy?.[fieldName];
            }));
            setData(dataCopy);
            setErrorData({
                ...errorData,
                mailRecipientAddress: isJunior ? validateInputNumber(dataCopy.mailRecipientAddressCountry) : null,
            });
            Modal.success({
                title: t('MailingAddress.successful.delete.title'),
                content: t('MailingAddress.success.delete.content'),
                okText: t('MailingAddress.success.okButton.label'),
            });
        } else {
            const mailRecipientAddress = {
                ...(TARGET_FIELDS.reduce((accu, fieldName) => {
                    accu[fieldName] = mailingData?.[fieldName];

                    return accu;
                }, {})),
            };

            setData({
                ...data,
                ...mailRecipientAddress,
            });
            setErrorData({
                ...errorData,
                mailRecipientAddress: isJunior ? validateInputNumber(mailRecipientAddress.mailRecipientAddressCountry) : null,
            });
        }
    }, [data, contactExternalId, errorData, isJunior]);

    return (
        <NewWrapper
            stepNavBarActive={0}
            headTitle={t('onboardingFlow.customerOnboarding.title')}
        >
            <PageSection className="newStyle NewPersonalInformationPage">
                <div className="newStyleContent">
                    <Preloader isLoading={isLoading || loadingUserData} error={error}>
                        <div className="container">
                            <Title type={2}>
                                {t('onboardingFlow.personalPage.personalInformation')}
                            </Title>

                            <div className="desktop-col">
                                <div className="form">
                                    <ButtonRadioElement
                                        data-cy="gender"
                                        label={t('onboardingFlow.personalPage.gender')}
                                        value={data?.gender}
                                        nameData="gender"
                                        items={optionGender}
                                        passData={passData}
                                        error={errorData?.gender}
                                        required
                                    />
                                    <Input
                                        data-cy="firstName"
                                        label={t('onboardingFlow.personalPage.firstNames')}
                                        value={data?.firstName}
                                        onChange={(e) => {
                                            setData({ ...data, firstName: e?.target?.value });
                                            setErrorData({
                                                ...errorData,
                                                firstName: validateName(e?.target?.value) || validateInputLength(e?.target?.value, FIELD_LENGTH.firstName),
                                            });
                                        }}
                                        disabled={!isEmpty(cachedData?.firstName)}
                                        error={errorData?.firstName}
                                        required
                                    />

                                    <Input
                                        data-cy="lastName"
                                        label={t('onboardingFlow.personalPage.surname')}
                                        value={data?.lastName}
                                        onChange={(e) => {
                                            setData({ ...data, lastName: e?.target?.value });
                                            setErrorData({
                                                ...errorData,
                                                lastName: validateName(e?.target?.value) || validateInputLength(e?.target?.value, FIELD_LENGTH.lastName),
                                            });
                                        }}
                                        disabled={!isEmpty(cachedData?.lastName)}
                                        error={errorData?.lastName}
                                        required
                                    />

                                    <div
                                        onMouseEnter={() => {
                                            if (!data?.dateOfBirth) {
                                                setData({
                                                    ...data,
                                                    dateOfBirth: STARTBIRTHDAY,
                                                    hideDatePickerValue: false,
                                                });
                                            }
                                        }}
                                        className={data?.hideDatePickerValue === undefined ? 'hideDatePickerValue' : null}
                                    >
                                        <DatePicker
                                            data-cy="dateOfBirth"
                                            label={t('onboardingFlow.personalPage.dateOfBirth')}
                                            value={data?.dateOfBirth ? data?.dateOfBirth : STARTBIRTHDAY}
                                            onChange={onDateClick}
                                            disabledDate={(current) => current
                                                && !compareDates(
                                                    new Date(), current,
                                                )}
                                            error={errorData?.dateOfBirth}
                                            format="DD.MM.YYYY"
                                            disabled={!isEmpty(cachedData?.dateOfBirth)}
                                            required
                                        />
                                    </div>

                                    <SelectElement
                                        data-cy="countryOfResidence"
                                        label={t('onboardingFlow.personalPage.countryOfResidence')}
                                        options={optionCountry}
                                        value={data?.countryOfResidence}
                                        onChange={(val) => {
                                            setData({ ...data, countryOfResidence: `${val}` });
                                            setErrorData({
                                                ...errorData,
                                                countryOfResidence: validateSelect(`${val}`),
                                            });
                                        }}
                                        error={errorData?.countryOfResidence}
                                        required
                                    />

                                    <div className="form-group-row">
                                        <Input
                                            data-cy="street"
                                            label={`${t('onboardingFlow.personalPage.strret')}/${t('onboardingFlow.personalPage.number')}`}
                                            onChange={(e) => {
                                                setData({ ...data, street: e?.target?.value });
                                                setErrorData({
                                                    ...errorData,
                                                    street: validateInputString(e?.target?.value) || validateInputLength(e?.target?.value, FIELD_LENGTH.street),
                                                });
                                            }}
                                            value={data?.street}
                                            error={errorData?.street}
                                            required
                                        />
                                    </div>

                                    <div className="form-group-row">
                                        <Input
                                            data-cy="PLZ"
                                            label={t('onboardingFlow.personalPage.plz')}
                                            type="number"
                                            onChange={(e) => {
                                                setData({ ...data, PLZ: e?.target?.value });
                                                setErrorData({
                                                    ...errorData,
                                                    PLZ: validateInputNumber(e?.target?.value),
                                                });
                                            }}
                                            value={data?.PLZ}
                                            error={errorData?.PLZ}
                                            required
                                        />

                                        <Input
                                            data-cy="ORT"
                                            label={t('onboardingFlow.personalPage.ort')}
                                            onChange={(e) => {
                                                setData({ ...data, ORT: e?.target?.value });
                                                setErrorData({
                                                    ...errorData,
                                                    ORT: validateInputString(e?.target?.value) || validateInputLength(e?.target?.value, FIELD_LENGTH.city),
                                                });
                                            }}
                                            value={data?.ORT}
                                            error={errorData?.ORT}
                                            disabled={dataReadOnly}
                                            required
                                        />
                                    </div>

                                    <SelectElement
                                        data-cy="maritalStatus"
                                        label={t('onboardingFlow.personalPage.maritalStatus')}
                                        options={optionMaritalStatus}
                                        value={data?.maritalStatus}
                                        onChange={(val) => {
                                            setData({ ...data, maritalStatus: `${val}` });
                                            setErrorData({
                                                ...errorData,
                                                maritalStatus: validateSelect(`${val}`),
                                            });
                                        }}
                                        error={errorData?.maritalStatus}
                                        required
                                    />

                                    <SelectElement
                                        data-cy="countryOfNationality"
                                        label={t('onboardingFlow.personalPage.countryOfNationality')}
                                        value={data?.countryOfNationality}
                                        onChange={(val) => {
                                            setData({ ...data, countryOfNationality: `${val}` });
                                            setErrorData({
                                                ...errorData,
                                                countryOfNationality: validateSelect(`${val}`),
                                            });
                                        }}
                                        options={optionCountry}
                                        error={errorData?.countryOfNationality}
                                        required
                                    />

                                    <SelectElement
                                        data-cy="language"
                                        label={t('onboardingFlow.personalPage.language')}
                                        options={optionLanguage}
                                        onChange={(val) => {
                                            setData({ ...data, language: `${val}` });
                                            setErrorData({
                                                ...errorData,
                                                language: validateSelect(`${val}`),
                                            });
                                        }}
                                        value={data?.language}
                                        error={errorData?.language}
                                        required
                                    />

                                    <MailingAddress
                                        error={errorData?.mailRecipientAddress}
                                        required={isJunior}
                                        initData={data}
                                        onSubmit={handleSubmitMailingAddress}
                                    />

                                    <Input
                                        data-cy="primaryPhoneNumber"
                                        label={t('dynamicCRM.communicationMethods.primaryPhoneNumber')}
                                        type="tel"
                                        value={data?.primaryPhoneNumber}
                                        onChange={(e) => {
                                            changeMobileMask(e?.target?.value, 'primaryPhoneNumber', '+');
                                        }}
                                        error={errorData?.primaryPhoneNumber}
                                        required
                                    />

                                    <Input
                                        data-cy="primaryEmail"
                                        label={t('onboardingFlow.personalPage.email')}
                                        value={data?.primaryEmail}
                                        onChange={(e) => {
                                            setData({ ...data, primaryEmail: e?.target?.value });
                                            setErrorData({
                                                ...errorData,
                                                primaryEmail: validateEmailAdress(e?.target?.value),
                                            });
                                        }}
                                        error={errorData?.primaryEmail}
                                        required
                                    />

                                    <fieldset>
                                        <Title tagName="legend">
                                            {t('onboardingFlow.personalPage.taxInformation')}
                                        </Title>
                                        <div className="form">
                                            <SelectElement
                                                data-cy="countryOfBirth"
                                                label={t('onboardingFlow.personalPage.countryOfBirth')}
                                                value={data?.countryOfBirth}
                                                onChange={(val) => {
                                                    setData({ ...data, countryOfBirth: `${val}` });
                                                    setErrorData({
                                                        ...errorData,
                                                        countryOfBirth: validateSelect(`${val}`),
                                                    });
                                                }}
                                                options={optionCountry}
                                                error={errorData?.countryOfBirth}
                                                required
                                            />

                                            <ButtonRadioElement
                                                data-cy="usCitizen"
                                                label={t('onboardingFlow.personalPage.usCitizen')}
                                                items={usCitizenOption}
                                                value={data?.usCitizen}
                                                nameData="usCitizen"
                                                passData={passData}
                                                error={errorData?.usCitizen}
                                                required
                                            />

                                            <ButtonRadioElement
                                                data-cy="hasUsResidence"
                                                label={t('dynamicCRM.taxSituation.hasUsResidence')}
                                                items={usCitizenOption}
                                                value={data?.hasUsResidence}
                                                nameData="hasUsResidence"
                                                passData={passData}
                                                error={errorData?.hasUsResidence}
                                                description={t('dynamicCRM.taxSituation.isUsPersonDesc')}
                                                required
                                            />

                                            <ButtonRadioElement
                                                data-cy="isUsPerson"
                                                label={t('dynamicCRM.taxSituation.isUsPerson')}
                                                items={usCitizenOption}
                                                value={data?.isUsPerson}
                                                nameData="isUsPerson"
                                                passData={passData}
                                                error={errorData?.isUsPerson}
                                                required
                                            />

                                            <fieldset>
                                                {data && (
                                                    <legend className="Form-control-label">
                                                        {`${t('dynamicCRM.taxSituation.customerConfirmation')} *`}
                                                    </legend>
                                                )}
                                                <CheckboxGroup
                                                    value={data.classedUsPerson ?? []}
                                                    options={[{ value: false, label: t('dynamicCRM.taxSituation.customerConfirmationNo'), position: 2 }, { value: true, label: t('dynamicCRM.taxSituation.customerConfirmationYes'), position: 1 }]}
                                                    error={errorData?.classedUsPerson}
                                                    size="big"
                                                    onChange={(item) => passData(item, 'classedUsPerson')}
                                                    defaultValue={[]}
                                                    isConditional
                                                    isInQuestionnaire
                                                    required
                                                />
                                            </fieldset>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            {isJunior && errorData?.mailRecipientAddress && (
                                <Infobox error showIcon={false} className="mb-16">
                                    {t('clientDetails.mailingAddress.juniorMailAddressRequiredMessage')}
                                </Infobox>
                            )}
                        </div>

                        <div className="JourneyFooter">
                            <div className="container">
                                {errorPage && <Error>{JSON.stringify(errorPage)}</Error>}

                                <ButtonsBlockRow
                                    additionalButton={{
                                        text: t('onboardingFlow.documetsPage.back'),
                                        onClick: () => goBack(),
                                        disabled: isLoadingAPICall,
                                        'data-cy': 'back',
                                    }}
                                    primaryButton={{
                                        text: isLoadingAPICall ? <Loader /> : t('onboardingFlow.clientsDataPage.continue'),
                                        onClick: () => goToAction(),
                                        disabled: isLoadingAPICall,
                                        'data-cy': 'continue',
                                    }}
                                />
                            </div>
                        </div>

                    </Preloader>
                </div>
            </PageSection>
        </NewWrapper>
    );
};

export default withMainLayout(NewPersonalInformationPage);
