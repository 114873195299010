import Preloader from 'components/Preloader';
import { THREEBTYPES } from 'constants/constants';
import React from 'react';
import { LineChart, ProjectionChart } from 'ui-library';
import { useGoalAndPerformance } from 'widgets/GoalAndPortfolioSetup/hooks/useGoalAndPortfolio';

const ProjectionChartComp = ({
    product,
    continueDisabled,
    t,
}) => {
    const {
        projectionOptions,
        currency,
        chartData,
        returnRates,
        isLoading: chartDataInProgress,
        azpXAxisFormat,
        azpYAxisFormat,
    } = useGoalAndPerformance({
        modelPortfolioId: product?.modelPortfolioId,
        continueDisabledFromProps: continueDisabled,
    });

    return (
        <div>
            <Preloader isLoading={chartDataInProgress}>
                <ProjectionChart
                    data={chartData}
                    {...projectionOptions}
                    yTitle={currency}
                    yearsText={t('charts.years')}
                    returnRates={returnRates}
                    showLegend
                />
            </Preloader>

        </div>
    );
};

export default React.memo(ProjectionChartComp);
