import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { getClientId } from 'utils';
import { Product } from 'core/types/api/Api';
import { INVESTOR_PROFILE_CONTACT_ID, THREEBTYPES } from 'constants/constants';
import { useProductAllocation } from '../../../../../hooks/useProductAllocation';
import { LiquidityId } from '../../../../../../../../../constants/instrument';
import ServiceManager from '../../../../../../../../../services/ServiceManager';
import { PORTFOLIO_BLOCKED_ID, PORTFOLIO_CLOSED_ID } from '../../../../../../../../../constants/portfolioStatuses';
import { parseXml } from '../../../../../../../../../utils/xmlParser';
import {
    BodyItemType,
    FooterItemType,
    IndividualPortfolioDataType,
} from '../../../../../../../../../ViewModels/ModelPortfolioDetails';
import { useCachedAPI } from '../../../../../../../../../hooks/useCachedAPI';
import { adaptAllocations } from '../adapters/adaptProposalData';
import { riskCategoriesByLang } from '../../../../../constants';
import { useModelList } from '../../../../../hooks/useModelList';

enum FeeType {
    Asset = 1,
    Custody = 0,
    Entry = 2,
}

type Fee = {
    discountValue: number | null,
    expiryDate: string | null,
    fee: number | null,
    feeType: FeeType,
    startDate: string | null
}

export const useStrategy = ({ dfsClientId, portfolioId }) => {
    const { i18n: { language }, t } = useTranslation();
    const clientId = getClientId(dfsClientId);
    const [portfolio, setPortfolio] = useState<any>(true);
    const [isIndividualPortfolio, setIndividualPortfolio] = useState<boolean>(false);

    const [modelPortfolioId, setModelPortfolioId] = useState();
    const [productId, setProductId] = useState();
    const [modelPortfolio, setModelPortfolio] = useState<any>();

    const [positions, setPositions] = useState([]);
    const [isRebalancing, setRebalancing] = useState([]);

    const [isInitLoading, setInitLoading] = useState<boolean>(true);
    const [individualPortfolioData, setIndividualPortfolioData] = useState<IndividualPortfolioDataType<Partial<BodyItemType>, Partial<FooterItemType>>>({ body: [], footer: {} });
    const [strategyError, setStrategyError] = useState<any>();
    const [portfolioProducts, setPortfolioProducts] = useState<Partial<Product>[]>([]);
    const [getOngoingChangePlanInProgress, setOnGoingChangePlanInProgress] = useState(false);
    const [ongoingPlan, setOngoingPlan] = useState<{current:string, submitted:string}|null>(null);
    const [fees, setFees] = useState<Array<Fee>>([]);
    const [investmentAppPersonalDetails, setInvestmentAppPersonalDetails] = useState(null);
    const [withdrawal, setWithdrawal] = useState(null);
    const [investmentAppId, setInvestmentAppId] = useState(null);
    const [withdrawalData, setWithdrawalData] = useState(null);

    const {
        getPortfolioDetails,
        getLastApplicationByPortfolio,
        getInvestmentApplication,
        getModelPortfolio,
        getPortfolioProposal,
        getWithdrawalData,
    } = useCachedAPI();

    const getData = useCallback(async () => {
        try {
            setInitLoading(true);

            const [
                portfolioDetails,
                lastApplicationByPortfolio,
                withdrawalDataResponse,
            ] = await Promise.all([
                getPortfolioDetails([portfolioId, clientId, { language }]),
                getLastApplicationByPortfolio([{ portfolioId, language }]),
                getWithdrawalData([{ portfolioId, language }]),
            ]);

            const investmentApplicationId = lastApplicationByPortfolio?.investmentApplicationId;
            const investmentApplicationData = await getInvestmentApplication([{ investmentApplicationId }]);

            sessionStorage.setItem(INVESTOR_PROFILE_CONTACT_ID, investmentApplicationData?.investmentDescription?.investorProfileContactId);

            const portfolioProposalId = investmentApplicationData?.investmentDescription?.portfolioProposalId;
            const mpId = investmentApplicationData?.investmentDescription?.selectStrategy?.modelPortfolioId;
            const modelPortfolioData = await getModelPortfolio([mpId, { language }]);

            const isIndividual = !!portfolioDetails?.PortfolioProposalId;

            setIndividualPortfolio(isIndividual);

            if (isIndividual) {
                const portfolioProposal = await getPortfolioProposal([clientId, portfolioProposalId, { language }]);
                const portfolioProposalPositions = portfolioProposal?.Positions;

                setPositions(portfolioProposalPositions);
                setIndividualPortfolioData(adaptAllocations(portfolioProposalPositions));
            } else {
                setPositions(portfolioDetails?.Positions);
            }

            // HOTFIX: mapro issue: do not show nextTransfer and startOfWithdrawal sections
            if (portfolio?.Product?.ExternalId === THREEBTYPES.azpNeuSB) {
                delete withdrawalDataResponse?.nextTransfer;
                delete withdrawalDataResponse?.startOfWithdrawal;
            }
            // HOTFIX: mapro issue: this will hide withdrawal section at all
            if (portfolio?.Product?.ExternalId === THREEBTYPES.azpAlt) {
                delete withdrawalDataResponse?.bankIban;
            }

            setWithdrawalData(withdrawalDataResponse);
            setPortfolio(portfolioDetails);
            setProductId(portfolioDetails?.Product?.Id);
            setRebalancing(investmentApplicationData?.investmentDescription?.selectStrategy?.rebalancing);
            setModelPortfolio(modelPortfolioData);
            setModelPortfolioId(mpId);
            setFees(investmentApplicationData.investmentDescription.fees);
            setInvestmentAppPersonalDetails(investmentApplicationData.investmentDescription.personalDetails);
            setWithdrawal(investmentApplicationData.investmentDescription.withdrawal);
            setInvestmentAppId(investmentApplicationId);
        } catch (error) {
            setStrategyError(error);
        } finally {
            setInitLoading(false);
        }
    }, [portfolioId, clientId, language, portfolio?.Product?.ExternalId]);

    const isPortfolioOnReadOnly = useMemo(() => (
        portfolio?.PortfolioStatusId !== PORTFOLIO_CLOSED_ID && portfolio?.PortfolioStatusId !== PORTFOLIO_BLOCKED_ID
    ), [portfolio?.PortfolioStatusId]);

    const isPortfolioChangeable = useMemo(() => (
        isPortfolioOnReadOnly && ![THREEBTYPES.azpNeu, THREEBTYPES.azpNeuSB, THREEBTYPES.azpAlt, THREEBTYPES.zic, THREEBTYPES.pk].includes(portfolio?.Product?.ExternalId)
    ), [isPortfolioOnReadOnly, portfolio?.Product?.ExternalId]);

    const checkIfChangePlanInProgress = useCallback(async () => {
        try {
            setOnGoingChangePlanInProgress(true);
            const { data } = await ServiceManager.planService('checkIfChangePlanInProgress', [{ portfolioId }]);

            if (data) {
                const planData = {
                    current: parseXml(data.currentModelPortfolio)?.root,
                    submitted: parseXml(data.submitedModelPortfolio)?.root,
                    portfolioId,
                };

                setOngoingPlan(planData);
            } else {
                setOngoingPlan(null);
            }
        } finally {
            setOnGoingChangePlanInProgress(false);
        }
    }, [portfolioId]);

    useEffect(() => {
        (async () => checkIfChangePlanInProgress())();
    }, [checkIfChangePlanInProgress]);

    const {
        strategies,
        products,
    } = useModelList({ productId });

    useEffect(() => {
        if (isIndividualPortfolio && !!products.length && !!positions?.length && modelPortfolio) {
            (async () => {
                try {
                    const chosenSecurityIds: number[] = [];

                    const portfoliosPositions = positions.reduce(
                        (akku: any, portfolioPosition: any) => {
                            if (portfolioPosition?.Security?.Type?.Id !== LiquidityId) {
                                akku.push({
                                    allocation: `${Math.round(portfolioPosition?.Allocation * 100)}%`,
                                    name: portfolioPosition?.Security?.Name ?? '-',
                                    positionId: portfolioPosition?.Id,
                                    securityId: portfolioPosition?.Security?.Id,
                                    modelPortfolioId: portfolioPosition?.Id,
                                    factsheetUrl: portfolioPosition?.Factsheet?.replace('url:', ''),
                                });
                            }

                            return akku;
                        }, [],
                    ).reduce((accu, item) => {
                        if (!chosenSecurityIds.includes(item.securityId)) {
                            chosenSecurityIds.push(item.securityId);
                            accu.push(item);
                        }

                        return accu;
                    }, []);

                    setPortfolioProducts(portfoliosPositions);
                } catch (error) {
                    setStrategyError(error);
                    // eslint-disable-next-line no-console
                    console.error('Error: useStrategy: cannot get list of products based on current portfolio', error);
                }
            })();
        }
    }, [positions, products, language, isIndividualPortfolio]);

    useEffect(() => {
        if (!isIndividualPortfolio && modelPortfolio) {
            (async () => {
                try {
                    setPortfolioProducts([{
                        name: modelPortfolio?.Name,
                        factsheetUrl: modelPortfolio.Factsheet?.replace('url:', ''),
                    }]);
                } catch (error) {
                    setStrategyError(error);
                    // eslint-disable-next-line no-console
                    console.error('Error: useStrategy: cannot get list of products based on current portfolio', error);
                }
            })();
        }
    }, [positions, language, isIndividualPortfolio, modelPortfolio]);

    useEffect(() => {
        setStrategyError(undefined);
        getData();
    }, [getData]);

    const { getFormattedXAxisFormat } = useFormatting();

    const {
        allocations,
        isLoading: isAllocationsLoading,
        factsheetUrl,
        error: allocationError,
        riskScore,
    } = useProductAllocation({ productId: modelPortfolioId, individualPortfolioData });
    const isLoading = useMemo(() => (isAllocationsLoading || getOngoingChangePlanInProgress || isInitLoading),
        [isAllocationsLoading, getOngoingChangePlanInProgress, isInitLoading]);
    const error = useMemo(() => (strategyError || allocationError), [isAllocationsLoading, strategyError, allocationError]);
    const strategyName = useMemo(() => (portfolio?.RiskCategory?.Name), [portfolio?.RiskCategory?.Name]);
    const modelPortfolioDescription = useMemo(() => modelPortfolio?.Description, [modelPortfolio?.Description]);

    const getSelectStrategy = useCallback(() => Number(riskScore), [riskScore]);
    const riskCategories = useMemo(() => (riskCategoriesByLang(t)), [t]);
    const riskName = useMemo(() => {
        const risk: any = strategies?.find(({ Id }) => (riskScore === (Id - 1)));

        return risk?.Name ?? '';
    },
    [riskScore, language]);

    return {
        t,
        riskName,
        modelPortfolioDescription,
        modelPortfolio,
        isPortfolioChangeable,
        isPortfolioOnReadOnly,
        portfolioProducts,
        isIndividualPortfolio,
        clientId,
        strategyName,
        riskCategories,
        getSelectStrategy,
        getFormattedXAxisFormat,
        allocations,
        factsheetUrl,
        isRebalancing,
        error,
        isLoading,
        portfolioData: portfolio,
        ongoingPlan,
        fees,
        setFees,
        investmentAppPersonalDetails,
        withdrawal,
        withdrawalData,
        investmentAppId,
    };
};
