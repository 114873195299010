import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    portfolioSelector, proposalSelector, usePortfolioSelector,
} from 'domain/Portfolio';
import { getClientId } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';
import useCommonAllocationDataProvider from 'pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import { SELECETED_PRODUCT_TYPE } from 'pages/ClientOverview/constants';
import { useCachedAPI } from 'hooks/useCachedAPI';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import { THREEBTYPES } from 'constants/constants';
import { useChangePlan } from '../../context';
import { useStrategySelection } from '../../../../hooks/useStrategySelection';
import StrategySelection from '../../../../components/StrategySelection/StrategySelection';
import { Modal } from '../../../../../../../../ui-library';
import { useProductsList } from '../../../../../../../../hooks/useProducts';

function StrategySelectionPage() {
    const navigate = useNavigate();
    const { portfolioId, dfsClientId } = useParams();
    const { i18n: { language } } = useTranslation();
    const clientId = useMemo(() => getClientId(dfsClientId), [dfsClientId]);
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`, [dfsClientId, portfolioId]);
    const {
        productId,
        strategy: { setData: setStrategy, data: strategyData },
        riskProfile: { data: { recommendedStrategy: cachedStrategy } },
        layout: { setData: setLayoutData, setPageErrors },
        isThreeBProduct: is3bProduct,
    } = useChangePlan();
    const proposalManger = usePortfolioSelector(
        proposalSelector,
    );
    const { dataRaw: portfolio } = usePortfolioSelector(portfolioSelector);
    const [initialPortfolioProductAttributes, setInitialPortfolioProductAttributes] = useState({});
    const [cachedInvestmentHorizon, setCachedInvestmentHorizon] = useState();
    const [productMissingError, setProductMissingError] = useState(null);
    const {
        products: productsList,
        getProducts,
    } = useProductsList();

    const {
        getLastApplicationByPortfolio,
    } = useCachedAPI();

    useEffect(() => {
        const getInvestmentIdByPortfolioId = async () => {
            try {
                const lastApplicationByPortfolio = await getLastApplicationByPortfolio([{ portfolioId, language }], { withCache: true });

                setCachedInvestmentHorizon(lastApplicationByPortfolio?.investmentHorizon);
            } catch (e) {
                // handle error
            }
        };

        if (portfolioId && is3bProduct) {
            getInvestmentIdByPortfolioId();
        }
    }, [portfolioId, language, is3bProduct]);

    const portfolioProposalId = useMemo(() => portfolio?.PortfolioProposalId, [portfolio]);

    const isIndividual = useMemo(() => !!portfolioProposalId, [portfolioProposalId]);

    const productSettings = useMemo(() => productsList.find(f => f.id === productId)?.settings, [productsList, productId]);

    const { t } = useTranslation();
    const productExternalId = portfolio?.Product?.ExternalId;

    useEffect(() => productExternalId && global.sessionStorage.setItem(SELECETED_PRODUCT_TYPE, productExternalId || ''), [productExternalId]);

    const { setSetupWithdrawalPlan } = useCommonAllocationDataProvider();

    const {
        isZivZicAzp, isZiv, isZic, isAzp, isZivZifd, isZifd,
    } = useThreeBProductType({ product: { externalId: productExternalId } });

    const getIndividualData = useCallback(async () => {
        const portfolioProposal = await proposalManger.getProposal({ clientId, proposalId: portfolioProposalId });

        strategyData.individualPortfolio = portfolioProposal?.Positions?.map((position) => ({
            securityId: position?.Security?.Id,
            allocation: position?.Allocation,
        }));
    }, [portfolioProposalId, clientId, strategyData]);

    useEffect(() => {
        if (isIndividual && !strategyData?.individualPortfolio) {
            getIndividualData();
        }
    }, [getIndividualData, isIndividual, strategyData?.individualPortfolio]);

    const {
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy,
        selectedProductMarkId,
        setIndividualPortfolioData,
        onInvestmentHorizonChange,
        onProductMarkClick,
        strategies,
        strategy,
        setSelectedStrategyId,
        isIndividualPortfolioSelected,
        individualPortfolioData,
        isRebalance,
        setRebalance,
        selectedProduct,
        isNavigationDisabled,
        products,
        isModalListLoading,
        isAllocationsLoading,
        showChart,
        modelPortfolioDetails,
        setIndividualPortfolioValidationError,
        investmentHorizonError,
        setInvestmentHorizonError,
        productMarksOptions,
        allocations,
        factsheetUrl,
        checkTheRisk,
        productKey,
        preferenceStock,
        onPreferenceStockChange,
        preferenceExclusion,
        onPreferenceExclusionChange,
        preferenceInclusion,
        onPreferenceInclusionChange,
        exclusionSubstitute,
        onExclusionSubstituteChange,
        onSetupWithdrawalPlanChange,
        setupWithdrawalPlan,
        strategySelectedError,
        isZifdExclInterestIncome,
    } = useStrategySelection({
        productId,
        recommendedStrategyScore: cachedStrategy?.Score || portfolio?.RiskCategory?.Id,
        ...(!is3bProduct ? {
            additionalDisableCheck: (product) => (oldModelPortfolio?.Id === product?.Id || oldModelPortfolio?.Name === product?.Name),
            additionalCheckForProductList: (item) => (item.Id !== oldModelPortfolio?.Id && item?.Name !== oldModelPortfolio?.Name),
        } : {}),
        cachedInvestmentHorizon: strategyData?.investmentHorizon || cachedInvestmentHorizon,
        setPageErrors,
        productSettings,
        cachedModelPortfolioId: strategyData?.newModelPortfolio?.Id || (is3bProduct && portfolio?.ModelPortfolioId),
        ...(is3bProduct ? {
            cachedSetupWithdrawalPlan: strategyData?.withdrawalOption ?? initialPortfolioProductAttributes?.WithdrawalOption,
            cachedPreferenceStock: strategyData?.preferenceStock || initialPortfolioProductAttributes?.preferenceStock,
            cachedPreferenceExclusion: strategyData?.preferenceExclusion || initialPortfolioProductAttributes?.preferenceExclusion,
            cachedPreferenceInclusion: strategyData?.preferenceInclusion || initialPortfolioProductAttributes?.preferenceInclusion,
            cachedExclusionSubstitute: strategyData?.exclusionSubstitute || initialPortfolioProductAttributes?.exclusionSubstitute,
        } : {}),
        is3bProduct,
        isZiv,
        isZic,
        isAzp,
        isZifd,
    });

    useEffect(() => {
        const rawAttributes = products?.find((p) => portfolio?.ModelPortfolioId === p.Id)?.Attributes;

        setInitialPortfolioProductAttributes(
            JSON.parse(rawAttributes || '{}'),
        );
    }, [products, portfolio?.ModelPortfolioId]);

    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    const oldModelPortfolio = useMemo(
        () => products?.find(item => item.Id === parseInt(portfolio?.ModelPortfolioId, 10)),
        [products, parseInt(portfolio?.ModelPortfolioId, 10)],
    );

    // Client Provider

    // Handlers/Callbacks
    const handleOnContinue = useCallback(async () => {
        if (!selectedProductMarkId) {
            setProductMissingError(t('validation.mandatoryField'));

            return;
        }

        const strategyId = selectedStrategyId || recommendedStrategy?.id;

        const tempSetupWithdrawalPlan = isAzp || setupWithdrawalPlan;

        setStrategy({
            newModelPortfolio: selectedProduct,
            strategyId,
            strategyName: strategy?.Name,
            investmentHorizon: parseInt(investmentHorizon, 10),
            oldModelPortfolio,
            withdrawalOption: is3bProduct ? tempSetupWithdrawalPlan : undefined,
            preferenceStock,
            preferenceExclusion,
            exclusionSubstitute,
            preferenceInclusion,
            ...(isIndividualPortfolioSelected ? {
                individualPortfolioPositions: individualPortfolioData,
                individualPortfolio: individualPortfolioData.body.reduce((accu, item) => {
                    if (!item?.allocation?.disabled) {
                        accu.push({
                            securityId: item?.allocation?.id,
                            allocation: item?.allocation?.value / 100,
                        });
                    }

                    return accu;
                }, []),
                rebalancing: isRebalance,
            } : {
                individualPortfolio: undefined,
                individualPortfolioPositions: undefined,
            }),
        });

        if (is3bProduct) {
            setSetupWithdrawalPlan(tempSetupWithdrawalPlan);
        }

        navigate(tempSetupWithdrawalPlan ? `${baseUrl}/withdrawal-plan` : `${baseUrl}/change-overview`);
    }, [isRebalance,
        selectedProduct,
        individualPortfolioData,
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy?.Id,
        selectedProductMarkId,
        setupWithdrawalPlan,
        preferenceStock,
        preferenceExclusion,
        exclusionSubstitute,
        preferenceInclusion,
        is3bProduct,
        isZiv,
        isAzp,
        isIndividualPortfolioSelected]);

    const onContinue = useCallback(async () => {
        if (!investmentHorizon) {
            setInvestmentHorizonError(t('validation.mandatoryField'));
        }

        const isTheRiskChanged = checkTheRisk(handleOnContinue);

        if (isTheRiskChanged) {
            Modal.confirm(isTheRiskChanged);

            return;
        }

        await handleOnContinue();
    }, [
        checkTheRisk,
        investmentHorizon,
        isIndividualPortfolioSelected,
        handleOnContinue,
        recommendedStrategy?.Risk,
    ]);

    useEffect(() => {
        if (setProductMissingError) {
            setProductMissingError(null);
        }
    }, [selectedProductMarkId]);

    const onBack = useCallback(() => {
        navigate(-1);
    }, [baseUrl]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: (is3bProduct && productExternalId !== THREEBTYPES.zifd) ? 4 : 3,
        });
    }, [is3bProduct, productExternalId]);

    useEffect(() => {
        setLayoutData({
            onContinue,
            disabled: isNavigationDisabled,
        });
    }, [onContinue, isNavigationDisabled]);

    useEffect(() => {
        setLayoutData({
            onBack,
        });
    }, [onBack]);

    useEffect(() => {
        setLayoutData({ saveInProgress: false });
    }, []);

    return (
        <StrategySelection
            {...{
                investmentHorizon,
                investmentHorizonError,
                selectedProduct,
                recommendedStrategy,
                selectedStrategyId,
                setSelectedStrategyId,
                products,
                isIndividualPortfolioSelected,
                isModalListLoading,
                isAllocationsLoading,
                individualPortfolioData,
                isRebalance,
                setRebalance,
                modelPortfolioDetails,
                setIndividualPortfolioData,
                setIndividualPortfolioValidationError,
                selectedProductMarkId,
                productMarksOptions,
                showChart,
                allocations,
                factsheetUrl,
                onInvestmentHorizonChange,
                onProductMarkClick,
                strategies,
                productMissingError,
                cachedSelectStrategy: strategyData?.strategyId ? strategyData
                    : is3bProduct && { strategyId: portfolio?.RiskCategory?.Id },
                productKey,
                onSetupWithdrawalPlanChange,
                setupWithdrawalPlan,
                is3bProduct,
                preferenceStock,
                onPreferenceStockChange,
                preferenceExclusion,
                onPreferenceExclusionChange,
                preferenceInclusion,
                onPreferenceInclusionChange,
                exclusionSubstitute,
                onExclusionSubstituteChange,
                isZivZicAzp,
                isZiv,
                isAzp,
                isZivZifd,
                strategySelectedError,
                isZic,
                isZifdExclInterestIncome,
            }}
        />
    );
}

export default StrategySelectionPage;
