import { CHANGE_MODEL, INV_ALLOC_DATA_KEYS } from 'constants/constants';

export const CHANGE_STRATEGY = 'change-strategy';
export const MODIFY = 'modify';
export const QUICK_EDIT = 'quick-edit';
export const REBALANCE = 'rebalance';
export const NEW_PORTFOLIO = 'new-portfolio';

export const mapActionTypeName = (action, t) => {
    const actionsMap = {
        [CHANGE_STRATEGY]: t('overview.changeStrategy'),
        [CHANGE_MODEL]: t('overview.changeModel'),
        [QUICK_EDIT]: t('clientDashboard.portfolioEdit.quickEdit'),
        [MODIFY]: t('clientDashboard.portfolioEdit.title'),
        [REBALANCE]: t('clientDashboard.portfolio.rebalance'),
        [NEW_PORTFOLIO]: t('clientDashboard.portfolio.newPortfolio'),
    };

    return actionsMap[action] || null;
};

export const ALLOCATION_PRODUCT_DATA_KEYS = {
    REBALANCE,
    MODIFY,
    QUICK_EDIT,
    CHANGE_MODEL,
    NEW_PORTFOLIO,
    CHANGE_STRATEGY,
};

// TODO: find a way to avoid hardcode https://additiv.atlassian.net/browse/ZIAG-360?focusedCommentId=177545
export const riskCategoriesByLang = (t) => ([
    { id: 1, text: t('portfolio.createNewPortfolio.risk.lower') },
    { id: 2, text: t('portfolio.createNewPortfolio.risk.low') },
    { id: 3, text: t('portfolio.createNewPortfolio.risk.middle') },
    { id: 4, text: t('portfolio.createNewPortfolio.risk.high') },
    { id: 5, text: t('portfolio.createNewPortfolio.risk.higher') },
]);

export const ALLOCATION_TYPES = {
    NEW_ALLOCATION: 'NEW_ALLOCATION',
    CURRENT_ALLOCATION: 'CURRENT_ALLOCATION',
};

export const DEFAULT_CHART_VALUE = {
    chart: [{
        data: {
            pieSa: {}, bar: {}, list: {}, pie: {},
        },
    }],
};

export const DEFAULT_ALLOCATION_VALUE = {
    [INV_ALLOC_DATA_KEYS.withLookThroughtData]: {
        [ALLOCATION_TYPES.CURRENT_ALLOCATION]: DEFAULT_CHART_VALUE,
        [ALLOCATION_TYPES.NEW_ALLOCATION]: DEFAULT_CHART_VALUE,
    },
    [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]: {
        [ALLOCATION_TYPES.CURRENT_ALLOCATION]: DEFAULT_CHART_VALUE,
        [ALLOCATION_TYPES.NEW_ALLOCATION]: DEFAULT_CHART_VALUE,
    },
};
export { INV_ALLOC_DATA_KEYS };

export const MODE = {
    RESUME: 'resume',
    NORMAL: 'normal',
};

export const NO_EXCLUSION_PREFERENCE = 851;
