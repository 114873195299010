import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { newAdaptProjectionData } from 'adaptors/adaptProjection';
import use3bStepper from 'pages/ClientOverview/pages/Portfolios/hooks/use3bStepper';
import { useNavigate } from 'react-router-dom';
import { threeBProductGroup } from 'hooks/useProducts';
import { THREEBTYPES } from 'constants/constants';
import { calculatePlannedInvestment } from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/utils';
import {
    MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import {
    STATUS,
} from 'components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import { isThreeBProduct, useThreeBProductType } from 'hooks/isThreeBProduct';
import getInvestmentProjection from '../../GoalAndPortfolioSetup/services/getInvestmentProjection';
import { useFormatting } from '../../../../../../../../../locale';
import { Modal } from '../../../../../../../../../ui-library';
import { useCreatePortfolio } from '../../../context';
import handlerRequestCanceling from '../../../../../../../../../utils/handlerRequestCanceling';
import HandlerError from '../../../../../../../../../errors/HandlerError';
import { NEW_PORTFOLIO } from '../../../../../constants';

export const useOverview = ({ dfsClientId, selectedAppId }) => {
    const navigate = useNavigate();
    const { i18n: { language }, t } = useTranslation();
    const [goals, setGoals] = useState({});
    const [chartData, setChartData] = useState([]);
    const [returnRates, setReturnRates] = useState([]);
    const [chartDataInProgress, setChartDataInProgress] = useState(false);
    const [currency, setCurrency] = useState(undefined);
    const [chartOptions, setChartOptions] = useState([]);
    const [appsInTheContainer, setAppsInTheContainer] = useState([]);
    const [loadingAppsByContainer, setLoadingAppsByContainer] = useState(false);
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio-legacy`, [dfsClientId]);

    const { getFormattedNumber, getAmountWithDecimals } = useFormatting();
    const {
        newPortfolio, clearNewPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors }, setSelectedInvestmentApp, saveNewPortfolioToSessionStorage,
    } = useCreatePortfolio();

    const isThreeB = isThreeBProduct(newPortfolio);
    const { isPk } = useThreeBProductType(newPortfolio);
    const { overviewStepNumber } = use3bStepper();

    const pbValue = useMemo(() => (
        newPortfolio?.applicationData?.investmentDescription?.agentInformation?.pbNumber
    ), [newPortfolio?.applicationData?.investmentDescription?.agentInformation]);
    const productDesc = useMemo(() => (newPortfolio?.product?.description), [newPortfolio?.product?.description]);
    const productName = useMemo(() => (newPortfolio?.product?.name), [newPortfolio?.product?.name]);
    const showYearlyPayments = useMemo(() => (newPortfolio?.product?.settings?.showYearlyPayments), [newPortfolio?.product?.settings?.showYearlyPayments]);
    const strategy = useMemo(() => (newPortfolio?.strategy?.Name), [newPortfolio?.strategy?.Name]);
    const modelPortfolio = useMemo(() => (newPortfolio?.modelPortfolio?.Name), [newPortfolio?.modelPortfolio?.Name]);
    const individualPortfolio = useMemo(() => (newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.individualPortfolio), [newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.individualPortfolio]);
    const containerId = useMemo(() => newPortfolio?.applicationData?.investmentDescription?.containerId, [newPortfolio?.applicationData?.investmentDescription?.containerId]);
    const startedApplications = useMemo(() => {
        if (containerId) {
            return appsInTheContainer;
        }
        const cachedApps = JSON.parse(global.sessionStorage.getItem(NEW_PORTFOLIO) ?? 'null');

        if (cachedApps) {
            return Object.keys(cachedApps).map(key => {
                if (key !== 'undefined') {
                    return cachedApps[key];
                }

                return null;
            }).filter(f => !!f);
        }

        return [];
    }, [appsInTheContainer, containerId]);

    const transferAmount = useMemo(() => (
        currency && goals ? `${currency} ${getFormattedNumber(goals.InitialInvestmentAmount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}` : ''
    ), [currency, goals]);
    const yearlyAmount = useMemo(() => (
        currency && goals
            ? `${currency} ${getFormattedNumber(Math.round(goals.YearlyContributionAmount), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
            : ''
    ), [currency, goals]);

    const getInvestmentAppsByContainerId = useCallback(async () => {
        try {
            if (!containerId) {
                return;
            }

            setLoadingAppsByContainer(true);

            const response = await ServiceManager.customInvestmentService('getInvestmentAppsByContainerId', [{ containerId, language }]);

            if (response?.data) {
                response.data?.forEach(app => {
                    if (newPortfolio?.applicationData?.investmentApplicationId !== app.investmentApplicationId) {
                        saveNewPortfolioToSessionStorage({ applicationData: { ...app, investmentDescription: { containerId: app.containerId } }, product: { id: app.productId } });
                    }
                });
                setAppsInTheContainer(response?.data);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingAppsByContainer(false);
        }
    }, [containerId, language]);

    useEffect(() => {
        (async () => getInvestmentAppsByContainerId())();
    }, [getInvestmentAppsByContainerId]);

    const onAddNewProduct = useCallback(async () => {
        if (!newPortfolio?.applicationData?.investmentDescription?.containerId) {
            const payload = {
                data: {
                    investmentApplicationId: newPortfolio.applicationData.investmentApplicationId,
                },
                method: 'generateContainerId',
            };

            await saveNewPortfolio(payload);
        }

        const newDefaultAppProps = {
            agentInformation: {
                pbSelected: newPortfolio?.applicationData?.investmentDescription?.agentInformation?.pbNumber,
                selectedGeneralAgency: newPortfolio?.applicationData?.investmentDescription?.agentInformation?.agencyId,
                vstNumber: newPortfolio?.applicationData?.investmentDescription?.agentInformation?.vstNumber,
            },
            applicationData: {
                investmentApplicationId: undefined,
            },
            connectionGroupId: newPortfolio.connectionGroupId,
            isJointAccount: newPortfolio.isJointAccount,
            isAdvisoryCompleted: !!newPortfolio?.applicationData?.investmentDescription?.advisoryDocumentId,
            advisoryDocument: {
                advisoryDocumentId: newPortfolio.advisoryDocument?.advisoryDocumentId ?? newPortfolio?.applicationData?.investmentDescription?.advisoryDocumentId,
            },
            product: threeBProductGroup,
            userClientAge: newPortfolio.userClientAge,
            userClientName: newPortfolio.userClientName,
        };

        saveNewPortfolioToSessionStorage(newDefaultAppProps);
        setSelectedInvestmentApp(undefined);
        navigate(`${baseUrl}/product-selection`);
    }, [newPortfolio, navigate, baseUrl, saveNewPortfolio]);

    const onEdit = useCallback(async (investmentApplicationId) => {
        try {
            if (investmentApplicationId !== newPortfolio?.applicationData?.investmentApplicationId) {
                const { data: investmentAppDetails } = await ServiceManager.customInvestmentService('getInvestmentApplication', [{ investmentApplicationId }]);

                saveNewPortfolioToSessionStorage({
                    applicationData: investmentAppDetails,
                    connectionGroupId: investmentAppDetails?.investmentDescription?.contactGroupId,
                    isJointAccount: investmentAppDetails?.investmentDescription?.isJointAccount,
                    product: {
                        id: investmentAppDetails?.investmentDescription?.productId,
                    },
                });
                setSelectedInvestmentApp(investmentApplicationId);
            }
            navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/personal-details`);
        } catch (err) {
            console.error(err);
        }
    }, [dfsClientId, setSelectedInvestmentApp, newPortfolio]);

    const onAppDeleteSuccess = useCallback((hasMoreApps) => {
        if (!hasMoreApps) {
            navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy`);
        }
    }, [navigate]);

    const onDelete = useCallback((appId) => {
        Modal.confirm({
            title: t('portfolio.createNewPortfolio.deleteApplication.confirmationModal.title'),
            content: t('portfolio.createNewPortfolio.deleteApplication.confirmationModal.message'),
            okText: t('portfolio.createNewPortfolio.deleteApplication.confirmationModal.deleteBtnLabel'),
            onOk: async () => {
                try {
                    await ServiceManager.customInvestmentService(
                        'cancelInvestmentApplications',
                        [{ investmentApplicationId: appId }],
                    );
                    clearNewPortfolio(appId, onAppDeleteSuccess);
                    getInvestmentAppsByContainerId();
                } catch (err) {
                    handlerRequestCanceling(
                        HandlerError({
                            setError: setPageErrors,
                        }),
                    )(err);
                }
            },
            cancelText: t('clientDashboard.cancel'),
        });
    }, [dfsClientId, onAppDeleteSuccess, getInvestmentAppsByContainerId]);

    const onContinue = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/${isThreeB ? 'kyc' : 'quality-check'}`);
    }, [dfsClientId, isThreeB]);
    const onBack = useCallback(() => {
        let url = 'agent-information';

        if (isThreeB) {
            url = 'fees-overview';
        }
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/${url}`);
    }, [dfsClientId, isThreeB]);

    const getPlannedInvestment = useCallback((app) => {
        if (app.plannedInvestmentAmount) {
            return app.plannedInvestmentAmount;
        } if ([THREEBTYPES.zic, THREEBTYPES.azpAlt, THREEBTYPES.azpNeu, THREEBTYPES.azpNeuSB].includes(app.applicationData?.investmentDescription?.productExternalId)) {
            return app.applicationData?.investmentDescription?.productConfiguration?.initialInvestment;
        }

        return calculatePlannedInvestment(
            app.applicationData?.investmentDescription?.productConfiguration?.initialInvestment,
            app.applicationData?.investmentDescription?.productConfiguration?.periodicalPaymentAmount,
            app.applicationData?.investmentDescription?.productConfiguration?.periodicity,
        );
    }, []);

    const getPlannedInvestmentFormatted = useCallback((app) => {
        const amount = getPlannedInvestment(app);

        return getAmountWithDecimals(Number.isNaN(amount) ? 0 : amount);
    }, [getPlannedInvestment]);

    useEffect(() => {
        if (newPortfolio?.goals) {
            setGoals(newPortfolio.goals);
            setChartOptions(newPortfolio.chartOptions);
            setCurrency(newPortfolio.currency.CurrencyCode);
        }
    }, [newPortfolio]);

    useEffect(() => {
        (async () => {
            try {
                if (!selectedAppId) {
                    return;
                }

                setChartDataInProgress(true);

                const { data: investmentAppDetails } = await ServiceManager.customInvestmentService('getInvestmentApplication', [{ investmentApplicationId: selectedAppId }]);

                if (!investmentAppDetails?.investmentDescription?.productConfiguration) {
                    setChartData([]);

                    return;
                }

                const performance = {
                    ...investmentAppDetails?.investmentDescription?.productConfiguration,
                    modelPortfolioId: investmentAppDetails.investmentDescription?.selectStrategy?.modelPortfolioId,
                    language,
                    individualPortfolio: investmentAppDetails?.investmentDescription?.selectStrategy?.individualPortfolio,
                    investmentApplicationId: investmentAppDetails?.investmentApplicationId,
                };

                getInvestmentProjection(performance).then((response) => {
                    const chartGraphData = response.data?.projections;

                    const AdditionalValueArray1 = chartGraphData[1].values.map((k, i) => ({
                        ...k, AdditionalValue: chartGraphData[0].values[i].value, value: k.value, date: k.date,
                    }));
                    const AdditionalValueArray2 = chartGraphData[2].values.map((k, i) => ({
                        ...k, AdditionalValue: chartGraphData[1].values[i].value, value: k.value, date: k.date,
                    }));

                    const chart = newAdaptProjectionData([
                        {
                            Values: [...AdditionalValueArray1],
                            ProbabilityPercentage: chartGraphData[1].stdDevFactor,
                            SeriesType: 'InstrumentSet',
                        },
                        {
                            Values: [...AdditionalValueArray1],
                            ProbabilityPercentage: chartGraphData[1].stdDevFactor,
                            SeriesType: 'InstrumentSet',
                        },
                        {
                            Values: [...AdditionalValueArray2],
                            ProbabilityPercentage: chartGraphData[2].stdDevFactor,
                            SeriesType: 'InstrumentSet',
                        },
                    ]);
                    const { bestReturn, averageReturn, worstReturn } = response?.data || {};

                    setReturnRates([
                        { name: t('projectionLegend.bestCase'), value: `${bestReturn > 0 ? '+' : '-'} ${Math.abs(bestReturn * 100)?.toFixed(2)}%` },
                        { name: t('projectionLegend.averageCase'), value: `${averageReturn > 0 ? '+' : '-'} ${Math.abs(averageReturn * 100)?.toFixed(2)}%` },
                        { name: t('projectionLegend.worstCase'), value: `${worstReturn > 0 ? '+' : '-'} ${Math.abs(worstReturn * 100)?.toFixed(2)}%` },
                    ]);

                    setChartData(chart);
                });
            } catch (err) {
                console.log(err);
            } finally {
                setChartDataInProgress(false);
            }
        })();
        // }
    }, [goals, language, individualPortfolio, selectedAppId]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: isThreeB ? overviewStepNumber : 7,
            onBack,
            onContinue,
            disabled: false,
            inProgress: false,
        });
    }, [onBack, onContinue, overviewStepNumber]);

    const isProofOfAssetsRequired = useMemo(() => {
        const totalPlannedInvestmentForAllApps = startedApplications?.filter(f => f.status !== STATUS.canceled).reduce((acc, item) => acc + getPlannedInvestment(item), 0);

        return totalPlannedInvestmentForAllApps > MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS;
    }, [startedApplications, getPlannedInvestment]);

    return {
        t,
        pbValue,
        productDesc,
        productName,
        showYearlyPayments,
        strategy,
        modelPortfolio,
        transferAmount,
        yearlyAmount,
        projectionOptions: chartOptions,
        chartData,
        returnRates,
        chartDataInProgress,
        currency,
        onEdit,
        onDelete,
        onBack,
        onContinue,
        startedApplications,
        onAddNewProduct,
        loadingAppsByContainer,
        getPlannedInvestment: getPlannedInvestmentFormatted,
        isProofOfAssetsRequired,
    };
};
