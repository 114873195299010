import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';

import './InvestmentApplicationNavigationButton.css';
import { Icon } from 'ui-library';
import { ICON_LONG_ARROW_RIGHT } from 'ui-library/components/Icon';
import { genarateDFSClientId } from '../../utils';
import { StepperManager } from '../../core/stepper/StepperManager';
import { CreatePortfolioManager } from '../../datasource/CreatePortfolio';
import { PortfolioProcessLayoutManager } from '../../datasource/PortfolioProcessLayout';
import {
    APPLICATION_CONTAINER_ID, PRODUCT_EXTERNAL_ID, QUALITY_CHECK_DATA,
} from '../../constants/constants';

type InvestmentApplicationNavigationButtonProps = {
    destinationId: number
    destination: string
    inProgress?: boolean
    applicationId?: number
    clientId?: number | null
}

const InvestmentApplicationNavigationButton = ({
    inProgress = false,
    destinationId,
    destination,
    clientId = null,
    applicationId = undefined,
}: InvestmentApplicationNavigationButtonProps) => {
    const navigate = useNavigate();
    const { dfsClientId } = useParams();

    const urlClientId = useMemo(() => (dfsClientId || genarateDFSClientId(clientId)), [dfsClientId, clientId]);

    const onNavigationClick = useCallback(() => {
        if (inProgress) {
            sessionStorage.removeItem(StepperManager.LOCAL_STORAGE_KEY);
            sessionStorage.removeItem(CreatePortfolioManager.LOCAL_STORAGE_KEY);
            sessionStorage.removeItem(PortfolioProcessLayoutManager.LOCAL_STORAGE_KEY);
            sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
            sessionStorage.removeItem(QUALITY_CHECK_DATA);
            sessionStorage.removeItem(PRODUCT_EXTERNAL_ID);
            navigate(`/client/${urlClientId}/portfolios/new-portfolio/`, {
                state: {
                    investmentApplicationId: applicationId,
                },
            });
        } else {
            navigate(`/client/${urlClientId}/${destination}/${destinationId}`);
        }
    }, [navigate, destinationId, destination, urlClientId, applicationId]);

    return <Icon type={ICON_LONG_ARROW_RIGHT} className="navigationButtonArrow" onClick={onNavigationClick}/>;
};

export default InvestmentApplicationNavigationButton;
