/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { ICON_EDIT_SLIM } from 'ui-library/components/Icon';
import { Icon } from 'ui-library';
import { OtherAssetsFormData } from 'datasource/useOtherAssets/useOtherAssets.types';
import IconWithTooltip from 'components/common/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';

export const useGetAssetsTableColumns = () => {
    const { t } = useTranslation();
    const { getAmountWithDecimals } = useFormatting();
    const getColumns = ({
        onEditAssetRow = () => {},
        instituteOptions,
        strategyOptions = [],
    }: {
        onEditAssetRow?: (record?: OtherAssetsFormData) => void,
        categoryOptions?: any[],
        instituteOptions?: any[],
        strategyOptions?: any[]
    }) => [
        {
            key: 'instituteListItemId',
            title: t('otherAssets.table.header.institute'),
            sortable: true,
            width: 148,
            render: (_, record: OtherAssetsFormData) => (
                <>
                    {!instituteOptions ? <strong>{t('otherAssets.label.total')}</strong> : (
                        <>
                            {instituteOptions?.find(ins => ins.value === record?.instituteListItemId)?.label}
                            {record?.description && (
                                <IconWithTooltip description={record?.description} />
                            )}
                        </>
                    )}
                </>
            ),
        },
        {
            key: 'strategyListItemId',
            title: t('otherAssets.table.header.strategy'),
            // className: 'text-right',
            // hidden: true,
            width: 132,
            render: (_, record: OtherAssetsFormData) => (
                <>
                    {strategyOptions?.find(strategy => strategy.value === record?.strategyListItemId)?.label}
                </>
            ),
        },
        {
            key: 'value',
            title: t('otherAssets.table.header.value'),
            // className: 'text-right',
            width: 132,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right nowrap">
                    {getAmountWithDecimals(record?.value)}
                </div>
            ),
        },
        {
            key: 'stocks',
            title: t('otherAssets.table.header.stocks'),
            // className: 'text-right',
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.stocks || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'liquidity',
            title: t('otherAssets.table.header.cashAndLiquid'),
            // className: 'text-right',
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.liquidity || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'bonds',
            title: t('otherAssets.table.header.bonds'),
            // className: 'text-right',
            // hidden: true,
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.bonds || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'realEstate',
            title: t('otherAssets.table.header.realEstate'),
            // className: 'text-right',
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.realEstate || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'rawMaterial',
            title: t('otherAssets.table.header.rawMaterials'),
            // className: 'text-right',
            // hidden: true,
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.rawMaterial || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'tiedAssets',
            title: t('otherAssets.table.header.tiedAssets'),
            // className: 'text-right',
            // hidden: true,
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.tiedAssets || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'other',
            title: t('otherAssets.table.header.others'),
            // className: 'text-right',
            // hidden: true,
            width: 80,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${(+(record?.allocations?.other || 0))?.toFixed(1)}%`}
                </div>
            ),
        },
        {
            key: 'Icon',
            title: '',
            titleType: 'columns',
            width: 40,
            render: (_, record) => (
                <button className="edit-asset-row-button" type="button" onClick={() => onEditAssetRow(record)} aria-label="button">
                    {/* @ts-ignore */}
                    <Icon type={ICON_EDIT_SLIM} size={20} />
                </button>
            ),
            className: 'text-right',
            dragDisabled: true,
        },
    ];

    return { getColumns };
};
