import {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector as useReduxSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import {
    array, object, ObjectSchema, string,
} from 'yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from 'datasource/CreatePortfolio';
import { useGetCustomService } from 'hooks/rest/useGetCustomService';
import { APPLICATION_CONTAINER_ID, PRODUCTS, THREEBTYPES } from 'constants/constants';
import { useProductsList } from 'hooks/useProducts';
import { notification } from 'ui-library';
import { useGenericAPIMutation } from 'hooks/mutations/useGenericAPIMutation';
import {
    MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import { memberIdSelector } from '../redux-store/auth/authSelectors';
import useCommonAllocationDataProvider
    from '../pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';

const useShoppingCart = () => {
    const { t, i18n: { language } } = useTranslation();
    const { setSetupWithdrawalPlan } = useCommonAllocationDataProvider();

    const [existing3bPortfolioData, setExisting3bPortfolioData] = useState({
        isBuyTransaction: true,
        transactionAmount: 2500,
        error: null,
    });
    const [cancelInProgress, setCancelInProgress] = useState<boolean>(false);
    const [continueDisabled, setContinueDisabled] = useState(true);

    const {
        selectedProductIdSelector: selectedProductId,
        selectedProductSelector,
        contactGroupIdSelector,
        isJointAccountSelector,
        currentClientSelector,
        is3bProductGroupSelector,
        isAdvisoryProcessSelector,
        advisoryUploadedDocumentSelector,
        selectedProductGroupSelector,
        advisoryIdSelector, advisoryDataSelector,
        currentInvestmentDescriptionSelector,
        currentSelectedStrategySelector,
        advisoryDataLoading,
        advisoryProcessInProgressSelector,
    } = useCreatePortfolioSelectors();
    const {
        saveInvestmentApplicationDataAction, saveCurrentInvestmentApplicationIdAction,
        startProductSetupAction, clearProductSetupAction, removeInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();
    const { investorProfileClientId } = useCreatePortfolioState();

    const productSettings = useMemo(() => (selectedProductSelector?.settings), [selectedProductSelector?.settings]);
    const affiliatedToPensionFund = useMemo(() => (currentInvestmentDescriptionSelector?.personalDetails?.affiliatedToPensionFund), [currentInvestmentDescriptionSelector?.personalDetails?.affiliatedToPensionFund]);
    const advisoryDocumentId = useMemo(() => (advisoryUploadedDocumentSelector?.advisoryDocumentId ?? advisoryIdSelector), [advisoryIdSelector, advisoryUploadedDocumentSelector?.advisoryDocumentId]);
    const defaultYearlyPayment = useMemo(() => (affiliatedToPensionFund ? productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue : productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse), [affiliatedToPensionFund, productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue, productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse]);

    const memberId = useReduxSelector(memberIdSelector);
    const containerId = useRef(global.sessionStorage.getItem(APPLICATION_CONTAINER_ID))?.current;

    const isZurichEmployee = useMemo(() => currentClientSelector?.isZurichEmployee, [currentClientSelector?.isZurichEmployee]);
    const {
        data: existingPortfolios, isLoading: existingPortfoliosLoading, error: existingPortfoliosError, refetch: refetchExistingPlans,
    } = useGetCustomService({
        service: 'customAdvisoryServices', api: 'getExistingPortfolios', payload: { advisoryId: advisoryIdSelector || advisoryDocumentId, lang: language }, enabled: false,
    });

    const {
        data: newInvestmentPlansData, isLoading: newInvestmentPlansLoading, refetch: refetchNewPlans,
    } = useGetCustomService({
        service: 'customInvestmentService', api: 'getInvestmentAppsByContainerId', payload: { containerId, language }, enabled: false,
    });

    useEffect(() => {
        if (advisoryIdSelector || advisoryDocumentId) {
            refetchExistingPlans();
        }
    }, [advisoryIdSelector, advisoryDocumentId]);

    const { mutateAsync: handleChangeExisting3bPlan, isPending: changeExisting3bPlanResponseIsPending } = useGenericAPIMutation();

    const adaptNewInvestmentPlanFromAPI = useCallback((data) => data?.filter(dta => dta?.status !== 2 && !dta?.changePlan)?.map(plan => ({
        id: plan?.productId,
        product: plan.productExternalId,
        productName: plan.productName,
        amount: plan?.plannedInvestmentAmount,
        newStrategy: plan?.strategy || plan?.productName,
        containerId: plan?.containerId,
        investmentApplicationId: plan?.investmentApplicationId,
        isConfigured: !!plan?.investmentDescription?.agentInformation,
        yearlyPayments: plan?.investmentDescription?.productConfiguration?.yearlyPayments,
        transactionAmount: plan?.investmentDescription?.productConfiguration?.transactionAmount || 0,
        modelPortfolioId: plan?.investmentDescription?.selectStrategy?.modelPortfolioId,
        strategyId: plan?.investmentDescription?.selectStrategy?.strategyId,

    })), []);

    const newInvestmentPlanFromAPI = useMemo(() => adaptNewInvestmentPlanFromAPI(newInvestmentPlansData?.data) || [], [newInvestmentPlansData?.data, adaptNewInvestmentPlanFromAPI]);

    const existing3bPortfoliosData = useMemo(() => (existingPortfolios?.data?.portfolioWithApplicationList)?.map(portfolio => ({
        id: portfolio?.id,
        existingProduct: {
            name: portfolio?.product?.name,
            id: portfolio?.product?.id,
            externalId: portfolio?.product?.externalId,
        },
        amount: portfolio?.currentValue,
        newStrategy: [THREEBTYPES.pk, THREEBTYPES.zic]?.includes(portfolio?.product?.externalId) ? '-' : portfolio?.newStrategyName,
        isBuyTransaction: !portfolio?.changeAmountInvestmentApplication?.investmentDescription?.changeInvestmentAmount?.isSell,
        oldStrategy: portfolio?.oldStrategyName,
        changedStrategy: portfolio?.changeStrategyInvestmentApplication,
        investmentApplication: portfolio?.changeStrategyInvestmentApplication || portfolio?.parentInvestmentApplication,
        changedBuySellAmount: portfolio?.changeAmountInvestmentApplication,
        changedAmount: portfolio?.changeAmountInvestmentApplication?.investmentDescription?.changeInvestmentAmount?.isSell ? -(portfolio?.changeAmountInvestmentApplication?.investmentDescription?.changeInvestmentAmount?.value) : portfolio?.changeAmountInvestmentApplication?.investmentDescription?.changeInvestmentAmount?.value,
        pendingCEPRequest: portfolio?.incomplete,
        modelPortfolioId: portfolio?.modelPortfolioId,
        editable: (![THREEBTYPES.pk, THREEBTYPES.azpAlt, THREEBTYPES.azpNeu, THREEBTYPES.azpNeuSB]?.includes(portfolio?.product?.externalId)) || false,
        edited: !!portfolio?.changeAmountInvestmentApplication || !!portfolio?.changeStrategyInvestmentApplication || false,
        withdrawalAmount: portfolio?.withdrawalAmount,
        withdrawalOption: portfolio?.changeStrategyInvestmentApplication ? portfolio?.changeStrategyInvestmentApplication?.investmentDescription?.withdrawal : portfolio?.parentInvestmentApplication?.investmentDescription?.withdrawal,
        paymentInstruction: portfolio?.changeStrategyInvestmentApplication ? portfolio?.changeStrategyInvestmentApplication?.investmentDescription?.paymentInstruction : portfolio?.parentInvestmentApplication?.investmentDescription?.paymentInstruction,
    })), [existingPortfolios?.data?.portfolioWithApplicationList]);

    const schema: ObjectSchema = useMemo(() => object().shape({
        products: array().of(object().shape({
            product: string().nullable(),
            amount: string().nullable(),
            strategy: string().nullable(),
        })),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: { errors }, watch, reset,
    } = useForm({
        resolver,
        defaultValues: {
            products: newInvestmentPlanFromAPI || [],
        },
    });

    const {
        fields,
        append,
        update,
        remove,
    } = useFieldArray({
        control,
        name: 'products',
    });

    const formData = watch();

    useEffect(() => {
        if (!!containerId && containerId !== 'null' && !!language) {
            refetchNewPlans();
        }
    }, [containerId, adaptNewInvestmentPlanFromAPI, language]);

    useEffect(() => {
        reset({ products: newInvestmentPlanFromAPI || [] });
    }, [newInvestmentPlanFromAPI]);

    const hasPKApplication = useMemo(() => (existing3bPortfoliosData?.find(data => data?.existingProduct?.externalId === THREEBTYPES.pk)?.existingProduct?.externalId === 'PK')
     || (formData?.products?.find(data => data?.product === THREEBTYPES.pk)?.product === 'PK'), [existing3bPortfoliosData, formData?.products]);

    const { getProducts, products } = useProductsList(currentClientSelector?.id);

    useEffect(() => {
        clearProductSetupAction();
        setSetupWithdrawalPlan(false);
    }, []);

    useEffect(() => {
        getProducts();
    }, [language]);

    const desiredInvestmentAmount = advisoryDataSelector?.metadata?.desiredInvestmentAmount || advisoryUploadedDocumentSelector?.metadata?.desiredInvestmentAmount || 0;
    const totalInvestmentAmountNewProducts = useMemo(() => (formData?.products?.length > 0 ? formData?.products?.reduce((acc, curr) => acc + (+curr.amount || 0), 0) : 0), [formData]);
    const totalInvestmentAmountExistingProducts = useMemo(() => existing3bPortfoliosData?.length > 0 && existing3bPortfoliosData?.reduce((acc, curr) => acc + (+curr?.changedAmount || 0), 0), [existing3bPortfoliosData]);
    const totalInvestmentAmount = useMemo(() => totalInvestmentAmountNewProducts + totalInvestmentAmountExistingProducts, [totalInvestmentAmountNewProducts, totalInvestmentAmountExistingProducts]);

    const totalBuyAmount = useMemo(() => existing3bPortfoliosData?.filter((portfolio) => portfolio?.isBuyTransaction)?.reduce((acc, curr) => acc + (+curr?.changedAmount || 0), 0), [existing3bPortfoliosData]);
    const remainingInvestmentAmount = useMemo(() => (desiredInvestmentAmount - totalInvestmentAmount), [desiredInvestmentAmount, totalInvestmentAmount]);
    const isProofOfAssetsNeeded = useMemo(() => is3bProductGroupSelector && ((totalInvestmentAmountNewProducts + (totalBuyAmount || 0)) >= MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS), [totalInvestmentAmountNewProducts, totalBuyAmount, is3bProductGroupSelector]);
    const productOptions3B = useMemo(() => products.filter(f => f.settings.productKey === PRODUCTS.bbb)?.map(product => {
        const productToSave = { ...product };

        if ((hasPKApplication && productToSave.externalId === THREEBTYPES.pk)
            || (!isAdvisoryProcessSelector
                && (
                    productToSave.externalId === THREEBTYPES.azpNeu
                    || productToSave.externalId === THREEBTYPES.azpNeuSB
                    || productToSave.externalId === THREEBTYPES.zivv
                ))
        ) {
            productToSave.isEnabled = false;
        }

        // const minPeriodicPaymentFor3B = isZurichEmployee ? (productToSave?.settings?.minPeriodicPaymentZurich ?? productToSave?.settings?.minPeriodicPayment) : productToSave?.settings?.minPeriodicPayment;
        const minOneTimeInvestmentFor3B = isZurichEmployee ? (productToSave?.settings?.minOneTimeInvestmentZurich ?? productToSave?.settings?.minOneTimeInvestment) : productToSave?.settings?.minOneTimeInvestment;

        return {
            label: productToSave?.name,
            value: productToSave?.id,
            externalId: productToSave?.externalId,
            // minimumInvestment: productToSave?.settings?.isOneTimeInvestmentThresholdMetWithRegularPaymentPlan ? minPeriodicPaymentFor3B : minOneTimeInvestmentFor3B,
            minimumInvestment: minOneTimeInvestmentFor3B,
            disabled: !productToSave?.isEnabled || (productToSave.externalId !== THREEBTYPES.pk && isAdvisoryProcessSelector && remainingInvestmentAmount <= 0),
        };
    }), [products, hasPKApplication, isAdvisoryProcessSelector, remainingInvestmentAmount]);

    const handleAddProduct = useCallback(() => {
        append({
            product: is3bProductGroupSelector ? null : selectedProductSelector?.externalId, amount: is3bProductGroupSelector ? null : defaultYearlyPayment,
        });
    }, [is3bProductGroupSelector, defaultYearlyPayment, selectedProductSelector?.externalId]);

    const cancelInvestmentApplication = useCallback(async (index, applicationId) => {
        try {
            setCancelInProgress(applicationId);
            const response:any = await handleChangeExisting3bPlan({
                service: 'customInvestmentService',
                API: 'deleteInvestmentApplication',
                payload: { investmentApplicationId: applicationId },
            });

            if (response?.status === 200) {
                remove(index);
                removeInvestmentApplicationDataAction({
                    id: applicationId,
                });
                notification.open({ content: t('dashboard.overviewClientApplications.canceledSuccessfully'), type: 'success' });
            }
        } catch (err) {
            notification.open({ content: t('dashboard.overviewClientApplications.cancelError'), type: 'error' });
        } finally {
            setCancelInProgress(false);
        }
    }, [t]);

    const handleDeleteRow = useCallback(({ index, mode, applicationId }) => {
        if (mode === 'editMode') {
            remove(index);
        } else {
            cancelInvestmentApplication(index, applicationId);
        }
    }, []);

    const createNewInvestmentPlan = useCallback(async (data, index) => {
        const newInvestmentPlanData = data?.products?.[index];
        const productId = !is3bProductGroupSelector ? selectedProductSelector?.id : +newInvestmentPlanData?.product;
        const externalId = !is3bProductGroupSelector ? selectedProductSelector?.externalId : productOptions3B?.find(product => product?.value === +newInvestmentPlanData?.product)?.externalId;
        const cId = global.sessionStorage.getItem(APPLICATION_CONTAINER_ID);

        const uuid = uuidv4();

        if (!cId || (cId === 'null')) {
            global.sessionStorage.setItem(APPLICATION_CONTAINER_ID, uuid);
        }

        try {
            const payload = {
                contactId: currentClientSelector?.id,
                memberId,
                contactGroupId: contactGroupIdSelector,
                isJointAccount: isJointAccountSelector,
                productId,
                productExternalId: externalId,
                advisoryDocumentId,
                plannedInvestment: +newInvestmentPlanData?.amount,
                containerId: cId && cId !== 'null' ? cId : uuid,
                ...isJointAccountSelector ? {
                    investorProfileContactId: investorProfileClientId,
                } : {},
            };

            const additionalData = {
                product: selectedProductSelector,
            };

            const result = await saveInvestmentApplicationDataAction({
                payload,
                method: 'saveProductDetails',
                additionalData,
            });

            if (result?.data?.investmentDescription?.containerId) {
                const plan = result?.data?.investmentDescription;
                const newData = {
                    id: plan?.productId,
                    product: plan.productExternalId,
                    productName: products?.find(product => product?.id === plan?.productId)?.name,
                    amount: plan?.productConfiguration?.initialInvestment,
                    newStrategy: plan?.strategy || plan?.productName,
                    containerId: plan?.containerId,
                    investmentApplicationId: result?.data?.investmentApplicationId,
                    isConfigured: !!plan?.investmentDescription?.agentInformation,
                    yearlyPayments: plan?.investmentDescription?.productConfiguration?.yearlyPayments,
                    transactionAmount: plan?.investmentDescription?.productConfiguration?.transactionAmount || 0,
                    modelPortfolioId: plan?.investmentDescription?.selectStrategy?.modelPortfolioId,
                    strategyId: plan?.investmentDescription?.selectStrategy?.strategyId,
                };

                update(index, newData);
            }
        } catch (e) {
            console.error(e);
        }
    }, [
        selectedProductSelector,
        contactGroupIdSelector,
        isJointAccountSelector,
        currentClientSelector?.id,
        memberId, selectedProductId,
        selectedProductGroupSelector,
        productOptions3B,
        is3bProductGroupSelector,
        advisoryDocumentId,
        investorProfileClientId,
    ]);

    const saveNewProduct = async (data, index) => {
        await createNewInvestmentPlan(data, index);
    };

    const handleConfig = useCallback((id: number) => {
        if (id) {
            startProductSetupAction(id);

            stepComplete();
        }
    }, [startProductSetupAction]);

    useEffect(() => {
        const hasChangedExistingPlan = existingPortfolios?.data?.portfolioWithApplicationList?.some(plan => plan?.changeStrategyInvestmentApplication || plan?.changeAmountInvestmentApplication);
        const hasNoProducts = formData?.products?.length < 1 && !hasChangedExistingPlan;
        const hasNegativeInvestmentAmountWithAdvisory = !!advisoryIdSelector && remainingInvestmentAmount < 0;
        const hasNonConfiguredPlan = formData?.products?.length && formData?.products?.find(plan => !plan?.isConfigured);

        setContinueDisabled(hasNoProducts || hasNegativeInvestmentAmountWithAdvisory || !!hasNonConfiguredPlan);
    }, [formData?.products, remainingInvestmentAmount, existingPortfolios?.data?.portfolioWithApplicationList]);

    const { stepComplete } = usePortfolioProcessNavigation({
        enableAutoStepComplete: true,
        backDisabled: !advisoryProcessInProgressSelector,
        continueDisabled,
    });

    const changeExisting3bPlan = useCallback(async ({ requestType, API, payload }) => {
        const additionalPayload = requestType === 'post' ? { advisoryDocumentId } : {};

        await handleChangeExisting3bPlan({
            service: 'customInvestmentService',
            API,
            payload: { ...payload, ...additionalPayload },
        });
    }, []);

    return {
        formData,
        existingPortfolios: {
            data: existing3bPortfoliosData,
            error: existingPortfoliosError,
            loading: existingPortfoliosLoading || false,
        },
        advisoryId: advisoryIdSelector,
        desiredInvestmentAmount,
        refetchExistingPlans,
        newProductsLoading: newInvestmentPlansLoading,
        existing3bPortfolioData,
        setExisting3bPortfolioData,
        totalInvestmentAmount,
        remainingInvestmentAmount,
        fields,
        control,
        errors,
        handleDeleteRow,
        handleAddProduct,
        saveNewProduct: handleSubmit(saveNewProduct),
        setContinueDisabled,
        productOptions3B,
        cancelInProgress,
        handleConfig,
        is3bProductGroupSelector,
        selectedProductSelector,
        isAdvisoryProcessSelector,
        currentClientSelector,
        advisoryUploadedDocumentSelector,
        saveCurrentInvestmentApplicationIdAction,
        continueDisabled,
        changeExisting3bPlan,
        changeExisting3bPlanResponseIsPending,
        selectedStrategyId: currentSelectedStrategySelector?.Id,
        advisoryDocumentId,
        advisoryDataLoading,
        isProofOfAssetsNeeded,
        hasPKApplication,
        isZurichEmployee,
    };
};

export default useShoppingCart;
