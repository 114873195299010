/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Button, Icon, Modal, NumberInput, Select,
} from 'ui-library';
import { ICON_EDIT_MINIMAL, ICON_TYPE_BIN, ICON_TYPE_CHART_ARROW } from 'ui-library/components/Icon';
import { PRODUCT_ID_FOR_PK, THREEBTYPES } from 'constants/constants';
import { useFormatting } from 'locale';
import { StaticField } from '../../../ui-library/components/StaticField/StaticField';
import ProjectionChartComp from './ProjectionChartComp';
import { ProductRowProps } from './ShoppingCartProductRow.types';
import './ShoppingCartProductRow.css';

const NewProductRow: FC<ProductRowProps> = (props) => {
    const { t, i18n: { language } } = useTranslation();
    const { getAmountWithDecimals } = useFormatting();

    const {
        formData,
        configured,
        product,
        index,
        control,
        errors,
        productOptions,
        handleDeleteRow = () => {
        },
        saveNewProduct = () => {
        },
        handleConfig = () => {
        },
        cancelInProgress,
        is3bProductGroupSelector,
        saveCurrentInvestmentApplicationIdAction,
        continueDisabled,
    } = props;

    const [showChartModal, setShowChartModal] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const saveButtonDisabled = useMemo(() => {
        if (is3bProductGroupSelector) {
            if (formData?.product === 20) {
                return !formData?.product;
            }

            return !formData?.amount || !formData?.product;
        }

        return !formData?.amount;
    }, [formData?.amount, formData?.product, is3bProductGroupSelector]);

    const [formFieldStatus, setFormFieldStatus] = useState('editMode');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (configured) {
            setFormFieldStatus('configured');
            setLoading(false);
        } else if (product?.investmentApplicationId) {
            setFormFieldStatus('toConfigure');
            setLoading(false);
        } else {
            setFormFieldStatus('editMode');
        }
    }, [configured, product?.investmentApplicationId]);

    const handleSave = useCallback(() => {
        setLoading(true);
        if (product?.id) {
            saveNewProduct(index);
        }
    }, [product, saveNewProduct]);

    const onConfig = useCallback(() => {
        if (product?.investmentApplicationId) {
            setLoading(true);
            handleConfig(product?.investmentApplicationId);
        }
    }, [product, saveNewProduct]);

    const handleShowChartModal = () => {
        if (saveCurrentInvestmentApplicationIdAction) {
            saveCurrentInvestmentApplicationIdAction(product?.investmentApplicationId as number);
        }
        setShowChartModal(true);
    };

    useEffect(() => {
        const minimum = productOptions?.find(prod => prod.value === formData?.product)?.minimumInvestment;

        if (formData?.amount && (formData.amount < minimum)) {
            setError(t('ShoppingCart.ProductRow.PlannedInvestment.MinimumAmount.Error', {
                amount: getAmountWithDecimals(minimum, ''),
            }));
        } else {
            setError(null);
        }
    }, [productOptions, formData?.amount, formData?.product, language]);

    return (
        <>
            {/* <form className="edit-strategy-form"> */}
            <div className="product-row">
                <div className="product-col">
                    {formFieldStatus !== 'editMode' ? (
                        <div className="configured-col">
                            <StaticField
                                label={t('ShoppingCart.ProductRow.ProductName')}
                                value={product?.productName as string}
                                width="140px"
                            />
                            {formFieldStatus === 'configured' && (
                                <>
                                    <StaticField
                                        label={t('ShoppingCart.ProductRow.InvestmentStrategy')}
                                        value={product?.newStrategy as string}
                                        width="190px"
                                        Icon={product?.product === THREEBTYPES.pk || (
                                            <Button
                                                type="link"
                                                onClick={handleShowChartModal}
                                                className="edit-icon-button"
                                            >
                                                <Icon
                                                    type={ICON_TYPE_CHART_ARROW}
                                                    size={20}
                                                    color={undefined}
                                                    ariaLabel={undefined}
                                                    component={undefined}
                                                    className={undefined}
                                                />
                                                {t('ShoppingCart.ProductRow.Button.Chart')}
                                                {/* Chart */}
                                            </Button>
                                        )}
                                    />
                                    {!is3bProductGroupSelector && (
                                        <>
                                            <StaticField
                                                label={t('ShoppingCart.ProductRow.Transfer')}
                                                value={getAmountWithDecimals(product?.transactionAmount || product?.amount)}
                                                width="180px"
                                            />

                                            <StaticField
                                                label={t('ShoppingCart.ProductRow.YearlyPayments')}
                                                value={getAmountWithDecimals(product?.yearlyPayments)}
                                                width="180px"
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            {(is3bProductGroupSelector || !configured) && (
                                <StaticField
                                    label={t('ShoppingCart.ProductRow.PlannedInvestment')}
                                    value={!product?.amount ? '-' : getAmountWithDecimals(product?.amount)}
                                    width="180px"
                                />
                            )}
                        </div>
                    ) : (
                        <div className="add-product-form">
                            {is3bProductGroupSelector ? (
                                <Controller
                                    name={`products.${index}.product`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label={t('ShoppingCart.ProductRow.ProductName')}
                                            options={productOptions}
                                            placeholder={t('position.pleaseSelect')}
                                            error={errors?.[`products[${index}].product`]?.message}
                                            required
                                        />
                                    )}
                                />
                            ) : (
                                <StaticField
                                    label={t('ShoppingCart.ProductRow.ProductName')}
                                    value={formData?.product as string}
                                    width="180px"
                                />
                            )}
                            <Controller
                                name={`products.${index}.amount`}
                                control={control}
                                render={({ field }) => (
                                    <NumberInput
                                        {...field}
                                        id="investmentAmount"
                                        placeholder=""
                                        thousandSeparator="’"
                                        decimalSeparator="."
                                        label={is3bProductGroupSelector ? t('ShoppingCart.ProductRow.PlannedInvestment') : t('ShoppingCart.ProductRow.YearlyPayments')}
                                        data-cy={`products.${index}.amount`}
                                        prefix="CHF "
                                        required={formData?.product !== PRODUCT_ID_FOR_PK}
                                        error={errors?.[`products[${index}].amount`]?.message || error}
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className="product-row-button-group">
                        <>
                            <Button
                                type="link"
                                loading={cancelInProgress === product?.investmentApplicationId}
                                onClick={() => handleDeleteRow({
                                    index,
                                    mode: formFieldStatus,
                                    applicationId: product?.investmentApplicationId as number,
                                })}
                            >
                                <Icon
                                    type={ICON_TYPE_BIN}
                                    size={20}
                                    color={undefined}
                                    ariaLabel={undefined}
                                    component={undefined}
                                    className={undefined}
                                />
                                {t('ShoppingCart.productRow.buttons.Delete')}
                            </Button>
                            {formFieldStatus === 'editMode' && (
                                <Button
                                    onClick={handleSave}
                                    type="submit"
                                    loading={loading}
                                    disabled={saveButtonDisabled || error}
                                >
                                    {t('ShoppingCart.productRow.buttons.Save')}
                                </Button>
                            )}
                            {formFieldStatus === 'toConfigure' && (
                                <Button
                                    type="primary"
                                    onClick={onConfig}
                                    loading={loading}
                                >
                                    {t('ShoppingCart.productRow.buttons.Configure')}
                                </Button>
                            )}
                            {formFieldStatus === 'configured' && (
                                <Button
                                    type="link"
                                    onClick={onConfig}
                                >
                                    <Icon
                                        type={ICON_EDIT_MINIMAL}
                                        size={20}
                                    />
                                    {t('ShoppingCart.productRow.buttons.Edit')}
                                </Button>
                            )}
                        </>
                    </div>
                </div>
            </div>
            {showChartModal && (
                <Modal
                    title={t('portfolio.createNewPortfolio.calculationExample')}
                    visible
                    okText={t('confirmation.cancel')}
                    onOk={() => setShowChartModal(false)}
                    width={650}
                    cancelText={null}
                >
                    <ProjectionChartComp product={product} continueDisabled={continueDisabled} t={t}/>
                </Modal>
            )}
        </>
    );
};

export default NewProductRow;
