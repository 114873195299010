import React, { useCallback, useEffect, useMemo } from 'react';
import Preloader from 'components/Preloader';
import { Modal } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    isAnyOfThreeBProductTypes,
} from 'hooks/isThreeBProduct';
import { useLayout } from 'pages/ClientOverview/pages/Portfolios/hooks/useLayout';
import { getPortfolioStatus } from 'constants/portfolioStatuses';
import EmptyContent from 'components/EmptyContent';
import { APPLICATION_CONTAINER_ID, PRODUCT_EXTERNAL_ID, QUALITY_CHECK_DATA, THREEBTYPES } from 'constants/constants';
import { CreatePortfolioManager } from 'datasource/CreatePortfolio';
import { PortfolioProcessLayoutManager } from 'datasource/PortfolioProcessLayout';
import { StepperManager } from '@/core/stepper/StepperManager';
import { modifyPlan } from '../../../../../../utils/utils';
import { useSinglePortfolio } from '../../context';

import './Strategy.css';
import BasicStrategy from './BasicStrategy';
import IndividualStratagy from './IndividualStratagy';

const Strategy = () => {
    const navigate = useNavigate();
    const { clearLayoutCache } = useLayout();
    const { dfsClientId, portfolioId } = useParams();
    const { t, i18n: { language } } = useTranslation();
    const {
        isPortfolioChangeable,
        portfolioProducts,
        strategyName,
        riskCategories,
        riskName,
        modelPortfolioDescription,
        getSelectStrategy,
        getFormattedXAxisFormat,
        allocations,
        isLoading,
        error,
        portfolioData,
        isIndividualPortfolio,
        ongoingPlan,
        individualPortfolioData,
        isRebalancing,
        withdrawal,
        withdrawalData,
    } = useSinglePortfolio();
    const modalContent = useMemo(() => (
        <div>
            <span>{t('strategy.blockChangePlan.modalContentDesc')}</span>
            <div>
                {t('strategy.blockChangePlan.modalContentCurrent', { plan: ongoingPlan?.current?.[language]?.['#text'] })}
            </div>
            <div>
                {t('strategy.blockChangePlan.modalContentSubmitted', { plan: ongoingPlan?.submitted?.[language]?.['#text'] })}
            </div>
        </div>
    ), [t, language, ongoingPlan]);

    useEffect(() => {
        if (ongoingPlan && !isLoading && ongoingPlan?.portfolioId === portfolioId) {
            Modal.info({
                title: t('strategy.blockChangePlan.modalTitle'),
                content: modalContent,
            });
        }
    }, [isLoading, modalContent, portfolioId]);

    const isThreeBProduct = useMemo(() => isAnyOfThreeBProductTypes(portfolioData?.Product?.ExternalId), [portfolioData.Product]);

    const onChangePlan = useCallback(() => {
        clearLayoutCache();
        modifyPlan({
            path: `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy-legacy/${isThreeBProduct ? 'advisory-setup' : 'personal-details'}`,
            action: 'ChangeStrategy',
            t,
            id: portfolioId,
            status: getPortfolioStatus({
                portfolioStatusId: portfolioData?.PortfolioStatusId,
                isBreached: portfolioData?.IsBreached,
                isOrderBookBlocked: portfolioData?.HasPendingOrders,
            }),
            productExternalId: portfolioData?.Product?.ExternalId,
            amount: portfolioData?.InvestedAmount,
            navigate,
        });
    }, [dfsClientId, portfolioId, portfolioData?.PortfolioStatusId, portfolioData?.IsBreached, portfolioData?.HasPendingOrders]);

    const onChangePlanSimplified = useCallback(() => {
        clearLayoutCache();
        sessionStorage.removeItem(StepperManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(CreatePortfolioManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(PortfolioProcessLayoutManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
        sessionStorage.removeItem(QUALITY_CHECK_DATA);
        if (portfolioData?.Product?.ExternalId === THREEBTYPES.zivv) {
            sessionStorage.removeItem(PRODUCT_EXTERNAL_ID);
            navigate(`/client/${dfsClientId}/portfolios/new-portfolio/`, {
                state: {
                    changePlan: true,
                },
            });
        } else {
            sessionStorage.setItem(PRODUCT_EXTERNAL_ID, portfolioData?.Product?.ExternalId);
            modifyPlan({
                path: `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy/${(isThreeBProduct && portfolioData?.Product?.ExternalId !== THREEBTYPES.zifd) ? 'advisory-setup' : 'personal-details'}`,
                action: 'ChangeStrategy',
                t,
                id: portfolioId,
                status: getPortfolioStatus({
                    portfolioStatusId: portfolioData?.PortfolioStatusId,
                    isBreached: portfolioData?.IsBreached,
                    isOrderBookBlocked: portfolioData?.HasPendingOrders,
                }),
                productExternalId: portfolioData?.Product?.ExternalId,
                amount: portfolioData?.InvestedAmount,
                navigate,
            });
        }
    }, [dfsClientId, portfolioId, portfolioData?.PortfolioStatusId, portfolioData?.Product?.ExternalId, portfolioData?.IsBreached, portfolioData?.HasPendingOrders]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={allocations}>
                <div className="container">
                    <div className="row">
                        {(
                            isIndividualPortfolio ? (
                                <IndividualStratagy
                                    isRebalancing={isRebalancing}
                                    riskName={riskName}
                                    individualPortfolioData={individualPortfolioData}
                                    ongoingPlan={ongoingPlan}
                                    onChangePlan={onChangePlan}
                                    onChangePlanSimplified={onChangePlanSimplified}
                                    allocations={allocations}
                                    strategyName={strategyName}
                                    portfolioProducts={portfolioProducts}
                                    t={t}
                                    riskCategories={riskCategories}
                                    strategy={getSelectStrategy()}
                                    getFormattedXAxisFormat={getFormattedXAxisFormat}
                                    isPortfolioChangeable={isPortfolioChangeable}
                                />
                            ) : (
                                <BasicStrategy
                                    riskName={riskName}
                                    modelPortfolioDescription={modelPortfolioDescription}
                                    ongoingPlan={ongoingPlan}
                                    onChangePlan={onChangePlan}
                                    onChangePlanSimplified={onChangePlanSimplified}
                                    allocations={allocations}
                                    strategyName={strategyName}
                                    portfolioProducts={portfolioProducts}
                                    t={t}
                                    riskCategories={riskCategories}
                                    strategy={getSelectStrategy()}
                                    getFormattedXAxisFormat={getFormattedXAxisFormat}
                                    isPortfolioChangeable={isPortfolioChangeable}
                                    withdrawal={withdrawal}
                                    withdrawalData={withdrawalData}
                                />
                            )
                        )}
                    </div>
                </div>
            </EmptyContent>
        </Preloader>
    );
};

export default Strategy;
