/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */

import React, {
    useRef, useState, useEffect,
} from 'react';
import PropTypes, { string } from 'prop-types';
import TextInput from '../TextInput';
import FormGroup from '../FormGroup';
import Label from '../Label';
import { getClassNames } from '../../utils';
import {
    // maxValueLength
    roundToPrecision,
    splitDecimal, limitToPrecision, applyThousandSeparator,
    getCurrentCaretPosition,
    findChangedIndex, clamp,
} from './utils';

import './NumberInput.css';
import withTheme from '../../hocs/withTheme';


/** A basic widget for getting the user input is a number field with formatter.
 * Keyboard and mouse can be used for providing or changing data.
 *
 * DO Always use labels on text inputs and don’t depend on the placeholder
 * text. If there is more information for the text input needed make
 * use of a paragraph on top or use an info box. */

function NumberInput(props) {
    const {
        suffix, prefix, thousandsGroupStyle, validation,
        disabled, error, errorIcon, errorInTooltip, label,
        labelInfobox, required, questionmark, verified,
        children, value, decimalSeparator, thousandSeparator, defaultValue,
        onChange, precision, withSelectBox, helpText, getFormattedValue, textRight = false, ...rest
    } = props;
    const [state, setState] = useState(getInitialValue(value || defaultValue));
    const inputRef = useRef(null);

    useEffect(() => {
        setState(getInitialValue(value));
    }, [value]);


    useEffect(() => {
        if (decimalSeparator === thousandSeparator) {
            throw new Error('Privided Decimal and Thousand are equal. Use various separators!');
        }
    }, [decimalSeparator, thousandSeparator]);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            if (inputRef && inputRef.current) {
                inputRef.current.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [inputRef]);

    // todo fix me
    // eslint-disable-next-line no-unused-vars
    const iconProps = {
        errorInTooltip, error, errorIcon, questionmark, verified,
    };

    function getAllowedDecimentalSeparators() {
        return [decimalSeparator];
    }

    function updateCaretPosition(position) {
        inputRef.current.selection = position;
        inputRef.current.selectionStart = position;
        inputRef.current.selectionEnd = position;
    }

    function formatAsNumber(numStr) {
        const hasDecimalSeparator = numStr.indexOf('.') !== -1;

        // eslint-disable-next-line prefer-const
        let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr);

        afterDecimal = limitToPrecision(afterDecimal, precision);

        if (thousandSeparator) {
            beforeDecimal = applyThousandSeparator(
                beforeDecimal,
                thousandSeparator,
                thousandsGroupStyle,
            );
        }

        if (prefix) beforeDecimal = prefix + beforeDecimal;

        if (suffix) afterDecimal = afterDecimal + suffix;

        if (addNegation) beforeDecimal = `-${beforeDecimal}`;

        // eslint-disable-next-line no-mixed-operators
        numStr = beforeDecimal + (hasDecimalSeparator && decimalSeparator || '') + afterDecimal;

        return numStr;
    }

    function formatNumString(numStr) {
        let formattedValue = numStr;

        if (numStr === '') {
            formattedValue = '';
        } else if (numStr === '-') {
            formattedValue = '-';
        } else {
            formattedValue = formatAsNumber(formattedValue);
        }

        return formattedValue;
    }

    function getInitialValue(initial) {
        const value = (
            initial === null
            || typeof initial === 'boolean'
            // eslint-disable-next-line no-restricted-globals
            || isNaN(initial)
            || initial === Infinity
            || initial === -Infinity)
            || initial === undefined
            || (typeof initial === 'string' && initial.length < 0)
            ? ''
            : formatNumString(roundToPrecision(String(initial), precision));

        return { value, raw: removeFormatting(value) };
    }

    function removeFormatting(value) {
        if (!value) return value;

        return getFloatString(removePrefixAndSuffix(value));
    }

    function removePrefixAndSuffix(val) {
        if (!val) return val;

        const isNegative = val[0] === '-';

        if (isNegative) val = val.substring(1, val.length);

        val = prefix && val.indexOf(prefix) === 0 ? val.substring(prefix.length, val.length) : val;

        const suffixLastIndex = val.lastIndexOf(suffix);

        val = (suffix && suffixLastIndex !== -1 && suffixLastIndex === val.length - suffix.length)
            ? val.substring(0, suffixLastIndex)
            : val;

        if (isNegative) val = `-${val}`;

        return val;
    }

    function getFloatString(num = '') {
        const numRegex = getNumberRegex(true);

        const hasNegation = num[0] === '-';

        if (hasNegation) num = num.replace('-', '');

        if (decimalSeparator && precision === 0) {
            // eslint-disable-next-line prefer-destructuring
            num = num.split(decimalSeparator)[0];
        }

        num = (num.match(numRegex) || []).join('').replace(decimalSeparator, '.');

        const firstDecimalIndex = num.indexOf(decimalSeparator);

        if (firstDecimalIndex !== -1) {
            num = `${num.substring(0, firstDecimalIndex)}.${num.substring(firstDecimalIndex + 1, num.length)
                .replace(new RegExp(escapeRegExp(decimalSeparator), 'g'), '')}`;
        }

        if (hasNegation) num = `-${num}`;

        return num;
    }

    function escapeRegExp(str) {
        return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    }

    function getNumberRegex(g, ignoreDecimalSeparator) {
        // eslint-disable-next-line prefer-template
        return new RegExp('\\d' + (decimalSeparator && precision !== 0 && !ignoreDecimalSeparator ? '|' + escapeRegExp(decimalSeparator) : ''), g ? 'g' : undefined);
    }

    function isCharacterAFormat(caretPos, value) {
        if (caretPos < prefix.length || caretPos >= value.length - suffix.length) {
            return true;
        }

        return false;
    }

    function checkIfFormatGotDeleted(start, end, value) {
        for (let i = start; i < end; i++) {
            if (isCharacterAFormat(i, value)) return true;
        }

        return false;
    }

    function formatNegation(value = '') {
        const negationRegex = new RegExp('(-)');
        const doubleNegationRegex = new RegExp('(-)(.)*(-)');

        const hasNegation = negationRegex.test(value);
        const removeNegation = doubleNegationRegex.test(value);

        value = value.replace(/-/g, '');

        if (hasNegation && !removeNegation) {
            value = `-${value}`;
        }

        return value;
    }

    function correctInputValue(caretPosition, lastValue, value) {
        const [selectionStart, selectionEnd] = [0, 0];
        const lastRaw = state.raw || '';

        const { start, end } = findChangedIndex(lastValue, value);

        if (start === end
            && getAllowedDecimentalSeparators().indexOf(value[selectionStart]) !== -1) {
            const separator = precision === 0 ? '' : decimalSeparator;

            return value.substr(0, selectionStart)
                + separator
                + value.substr(selectionStart + 1, value.length);
        }


        const leftBound = prefix.length;
        const rightBound = lastValue.length - suffix.length;

        if (value.length > lastValue.length
            || !value.length
            || start === end
            || (selectionStart === 0 && selectionEnd === lastValue.length)
            || (selectionStart === leftBound && selectionEnd === rightBound)) {
            return value;
        }

        if (checkIfFormatGotDeleted(start, end, lastValue)) {
            value = lastValue;
        }

        const numericString = removeFormatting(value);
        // eslint-disable-next-line prefer-const
        let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numericString);
        const isBeforeDecimalPoint = caretPosition < value.indexOf(decimalSeparator) + 1;

        if (numericString.length < lastRaw.length && isBeforeDecimalPoint && beforeDecimal === '' && !parseFloat(afterDecimal)) {
            return addNegation ? '-' : '';
        }

        return value;
    }

    function formatInput(value) {
        value = removePrefixAndSuffix(value);

        value = formatNegation(value);

        value = removeFormatting(value);

        return formatNumString(value) || '';
    }

    function correctCaretPosition(value, caretPos) {
        if (value === '') return 0;

        caretPos = clamp(caretPos, 0, value.length);

        const hasNegation = value[0] === '-';

        return clamp(caretPos, prefix.length + (hasNegation ? 1 : 0), value.length - suffix.length);
    }

    function getCaretPosition(inputValue, formattedValue, caretPos) {
        const { value } = state;
        const numRegex = getNumberRegex(true);
        const inputNumber = (inputValue.match(numRegex) || []).join('');
        const formattedNumber = (formattedValue.match(numRegex) || []).join('');

        let j = 0;
        let i;

        for (i = 0; i < caretPos; i++) {
            const currentInputChar = inputValue[i] || '';
            const currentFormatChar = formattedValue[j] || '';

            if (!currentInputChar.match(numRegex) && currentInputChar !== currentFormatChar) {
                // eslint-disable-next-line no-continue
                continue;
            }

            if (currentInputChar === '0' && currentFormatChar.match(numRegex) && currentFormatChar !== '0' && inputNumber.length !== formattedNumber.length) {
                // eslint-disable-next-line no-continue
                continue;
            }

            while (currentInputChar !== formattedValue[j] && j < formattedValue.length) j++;
            j++;
        }

        if (!value) {
            j = formattedValue.length;
        }

        j = correctCaretPosition(formattedValue, j);

        return j;
    }

    function handleMouseUp({ target: { selectionStart, selectionEnd, value = '' } }) {
        if (selectionStart === selectionEnd) {
            const caretPosition = correctCaretPosition(value, selectionStart);

            updateCaretPosition(caretPosition);
        }
    }

    function updateValue({
        formattedValue, rawValue: raw, corrected, target, caretPosition, setCaretPosition = true,
    }) {
        const lastValue = state.value || '';

        if (target) {
            if (setCaretPosition) {
                if (!caretPosition) {
                    const inputValue = corrected || target.value;

                    const currentCaretPosition = getCurrentCaretPosition(target);

                    /**
                     * This is required if new caret position is beyond prev val
                     * caret position won't be set correctly
                     */
                    target.value = formattedValue;

                    caretPosition = getCaretPosition(
                        inputValue,
                        formattedValue,
                        currentCaretPosition,
                    );
                }

                updateCaretPosition(caretPosition);
            }
        }

        if (raw === undefined) {
            raw = removeFormatting(formattedValue);
        }

        if (formattedValue !== lastValue) {
            setState(() => ({
                value: formattedValue,
                raw,
            }));

            onChange(raw);
        }
    }

    function isValid(value, type, num) {
        if (typeof num !== 'number' || value === '-' || value === decimalSeparator) {
            return true;
        }

        switch (type) {
            case 'maxValue':
                return value <= num;
            case 'minValue':
                return value >= num;
            default:
                return true;
        }
    }

    function getValidated(value, { minValue, maxValue }) {
        if (!isValid(value, 'minValue', minValue)) return formatNumString(minValue.toString());
        if (!isValid(value, 'maxValue', maxValue)) return formatNumString(maxValue.toString());

        return String(value);
    }

    function getValidatedFormattedValue(value, { minValue, maxValue }) {
        const val = removeFormatting(value);
        const isNegative = val?.[0] === '-' ? '-' : '';
        const valParts = val.replace('-', '').match(/^([0-9]*)([0-9.,]*)/) || [];
        const valInt = !valParts[1] || valParts[1] === parseInt(valParts[1], 10).toString()
            ? valParts[1] : parseInt(valParts[1], 10);
        const valFull = !!isNegative && valInt !== '0'
            ? isNegative + valInt + valParts[2]
            : valInt + valParts[2];

        if (isValid(val, 'minValue', minValue) && isValid(val, 'maxValue', maxValue)) {
            return formatNumString(valFull);
        }

        return getValidated(valFull, { minValue, maxValue });
    }

    function getValidatedRawValue(value, validation) {
        const validated = getValidated(value, validation);

        return validated !== '' ? String(Number(validated)) : validated;
    }

    function handleChange({ target, target: { value } }) {
        const lastValue = state.value || '';

        const currentCaretPosition = getCurrentCaretPosition(target);

        const corrected = correctInputValue(currentCaretPosition, lastValue, value);

        const formattedValue = getValidatedFormattedValue(formatInput(corrected), validation);
        const rawValue = getValidatedRawValue(removeFormatting(formattedValue), validation);

        updateValue({
            formattedValue, rawValue, corrected, target,
        });
    }

    const classNames = getClassNames({
        TextInput_invalid: error,
        'with-select-box': withSelectBox,
        'text-right': textRight,
    });


    return (
        <FormGroup disabled={disabled} error={error} className="Number-input-wrapper">
            {label && (
                <Label
                    htmlFor={label}
                    label={label}
                    labelInfobox={labelInfobox}
                    required={required}
                    helpText={helpText}
                />
            )}

            <TextInput
                id={label}
                {...rest}
                ref={inputRef}
                value={state.value}
                className={classNames}
                errorInTooltip={errorInTooltip}
                error={error}
                disabled={disabled}
                required={required}
                onChange={handleChange}
                precision={precision}
                textRight={textRight}
            >
                {children}
            </TextInput>
        </FormGroup>
    );
}

NumberInput.propTypes = {
    /** The label text */
    label: PropTypes.string,
    /** The text displayed if the label tooltip exist */
    labelInfobox: PropTypes.bool,
    /** The placeholder text */
    placeholder: PropTypes.string,
    /** @ignore */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /** @ignore */
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    /** @ignore */
    disabled: PropTypes.bool,
    /** If required field* */
    required: PropTypes.bool,
    /** The error message displayed if the input is errored */
    error: PropTypes.string,
    /** If the space does not allow show error message below the input
     *  make use of the red error indicator with tooltip. */
    errorIcon: PropTypes.bool,
    /** The error message displayed is tooltip */
    errorInTooltip: PropTypes.bool,
    /** Whether the input field has questionmark icon */
    questionmark: PropTypes.bool,
    /** Whether the input field has verified icon */
    verified: PropTypes.bool,
    /** @ignore */
    addonAfter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /** @ignore */
    children: PropTypes.node,
    /** @ignore */
    precision: PropTypes.number,
    /** @ignore */
    suffix: PropTypes.string,
    /** @ignore */
    prefix: PropTypes.string,
    /** @ignore */
    thousandsGroupStyle: PropTypes.string,
    /** Callback function that is executed when when value is changed,
     it provides stringified float value (without prefix or suffix) */
    onChange: PropTypes.func,
    /** Callback function that is executed when when value is changed,
     it provides stringified input value (incledes prefix or suffix) */
    getFormattedValue: PropTypes.func,
    /** @ignore */
    withSelectBox: PropTypes.bool,
    /** You can provide validation rules.
     * It's gonna check min or max and return it if validation has been failed */
    validation: PropTypes.shape({
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
    }),
    /** Decimal sepearator */
    decimalSeparator: string,
    /** Thousand Separator */
    thousandSeparator: string,
    /** The text displayed if the label tooltip exist */
    helpText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    textRight: PropTypes.bool,
};

NumberInput.defaultProps = {
    suffix: '',
    prefix: '',
    thousandsGroupStyle: 'thousand',
    label: null,
    labelInfobox: false,
    placeholder: null,
    value: '',
    defaultValue: undefined,
    disabled: false,
    required: false,
    error: null,
    errorIcon: false,
    errorInTooltip: false,
    questionmark: false,
    verified: false,
    addonAfter: null,
    children: null,
    precision: 2,
    onChange: (v) => v,
    getFormattedValue: (v) => v,
    withSelectBox: false,
    validation: {
        maxValue: null,
        minValue: null,
    },
    decimalSeparator: ".",
    thousandSeparator: "’",
    helpText: null,
    textRight: false
};

export default withTheme(NumberInput);
