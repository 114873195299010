import { findMinAndMax, newAdaptProjectionData } from 'adaptors/adaptProjection';
import ProjectionTooltip from 'components/ProjectionTooltip';
import { THREEBTYPES } from 'constants/constants';
import { projectionOptional } from 'constants/goalCreation';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from 'datasource/CreatePortfolio';
import HandlerError from 'errors/HandlerError';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import { useFormatting } from 'locale';
import { riskCategoriesByLang } from 'pages/ClientOverview/pages/Portfolios/constants';
import {
    MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import {
    useInvestmentContributions,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/GoalAndPortfolioSetup/hooks/useInvestmentContributions';
import { calculatePlannedInvestment } from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/utils';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { formatNumberRounding } from 'utils/formatting';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { IsAllDataNull, validateInputNumberWithZero, validateInputString } from 'validation/validation';
import { errorModel } from '../models/errorModel';
import getInvestmentProjection from '../services/getInvestmentProjection';

let transferValueTimeoutId;
let yearlyPaymentTimeoutId;
let projectionYearsTimeoutId;

const INPUT_TIMEOUT = 200;
const MAX_INVESTMENT_FOR_NEXT_6_MONTHS = 200000;

export const useGoalAndPerformance = ({ continueDisabledFromProps = false }) => {
    const {
        currentInvestmentApplicationSelector,
        is3bProductGroupSelector: isThreeB,
        selectedProductSelector,
        currentInvestmentDescriptionSelector,
        currentClientSelector,
        selectedProductDescSelector,
    } = useCreatePortfolioSelectors();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();

    const {
        shouldInitiateAdvisoryProcess,
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();

    const modelPortfolioId = currentInvestmentDescriptionSelector.selectStrategy?.modelPortfolioId;
    const isZurichEmployee = currentInvestmentDescriptionSelector?.personalDetails?.zurichEmployee;

    const { i18n: { language }, t } = useTranslation();
    const [rubrik, setRubrik] = useState();
    const [transferValue, setTransferValue] = useState(0);
    const [yearlyPayment, setYearlyPayment] = useState(0);
    const [minMaxTransferValue, setMinMaxTransferValue] = useState(undefined);
    const [minMaxYearlyPayment, setMinMaxYearlyPayment] = useState({ min: 0, max: 6683 });
    const [isFullTransfer, setFullTransfer] = useState('true');
    const [currency, setCurrency] = useState(0);
    const [riskCategoryId, setRiskCategoryId] = useState(3);
    const [chartData, setChartData] = useState([]);
    const [returnRates, setReturnRates] = useState(null);
    const [modelPortfolio, setModelPortfolio] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [prevPensionScheme, setPrevPensionScheme] = useState({});
    const [validationPrevPensionScheme, setValidationPrevPensionScheme] = useState({});
    const [plannedInvestment, setPlannedInvestment] = useState(0);
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const [
        pageErrors, setPageErrors,
    ] = useState();
    const [projectionYears, setProjectionYears] = useState(null);
    const [paymentPeriod, setPaymentPeriod] = useState();
    const [periodicPayment, setPeriodicPayment] = useState();
    const [oneTimeInvestment, setOneTimeInvestment] = useState();
    const [isPaymentPeriodVisible, setPaymentPeriodVisibility] = useState(false);

    const riskCategoriesList = useMemo(() => (riskCategoriesByLang(t)), [t]);
    const productName = useMemo(() => (selectedProductSelector?.name), [selectedProductSelector?.name]);
    const productSettings = useMemo(() => (selectedProductSelector?.settings), [selectedProductSelector?.settings]);
    const affiliatedToPensionFund = useMemo(() => (currentInvestmentDescriptionSelector?.personalDetails?.affiliatedToPensionFund), [currentInvestmentDescriptionSelector?.personalDetails?.affiliatedToPensionFund]);
    const individualPortfolio = useMemo(() => (currentInvestmentDescriptionSelector?.selectStrategy?.individualPortfolio), [currentInvestmentDescriptionSelector?.selectStrategy?.individualPortfolio]);
    const showFullTransferQuestion = useMemo(() => (!productSettings?.showYearlyPayments), [productSettings?.showTransferQuestion]);
    const initialInvestmentAmount = useMemo(() => (currentInvestmentApplicationSelector.goals?.InitialInvestmentAmount), [currentInvestmentApplicationSelector.goals?.InitialInvestmentAmount]);
    const isWithdrawal = useMemo(() => (selectedProductSelector?.selectStrategy?.withdrawalOption), [selectedProductSelector?.selectStrategy?.withdrawalOption]);
    const {
        isAzp, isZic, isZifd,
    } = useThreeBProductType({ product: selectedProductSelector });

    const withdrawalAzpInvestmentAmount = useMemo(() => (currentInvestmentDescriptionSelector?.withdrawal?.investmentAmount), [currentInvestmentDescriptionSelector?.withdrawal?.investmentAmount]);

    const minPeriodicPaymentFor3B = useMemo(() => (isZurichEmployee ? (productSettings?.minPeriodicPaymentZurich ?? productSettings?.minPeriodicPayment) : productSettings?.minPeriodicPayment), [isZurichEmployee, productSettings?.minPeriodicPaymentZurich, productSettings?.minPeriodicPayment]);
    const minOneTimeInvestmentFor3B = useMemo(() => (isZurichEmployee ? (productSettings?.minOneTimeInvestmentZurich ?? productSettings?.minOneTimeInvestment) : productSettings?.minOneTimeInvestment), [isZurichEmployee, productSettings?.minOneTimeInvestmentZurich, productSettings?.minOneTimeInvestment]);

    const {
        getYearlyContribution,
        getInvestmentForNextSixMonths,
    } = useInvestmentContributions(selectedProductSelector?.externalId, productSettings, {
        periodicPayment,
        paymentPeriod,
        oneTimeInvestment,
    });

    const onPeriodicPaymentChange = useCallback((value) => {
        clearTimeout(yearlyPaymentTimeoutId);

        setPaymentPeriodVisibility(value !== 0);
        yearlyPaymentTimeoutId = setTimeout(() => {
            setPeriodicPayment(value);
            setPlannedInvestment(calculatePlannedInvestment(oneTimeInvestment, value, paymentPeriod));
        }, 500);
    }, [oneTimeInvestment, paymentPeriod, calculatePlannedInvestment, minPeriodicPaymentFor3B]);

    useEffect(() => {
        if (periodicPayment === 0 && oneTimeInvestment < minOneTimeInvestmentFor3B) {
            setOneTimeInvestment(minOneTimeInvestmentFor3B);
        }
    }, [minOneTimeInvestmentFor3B, periodicPayment, oneTimeInvestment]);

    const onOneTimePaymentChange = useCallback((value) => {
        clearTimeout(transferValueTimeoutId);

        transferValueTimeoutId = setTimeout(() => {
            setOneTimeInvestment(value);
            setPlannedInvestment(calculatePlannedInvestment(value, periodicPayment, paymentPeriod));
        }, INPUT_TIMEOUT);
    }, [periodicPayment, paymentPeriod, calculatePlannedInvestment]);

    const onPaymentPeriodChange = useCallback((value) => {
        setPaymentPeriod(value);
        setPlannedInvestment(calculatePlannedInvestment(oneTimeInvestment, periodicPayment, value));
    }, [oneTimeInvestment, periodicPayment, calculatePlannedInvestment]);

    const onProjectionYearsChange = useCallback((val) => {
        clearTimeout(projectionYearsTimeoutId);

        projectionYearsTimeoutId = setTimeout(() => {
            setProjectionYears(val);
        }, 500);
    }, []);

    const setProjectionYearsValue = useCallback((val) => {
        setProjectionYears(val);
    }, []);

    const isInvestmentAllowed = useMemo(() => {
        const plannedInvestmentForNext6Months = getInvestmentForNextSixMonths();

        return plannedInvestmentForNext6Months <= MAX_INVESTMENT_FOR_NEXT_6_MONTHS || (plannedInvestmentForNext6Months > MAX_INVESTMENT_FOR_NEXT_6_MONTHS && shouldInitiateAdvisoryProcess);
    }, [shouldInitiateAdvisoryProcess, getInvestmentForNextSixMonths]);

    const isProofOfAssetsRequired = useMemo(() => oneTimeInvestment > MAX_INVESTMENT_WITHOUT_PROOF_OF_ASSETS && selectedProductSelector.externalId === THREEBTYPES.zic, [oneTimeInvestment, selectedProductSelector?.externalId]);

    useEffect(() => {
        if (!plannedInvestment) {
            setPlannedInvestment(calculatePlannedInvestment(oneTimeInvestment, periodicPayment, paymentPeriod));
        }
    }, [oneTimeInvestment, paymentPeriod, periodicPayment, calculatePlannedInvestment, plannedInvestment]);

    const radioFullTransferOptions = useMemo(() => ([
        {
            label: t('portfolio.createNewPortfolio.configuration.options.fullTransfer'),
            value: 'true',
            disabled: false,
            error: false,
        }, {
            label: t('portfolio.createNewPortfolio.configuration.options.partialTransfer'),
            value: 'false',
            disabled: false,
            error: false,
        },
    ]), []);

    useEffect(() => {
        if (currentClientSelector?.age) {
            setProjectionYears(65 - currentClientSelector.age);
        }
    }, [currentClientSelector?.age]);

    useEffect(() => {
        if (productSettings) {
            setMinMaxTransferValue({
                min: productSettings?.transferAmount?.transferAmountMin,
                max: productSettings?.transferAmount?.transferAmountMax,
            });

            setMinMaxYearlyPayment({
                min: 0,
                max: affiliatedToPensionFund
                    ? productSettings?.maximumYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue
                    : productSettings?.maximumYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse,
            });

            const defaultYearlyPayment = affiliatedToPensionFund ? productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundTrue : productSettings?.defaultYearlyContributionWithPensionFund?.isDependantOnAffiliatedWithPensionFundFalse;

            setYearlyPayment(currentInvestmentDescriptionSelector?.productConfiguration?.yearlyPayments || defaultYearlyPayment || 0);

            if (!productSettings?.showYearlyPayments) {
                changeTransferValue((initialInvestmentAmount || productSettings?.defaultTransferAmount) ?? 0);
            }
        }
    }, [productSettings, affiliatedToPensionFund, initialInvestmentAmount, currentInvestmentDescriptionSelector?.productConfiguration?.yearlyPayments]);

    useEffect(() => {
        if (isThreeB) {
            setRubrik(currentInvestmentDescriptionSelector?.productConfiguration?.rubrik);
            setOneTimeInvestment(currentInvestmentDescriptionSelector?.productConfiguration?.initialInvestment || productSettings?.defaultOneTimeInvestment);
            setPeriodicPayment(currentInvestmentDescriptionSelector?.productConfiguration?.periodicalPaymentAmount || productSettings?.defaultPeriodicPayment);
            setPaymentPeriod(currentInvestmentDescriptionSelector?.productConfiguration?.periodicity || productSettings?.defaultPaymentFrequency);
        }
    }, [currentInvestmentDescriptionSelector, isThreeB]);

    useEffect(() => {
        if (currentInvestmentApplicationSelector?.goals) {
            setTransferValue(initialInvestmentAmount);
        }
        if (showFullTransferQuestion && currentInvestmentApplicationSelector?.isFullTransfer !== null && currentInvestmentApplicationSelector?.isFullTransfer !== undefined) {
            setFullTransfer(`${currentInvestmentApplicationSelector?.isFullTransfer}`);
        }
        if (currentInvestmentApplicationSelector?.prevPensionScheme) {
            setPrevPensionScheme({
                ...currentInvestmentApplicationSelector?.prevPensionScheme,
            });
        }
    }, [
        selectedProductSelector,
        currentInvestmentApplicationSelector?.goals,
        currentInvestmentApplicationSelector?.isFullTransfer,
        currentInvestmentApplicationSelector?.prevPensionScheme,
        initialInvestmentAmount,
    ]);

    const onFieldChange = useCallback((field, validateCallback = validateInputString) => {
        const [fieldKey] = Object.keys(field);

        setPrevPensionScheme({
            ...prevPensionScheme,
            ...field,
        });
        setValidationPrevPensionScheme({
            ...validationPrevPensionScheme,
            ...{ [fieldKey]: validateCallback ? validateCallback(field[fieldKey]) : null },
        });
    }, [validationPrevPensionScheme, setValidationPrevPensionScheme, setPrevPensionScheme, prevPensionScheme]);

    const yAxisFormat = useCallback((num) => formatNumberRounding(num, getFormattedNumber),
        [getFormattedNumber]);

    const azpXAxisFormat = useCallback((context) => {
        if (!context) return '';
        const { isFirst, value } = context;

        if (isFirst) return `<b>${t('charts.years')}</b>`;
        const yearOffset = new Date(value).getFullYear() - new Date().getFullYear();

        return `${yearOffset >= 0 ? '+' : ''}${yearOffset}`;
    }, []);

    const azpYAxisFormat = useCallback((context) => {
        const { value } = context;

        return formatNumberRounding(value, getFormattedNumber);
    }, []);

    const tooltipFormat = useCallback(({ point, series }) => ReactDOMServer
        .renderToString(ProjectionTooltip({
            point, series, currency, t, getFormattedCurrency,
        })), [currency, getFormattedCurrency, t]);

    const optional = useMemo(() => ({
        ...projectionOptional(t, currency, yAxisFormat, tooltipFormat),
        ...(findMinAndMax([chartData])),
    }), [currency, t, yAxisFormat, chartData]);

    const onContinue = useCallback(async () => {
        try {
            const errors = errorModel({
                data: {
                    ...prevPensionScheme,
                    yearlyPayment,
                    transferValue,
                    rubrik,
                },
                showYearlyPayments: isThreeB ? false : productSettings?.showYearlyPayments,
                isThreeB,
                isAzp,
                isZic,
                isZifd,
            });

            if (!productSettings?.showYearlyPayments || transferValue > 0) {
                setValidationPrevPensionScheme(errors);
                if (IsAllDataNull(errors) === false) {
                    return;
                }
            }

            if (productSettings?.showYearlyPayments && validateInputNumberWithZero(yearlyPayment) !== null) {
                setValidationPrevPensionScheme(errors);

                return;
            }

            let periodicity = null;

            if (isThreeB) {
                periodicity = periodicPayment === 0 ? null : parseInt(paymentPeriod, 10);
            }

            const payload = {
                initialInvestment: isThreeB ? oneTimeInvestment : parseInt(transferValue, 10),
                periodicalPaymentAmount: isThreeB ? periodicPayment : null,
                periodicity,
                yearlyPayments: parseInt(yearlyPayment, 10),
                pensionScheme: isThreeB ? null : prevPensionScheme.nameOfPensionScheme,
                addressSuffix: prevPensionScheme.addressSupplement,
                street: prevPensionScheme.streetAndNumber,
                zip: prevPensionScheme.postcodeAndCity,
                policyNo: prevPensionScheme.policeNumber,
                bankAccountNo: prevPensionScheme.bankAccountNumber,
                rubrik: isThreeB ? rubrik : null,
            };

            if (showFullTransferQuestion) {
                payload.isFullTransfer = isFullTransfer === 'true';
            }

            const azpPayload = {
                initialInvestment: withdrawalAzpInvestmentAmount,
                yearlyPayments: 0,
                rubrik: isThreeB ? rubrik : null,
            };

            const result = await saveInvestmentApplicationDataAction({
                payload: isAzp ? azpPayload : payload,
                method: 'saveProductData',
                additionalData: {
                    goals: {
                        InstrumentAllocations: modelPortfolio.Positions.map(
                            ({ Allocation, Security: { Id } }) => ({
                                Allocation,
                                InstrumentId: Id,
                            }),
                        ),
                        InitialInvestmentAmount: isThreeB ? oneTimeInvestment : parseInt(transferValue, 10),
                        YearlyContributionAmount: isThreeB ? getYearlyContribution() : parseInt(yearlyPayment, 10),
                        CurrencyId: modelPortfolio.BaseCurrency.Id,
                        PortfolioId: modelPortfolioId,
                        projectionYears: projectionYears < 1 ? 0 : projectionYears,
                        plannedInvestment,
                    },
                    isFullTransfer: isFullTransfer === 'true',
                    prevPensionScheme,
                    chartOptions: optional,
                    currency: modelPortfolio.BaseCurrency,
                },
            });

            if (!result) return;

            stepComplete();
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setPageErrors,
                    setLoading,
                }),
            )(err);
        }
    }, [
        modelPortfolio,
        transferValue,
        yearlyPayment,
        optional,
        isFullTransfer,
        showFullTransferQuestion,
        prevPensionScheme,
        isThreeB,
        oneTimeInvestment,
        periodicPayment,
        paymentPeriod,
        isWithdrawal,
        productSettings?.showYearlyPayments,
        rubrik,
        getYearlyContribution,
        isAzp,
        withdrawalAzpInvestmentAmount,
        plannedInvestment,
    ]);

    const changeTransferValue = useCallback((value) => {
        clearTimeout(transferValueTimeoutId);
        if (!productSettings?.showYearlyPayments) {
            setValidationPrevPensionScheme({
                ...validationPrevPensionScheme,
                ...{ transferValue: validateInputNumberWithZero(value) },
            });
        }
        transferValueTimeoutId = setTimeout(() => {
            setTransferValue(value);
        }, INPUT_TIMEOUT);
    }, [validationPrevPensionScheme, productSettings?.showYearlyPayments]);

    const changeYearlyPayment = useCallback((value) => {
        clearTimeout(yearlyPaymentTimeoutId);
        setValidationPrevPensionScheme({
            ...validationPrevPensionScheme,
            ...{ yearlyPayment: validateInputNumberWithZero(value) },
        });
        yearlyPaymentTimeoutId = setTimeout(() => {
            setYearlyPayment(value);
        }, INPUT_TIMEOUT);
    }, [validationPrevPensionScheme]);

    const changeFullTransfer = useCallback((e) => {
        setFullTransfer(e?.target?.value);
    }, [isFullTransfer]);

    const getSelectStrategy = () => currentInvestmentApplicationSelector?.riskScore;

    useEffect(() => {
        if (modelPortfolioId) {
            (async () => {
                try {
                    setLoading(true);
                    const response = await ServiceManager.portfolioManagement(
                        'getModelPortfolio',
                        [modelPortfolioId, { language }],
                    );

                    setCurrency(response.data?.BaseCurrency?.CurrencyCode);
                    setModelPortfolio(response.data);
                } catch (err) {
                    handlerRequestCanceling(
                        HandlerError({
                            setError: setPageErrors,
                            setLoading,
                        }),
                    )(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [language, modelPortfolioId]);

    useEffect(() => {
        if (modelPortfolio && (
            (transferValue && !productSettings?.showYearlyPayments)
    || (productSettings?.showYearlyPayments && (yearlyPayment || transferValue)) || isThreeB)) {
            (async () => {
                const performance = {
                    language,
                    InstrumentAllocations: modelPortfolio.Positions.map(
                        ({ Allocation, Security: { Id } }) => ({
                            Allocation,
                            InstrumentId: Id,
                        }),
                    ),
                    InitialInvestmentAmount: isThreeB ? oneTimeInvestment : parseInt(transferValue, 10),
                    YearlyContributionAmount: isThreeB ? getYearlyContribution() : parseInt(yearlyPayment, 10),
                    CurrencyId: modelPortfolio.BaseCurrency.Id,
                    PortfolioId: modelPortfolioId,
                    projectionYears: projectionYears < 1 ? 0 : projectionYears,
                    individualPortfolio,
                    investmentApplicationId: currentInvestmentApplicationId,
                };

                getInvestmentProjection(performance).then((response) => {
                    setLoading(true);
                    const chartGraphData = response.data?.projections;
                    const AdditionalValueArray1 = chartGraphData[1]?.values.map((k, i) => ({
                        ...k, AdditionalValue: chartGraphData[0]?.values[i]?.value, value: k?.value, date: k?.date,
                    }));
                    const AdditionalValueArray2 = chartGraphData[2]?.values.map((k, i) => ({
                        ...k, AdditionalValue: chartGraphData[1]?.values[i]?.value, value: k?.value, date: k?.date,
                    }));

                    const chart = newAdaptProjectionData([
                        { Values: [...AdditionalValueArray1], ProbabilityPercentage: chartGraphData[1]?.stdDevFactor, SeriesType: 'InstrumentSet' },
                        { Values: [...AdditionalValueArray1], ProbabilityPercentage: chartGraphData[1]?.stdDevFactor, SeriesType: 'InstrumentSet' },
                        { Values: [...AdditionalValueArray2], ProbabilityPercentage: chartGraphData[2]?.stdDevFactor, SeriesType: 'InstrumentSet' },
                    ]);

                    const { bestReturn, averageReturn, worstReturn } = response?.data || {};

                    setReturnRates([
                        { name: t('projectionLegend.bestCase'), value: `${bestReturn > 0 ? '+' : '-'} ${Math.abs(bestReturn * 100)?.toFixed(2)}%` },
                        { name: t('projectionLegend.averageCase'), value: `${averageReturn > 0 ? '+' : '-'} ${Math.abs(averageReturn * 100)?.toFixed(2)}%` },
                        { name: t('projectionLegend.worstCase'), value: `${worstReturn > 0 ? '+' : '-'} ${Math.abs(worstReturn * 100)?.toFixed(2)}%` },
                    ]);

                    setChartData(chart);
                    setLoading(false);
                });
            })();
        }
    }, [modelPortfolioId, transferValue, yearlyPayment, modelPortfolio, language, productSettings?.showYearlyPayments, individualPortfolio, plannedInvestment, oneTimeInvestment, isThreeB, projectionYears, getYearlyContribution]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        continueDisabled: continueDisabledFromProps,
        navigationDisabled: isLoading,
        pageErrors,
    });

    return {
        isPaymentPeriodVisible,
        projectionOptions: optional,
        riskCategoryId,
        currency,
        riskCategories: riskCategoriesList,
        t,
        showFullTransferQuestion,
        modelPortfolio,
        productSettings,
        productName,
        isFullTransfer,
        radioFullTransferOptions,
        changeFullTransfer,
        isLoading,
        chartData,
        returnRates,
        transferValue,
        yearlyPayment,
        changeTransferValue,
        changeYearlyPayment,
        minMaxTransferValue,
        minMaxYearlyPayment,
        validation: validationPrevPensionScheme,
        prevPensionScheme,
        onFieldChange,
        getSelectStrategy,
        isThreeB,
        periodicPayment,
        oneTimeInvestment,
        onPeriodicPaymentChange,
        onOneTimePaymentChange,
        onPaymentPeriodChange,
        paymentPeriod,
        plannedInvestment,
        rubrik,
        setRubrik,
        projectionYears,
        onProjectionYearsChange,
        getInvestmentForNextSixMonths,
        isInvestmentAllowed,
        isProofOfAssetsRequired,
        azpXAxisFormat,
        azpYAxisFormat,
        setProjectionYearsValue,
        language,
        selectedProductDescSelector,
        selectedProductSelector,
        currentInvestmentDescriptionSelector,
        isZurichEmployee,
        minPeriodicPaymentFor3B,
        minOneTimeInvestmentFor3B,
    };
};
