import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withMainLayout } from 'hocs';
import Preloader from 'components/Preloader';
import NewWrapper from 'components/AtomicStructure/layouts/NewWrapper';
import { getUserRegisterId, deleteUserRegisterId } from 'pages/ClientOnboardingFlow/helpers/userRegisterId';

import { genarateDFSClientId } from 'utils';
import { CreatePortfolioManager } from 'datasource/CreatePortfolio';
import { PortfolioProcessLayoutManager } from 'datasource/PortfolioProcessLayout';
import { APPLICATION_CONTAINER_ID, INVESTOR_PROFILE_CONTACT_ID, PRODUCT_EXTERNAL_ID, QUALITY_CHECK_DATA } from 'constants/constants';
import './ConfirmationPage.css';

const ConfirmationPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const userRegisterId = getUserRegisterId();
    const dfsId = genarateDFSClientId(userRegisterId);

    const goToAction = () => {
        deleteUserRegisterId();
        sessionStorage.removeItem('Stepper');
        sessionStorage.removeItem(CreatePortfolioManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(PortfolioProcessLayoutManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
        sessionStorage.removeItem(QUALITY_CHECK_DATA);
        sessionStorage.removeItem(PRODUCT_EXTERNAL_ID);
        sessionStorage.removeItem(INVESTOR_PROFILE_CONTACT_ID);
        if (globalThis?.AppConfig?.ADVISORY_PROCESS_FEATURE_FLAG === 'true') {
            navigate(`/client/${dfsId}/portfolios/new-portfolio`, {
                state: null,
            });
        } else {
            navigate(`/client/${dfsId}/portfolios/new-portfolio-legacy`, {
                state: null,
            });
        }
    };

    const goBack = () => {
        deleteUserRegisterId();
        navigate(`/client/${dfsId}/dashboard`);
    };

    return (
        <NewWrapper
            stepNavBarActive={2}
            NavbarTurnOff
        >
            <div className="container newStyle newStyleContent confirmation-content">
                <Preloader isLoading={false} error={null}>
                    <div className="textCenter">
                        <span className="confirmIcon"/>
                        <h1 className="confirmTitle">{t('onboardingFlow.confirmOnboarding.thanks')}</h1>
                        <p className="confirmParagpraph">{t('onboardingFlow.confirmOnboarding.paragraph')}</p>
                    </div>
                    <div className="confirmActionsWrapper">
                        <button type="button" className="btn" onClick={() => goBack()} data-cy="go_to_overview">
                            {t('onboardingFlow.confirmOnboarding.back')}
                        </button>
                        <button
                            data-cy="create_new_portfolio"
                            type="button"
                            className="btn"
                            onClick={() => goToAction()}
                        >
                            {t('onboardingFlow.confirmOnboarding.next')}
                        </button>
                    </div>

                </Preloader>
            </div>
        </NewWrapper>
    );
};

export default withMainLayout(ConfirmationPage);
