import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import React, {useCallback, useEffect, useMemo, useState,} from 'react';
import {FormGroup, Label} from 'ui-library';
import {useFormatting} from '../../locale';
import './rc-slider.css';
import './RangeSlider.css';
import {getClassNames} from '../../utils';
import {unformatCurrency} from '../../utils/formatting';
import Error from '../AtomicStructure/atoms/Error';

const RangeSlider = ({
    htmlFor,
    label,
    labelInfobox,
    error,
    value,
    onChange,
    min,
    max,
    withInput,
    disabled,
    suffix,
    unit,
    showMinMax,
    enableZero,
}) => {
    const [val, setVal] = useState(value);
    const {getFormattedCurrency} = useFormatting();

    useEffect(() => {
        setVal(value);
    }, [value]);

    const debouncedSetMinValue = useCallback(
        debounce((sliderValue) => {
            if ((sliderValue < min && enableZero && sliderValue !== 0) || (!enableZero && sliderValue < min)) {
                setVal(min);
                onChange(min);
            }
        }, 1000),
        [min, enableZero],
    );

    const onChangeSlider = useCallback((sliderValue) => {
        const newVal = sliderValue > max ? max : sliderValue;

        setVal(newVal);
        onChange(newVal);

        debouncedSetMinValue(newVal);
    }, [min, max]);

    const displayValue = useMemo(() => {
        if (unit === 'years') {
            return `${val} years`;
        }

        return getFormattedCurrency(val, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    }, [val, unit]);

    return (
        <FormGroup>
            <Label
                htmlFor={htmlFor}
                label={label}
                labelInfobox
            />

            <div className="slider-wrapper">
                {!withInput
                    ? (
                        <div className="slider-value">
                            {displayValue}
                        </div>
                    ) : (
                        <div className="slider-value">
                            <span className={getClassNames('slider-input-wrapper', {'is-disabled': disabled})}>
                                {unit === 'currency' && <span className="slider-currency">CHF</span>}
                                <input
                                    disabled={disabled}
                                    className="TextInput slider-input"
                                    value={displayValue}
                                    style={
                                        {
                                            width: displayValue.length * 11 + 1,
                                        }
                                    }
                                    onChange={(e) => {
                                        const myVal = e.target.value;

                                        onChangeSlider(unformatCurrency(myVal));
                                    }}
                                />
                                {suffix}
                            </span>
                        </div>
                    )}

                <Slider
                    disabled={disabled}
                    min={min}
                    max={max}
                    onChange={onChangeSlider}
                    value={val}
                />
                {showMinMax && (
                    <div className="slider-minmax">
                        <span>
                            CHF
                            {' '}
                            {getFormattedCurrency(min, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}

                        </span>
                        <span>
                            CHF
                            {' '}
                            {getFormattedCurrency(max, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}

                        </span>
                    </div>
                )}
            </div>
            {error && (
                <Error>{error}</Error>
            )}
        </FormGroup>
    );
};

RangeSlider.propTypes = {
    suffix: PropTypes.string,
    htmlFor: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelInfobox: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    error: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    unit: PropTypes.oneOf(['years', 'currency']),
    showMinMax: PropTypes.bool,
};

RangeSlider.defaultProps = {
    suffix: '',
    htmlFor: null,
    error: null,
    labelInfobox: true,
    value: 0,
    onChange: () => {
    },
    min: 0,
    max: 100,
    disabled: false,
    unit: 'currency',
    showMinMax: false,
};

export default RangeSlider;
