import moment from 'moment';
import { dateFormatZurich } from 'utils/datetime';

export const convertDate = (date) => moment(date).format(dateFormatZurich);

export const getOldness = (date) => moment().diff(moment(date, dateFormatZurich), 'years');

export const changeMonthWithDay = (inputFormat) => {
    const splitInputFormat = inputFormat.split('.');

    return [splitInputFormat[2], splitInputFormat[1], splitInputFormat[0]].join('-');
};
