import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import use3bStepper from 'pages/ClientOverview/pages/Portfolios/hooks/use3bStepper';
import { getClientId } from 'utils';

import { validateIban } from 'utils/validation';
import HandlerError from '../../../../../../../../../errors/HandlerError';
import handlerRequestCanceling from '../../../../../../../../../utils/handlerRequestCanceling';
import { useCreatePortfolio } from '../../../context';
import { useThreeBProductType } from '../../../../../../../../../hooks/isThreeBProduct';
import { getModel } from '../models/getModel';
import { sendModel } from '../models/sendModel';
import { useWithdrawalPlanBase } from './useWithdrawalPlanBase';
import { UseWithdrawalPlanReturn } from '../types';

export const useWithdrawalPlan = (): UseWithdrawalPlanReturn => {
    const navigate = useNavigate();

    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);
    const baseUrl = useMemo(
        () => `/client/${dfsClientId}/portfolios/new-portfolio-legacy`,
        [dfsClientId],
    );

    const {
        newPortfolio,
        saveNewPortfolio,
        layout: { setData: setLayoutData, setPageErrors },
    } = useCreatePortfolio();
    const productSettings = useMemo(
        () => newPortfolio?.product?.settings,
        [newPortfolio?.product?.settings],
    );

    const investmentApplicationId: string | undefined = newPortfolio?.applicationData?.investmentApplicationId;

    const {
        isAzp, isPk, isZiv, isZifd, isAzpI, isAzpD,
    } = useThreeBProductType(newPortfolio);

    const { withdrawalStepNumber } = use3bStepper();
    const {
        data,
        setData,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        trigger,
        setErrorData,
        isRegularTransferPlan,
        setIsRegularTransferPlan,
        memberId,
        option,
        isAtLeastOneProductOpened,
        setLoading,
        isLoading,
        optionWithdrawalPeriodicity,
        isOptionsLoading,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setPlanCalculationLoading,
        control,
        handleSubmit,
        register,
    } = useWithdrawalPlanBase({
        isAzp,
        isPk,
        isZiv,
        isZifd,
        productSettings,
        modelPortfolioId:
            newPortfolio?.applicationData?.investmentDescription?.selectStrategy
                ?.modelPortfolioId,
        strategyId:
            newPortfolio?.applicationData?.investmentDescription?.selectStrategy
                ?.strategyId,
        investmentApplicationId,
        setPageErrors,
        productExternalId: newPortfolio?.product?.externalId,
        productConfiguration: newPortfolio?.applicationData?.investmentDescription?.productConfiguration,
    });

    useEffect(() => {
        const firstName = newPortfolio?.userClientName?.split(' ')[0];
        const lastName = newPortfolio?.userClientName?.split(' ')[1];

        const adaptedData = getModel(
            newPortfolio?.applicationData?.investmentDescription,
            { firstName, lastName, isAzp },
        );

        setData(adaptedData);
    }, [newPortfolio.userClientName, isAzp]);

    const onContinue = useCallback(
        async (dta) => {
            try {
                const ibanError = await validateIban(dta?.iban);

                if (ibanError) {
                    setErrorData('iban', {
                        message: ibanError as string,
                    });

                    return;
                }

                const result = await saveNewPortfolio({
                    data: {
                        investmentApplicationId,
                        ...sendModel({
                            data: dta,
                            isAzp,
                            ...(withdrawalPlanData || {}),
                        }),
                    },
                    method: 'saveWithdrawalData',
                });

                if (!result) return;
                navigate(
                    `${baseUrl}/${isAzp ? 'goal-setup' : 'fees-overview'}`,
                );
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading,
                    }),
                )(err);
            }
        },
        [
            contactId,
            memberId,
            isAzp,
            withdrawalPlanData,
            isRegularTransferPlan,
            setPageErrors,
            investmentApplicationId,
        ],
    );

    const onBack = useCallback(() => {
        navigate(`${baseUrl}/${isAzp ? 'strategy-selection' : 'goal-setup'}`);
    }, [isAzp]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: withdrawalStepNumber,
            onBack,
            onContinue:
                handleSubmit && typeof handleSubmit === 'function'
                    ? handleSubmit(onContinue)
                    : onContinue,
            disabled: false,
        });
    }, [onBack, onContinue, withdrawalStepNumber]);

    return {
        data,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        isRegularTransferPlan,
        optionWithdrawalPeriodicity,
        isLoading,
        isOptionsLoading,
        productExternalId: newPortfolio?.product?.externalId,
        productDesc: newPortfolio?.product?.description,
        isAzp,
        isPk,
        isZiv,
        isZifd,
        isAzpI,
        isAzpD,
        calculateWithdrawalPlan,
        onContinue,
        onBack,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setData,
        setErrorData,
        trigger,
        option,
        isAtLeastOneProductOpened,
        setIsRegularTransferPlan,
        setPlanCalculationLoading,
        control,
        register,
    };
};
