import {useNavigate, useParams} from 'react-router-dom';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useSelector as useReduxSelector} from 'react-redux';
import Preloader from 'components/Preloader';
import {useClientProfile} from 'prodivers/clientProfile';
import {Title} from 'ui-library';
import {getClassNames, getClientId} from 'utils';
import {useContact} from 'domain/ClientService/hooks/useContact';
import {memberIdSelector} from 'redux-store/auth/authSelectors';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import {PRODUCTS} from 'constants/constants';
import ServiceManager from 'services/ServiceManager';
import {ProductOffersSelection} from '../../../../../../components/product-offers-selection';
import useCommonAllocationDataProvider from '../../context/useCommonAllocationDataProvider.';
import {getRestrictedClientMessage} from '../../../../utils/utils';
import useCreatePortfolio from './context/useCreatePortfolio';
import {ThreeBGroupsModal} from '../../../../../../components/three-b-groups-modal/ThreeBGroupsModal';

import './CreateNewPortfolio.css';

function CreateNewPortfolio() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedProductId, setSelectedProductId] = useState(0);
    const [showThreeBGroupsModal, setShowThreeBGroupsModal] = useState(false);
    const [getContactGroupsInProgress, setGetContactGroupsInProgress] = useState(false);
    const [contactGroups, setContactGroups] = useState([]);
    const [checkIfParentalAuthorityAvailableInProgress, setCheckIfParentalAuthorityAvailableInProgress] = useState(false);
    const [parentalAuthorityError, setParentalAuthorityError] = useState(null);

    const {
        newPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors },
        clearNewPortfolio, isFzAndFzpAlreadyApplied, checkIfNationalityUnderFatfGrayList,
        isLoadingProducts,
        groupedProducts,
        errorProducts,
        getProducts,
    } = useCreatePortfolio();
    const {dfsClientId} = useParams();
    const clientId = getClientId(dfsClientId);

    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio-legacy`, [dfsClientId]);
    const [data, setData] = useState({});
    const [disableContinueButton, setDisableContinueButton] = useState(false);
    const memberId = useReduxSelector(memberIdSelector);
    const {setSelectedProductType, selectedProductType} = useCommonAllocationDataProvider();

    // Client Provider
    const { ...information } = useClientProfile();

    const clientFitFailMessage = useMemo(() => getRestrictedClientMessage({data, t, selectedProductType}), [data, selectedProductId, groupedProducts, selectedProductType]);

    const checkIfParentalAuthorityAvailable = useCallback(async () => {
        try {
            setCheckIfParentalAuthorityAvailableInProgress(true);
            const response = await ServiceManager.customClientService('isParentalAuthorityAvailable', [clientId]);

            if (!response.data) {
                setParentalAuthorityError(t('portfolio.createNewPortfolio.3bParentalAuthorityRequired'));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setCheckIfParentalAuthorityAvailableInProgress(false);
        }
    }, [t]);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        clearNewPortfolio();
    }, []);

    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    useEffect(() => {
        setSelectedProductId(newPortfolio?.product?.id);
    }, [newPortfolio]);

    useEffect(() => {
        setDisableContinueButton(!!clientFitFailMessage);
    }, [clientFitFailMessage]);

    useEffect(() => {
        if (selectedProductId) {
            const selectedProduct = groupedProducts?.find(({id}) => (selectedProductId === id));

            setSelectedProductType(selectedProduct?.settings?.productKey?.toLowerCase());
        }
    }, [selectedProductId, groupedProducts]);

    useEffect(() => {
        if (selectedProductType === PRODUCTS.bbb && data?.userClientAge < 18 && !clientFitFailMessage) {
            (async () => checkIfParentalAuthorityAvailable())();
        } else {
            setParentalAuthorityError(null);
        }
    }, [selectedProductType, checkIfParentalAuthorityAvailable, clientFitFailMessage]);

    const handleOnThreeBConnectionGroupSelected = useCallback(async (groupId, isJointAccount) => {
        const selectedProduct = groupedProducts?.find(({id}) => (selectedProductId === id));
        const {userClientName} = data;
        const userClientAge = parseInt(data.userClientAge, 10);

        const additionalData = {
            product: selectedProduct,
            userClientName,
            userClientAge,
            connectionGroupId: groupId,
            isJointAccount,
        };

        await saveNewPortfolio({data: null, method: null, additionalData});

        navigate(`${baseUrl}/advisory-document-init`);
    }, [selectedProductId, data]);

    const handle3bSelectedProduct = useCallback(async () => {
        try {
            setGetContactGroupsInProgress(true);
            const result = await ServiceManager.customClientService('getContactGroups', [{
                contactId: clientId,
                createThreeB: true,
            }]);

            setContactGroups(result.data);

            if (result?.data?.length > 1) {
                setShowThreeBGroupsModal(true);
            } else {
                setGetContactGroupsInProgress(false);
                await handleOnThreeBConnectionGroupSelected(result.data?.[0]?.groupId, false);
            }
        } finally {
            setGetContactGroupsInProgress(false);
        }
    }, [handleOnThreeBConnectionGroupSelected]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        if (!disableContinueButton) {
            try {
                const selectedProduct = groupedProducts?.find(({id}) => (selectedProductId === id));
                const is3bProduct = selectedProduct.settings.productKey?.toLowerCase() === PRODUCTS.bbb;

                if (is3bProduct) {
                    await handle3bSelectedProduct(selectedProduct);

                    return;
                }

                const payload = {
                    productId: selectedProductId,
                    contactId: clientId,
                    memberId,
                    productExternalId: selectedProduct.externalId,
                };

                const additionalData = {
                    product: selectedProduct,
                    userClientName: data.userClientName,
                    userClientAge: parseInt(data.userClientAge, 10),
                };

                // saveNewPortfolio({...newPortfolio, ...payload});
                const result = await saveNewPortfolio({data: payload, method: 'saveProductDetails', additionalData});

                if (!result) return;

                navigate(`${baseUrl}/personal-details`);
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading,
                    }),
                )(err);
            }
        }
    }, [disableContinueButton, groupedProducts, selectedProductId, clientId, memberId, data.userClientName, data.userClientAge, handle3bSelectedProduct]);
    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/dashboard`);
        clearNewPortfolio();
    }, []);

    const { getContact, error: contactError } = useContact({ contactId: clientId });

    // TO DO: Create Hook or Move to Hook
    useEffect(() => {
        /* Get user data */
        if (clientId) {
            setLoading(true);
            getContact({
                contactId: clientId,
            }).then((contactDataForm) => {
                const userClientAge = moment(moment().format('YYYY-MM-DD')).diff(moment(contactDataForm?.details?.personalInformation?.dateOfBirth), 'years', true);
                const userClientName = `${contactDataForm?.details?.personalInformation?.firstName} ${contactDataForm?.details?.personalInformation?.lastName}`;
                const countryOfResidence = contactDataForm?.details?.communicationMethods?.primaryAddressCountry;
                const taxData = contactDataForm?.details?.taxSituation;
                const gender = contactDataForm?.details?.personalInformation?.gender;
                const nationality = contactDataForm?.details?.personalInformation?.identification?.[0]?.countryOfNationality;

                sessionStorage.setItem('userClientAgeExact', String(userClientAge));

                const jsUsers = {
                    clientName: userClientName,
                    clientAge: String(parseInt(userClientAge, 10)),
                    jaClientName: undefined,
                    jaClientAge: undefined,
                };

                setLayoutData(jsUsers);

                setData({
                    userClientName,
                    userClientAge,
                    mainOwner: {
                        name: jsUsers.clientName,
                        age: jsUsers.clientAge,
                    },
                    jaClient: undefined,
                    countryOfResidence,
                    taxData,
                    gender,
                    nationality,
                });
            }).catch((err) => {
                setPageErrors(err.message);
            }).finally(() => setLoading(false));
        }
    }, [clientId]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 1,
            onBack,
            onContinue,
        });
    }, [onBack, onContinue]);

    useEffect(() => {
        setPageErrors(
            contactError || errorProducts,
        );
    }, [contactError, errorProducts]);

    useEffect(() => {
        setLayoutData({
            disabled: !selectedProductId || disableContinueButton || getContactGroupsInProgress || checkIfParentalAuthorityAvailableInProgress || parentalAuthorityError,
            inProgress: getContactGroupsInProgress || checkIfParentalAuthorityAvailableInProgress,
        });
    }, [selectedProductId, disableContinueButton, getContactGroupsInProgress, checkIfParentalAuthorityAvailableInProgress, parentalAuthorityError]);

    const infoLabel = useMemo(() => {
        if (clientFitFailMessage || parentalAuthorityError || checkIfNationalityUnderFatfGrayList(data?.nationality)) {
            return <div className={`info-label ${clientFitFailMessage ? 'error' : 'warning'}`}>{clientFitFailMessage || parentalAuthorityError || t(checkIfNationalityUnderFatfGrayList(data?.nationality))}</div>;
        }

        return null;
    }, [clientFitFailMessage, parentalAuthorityError, checkIfNationalityUnderFatfGrayList, data, t]);

    return (
        <div className="new-portfolio">
            <Preloader
                isLoading={information?.isLoading || isLoadingProducts || isLoading}
                error={errorProducts}
            >
                <div>
                    <header className="new-portfolio-header">
                        <Title type={2}>{t('portfolio.createNewPortfolio.selectProductOffer')}</Title>
                    </header>
                    <div className={getClassNames('new-portfolio-content')}>
                        {infoLabel}
                        <ProductOffersSelection
                            isFzAndFzpAlreadyApplied={isFzAndFzpAlreadyApplied}
                            productOffers={groupedProducts}
                            selectProductOfferId={selectedProductId}
                            onSelectedProductOfferChange={(id) => setSelectedProductId(id)}
                        />
                    </div>
                </div>
            </Preloader>
            <ThreeBGroupsModal show={showThreeBGroupsModal} onClose={() => setShowThreeBGroupsModal(false)} groups={contactGroups} onSelect={handleOnThreeBConnectionGroupSelected}/>
        </div>
    );
}

export default CreateNewPortfolio;
