import React from 'react';
import { checkNullData, genarateDFSClientId } from 'utils';
import { get } from 'lodash/fp';
import { NA } from 'utils/formatting';
import { SkeletonLine } from '../../../ui-library';
import { ClientSchemaManager } from '../utils/ClientSchemaManager';

const translationCommunicationMap = {
    additionalChannelToUsePortal: 'additionalCommunicationMethods.additionalCommunicationChannelToUse.portal',
    additionalChannelToUseEmail: 'additionalCommunicationMethods.additionalCommunicationChannelToUse.email',
    additionalChannelToUsePaper: 'additionalCommunicationMethods.additionalCommunicationChannelToUse.paper',
};

const getWithDefault = (obj = {}, path = '', formatting = (v) => v) => {
    const value = get(path, obj);

    if (![undefined, null].includes(value)) return formatting(get(path, obj));

    return NA;
};

const findOptionById = (optionsList, id) => (optionsList?.find((item) => ((item?.id)) === id));

export const adaptClientList = ({
    navigate,
    data = {},
    t,
    columns = [],
    isLoading,
    getFormattedDate,
    optionGender, optionLanguage, optionCountry,
    pageSize = 10,
}) => {
    if (isLoading) {
        return new Array(pageSize).fill(null).map(() => (
            columns.map(({ key, render }) => {
                if (render) {
                    return ({
                        [key]: {
                            label: <SkeletonLine width="100px" />,
                            render: <SkeletonLine width="100%" />,
                        },
                    });
                }

                return ({ [key]: <SkeletonLine width="100%" /> });
            }).reduce((a, i) => ({ ...a, ...i }), {})
        ));
    }

    if (checkNullData(data)) return [];

    const clientOverview = ({ internalId, clientName }) => () => {
        navigate(`/client/${internalId}`, { clientName, isProspect: false });
    };

    const onBoarding = ({ internalId, clientName }) => () => {
        navigate(`/onboarding/${internalId}`, { clientName, isProspect: true });
    };

    const dataPath = ClientSchemaManager.getDataPathByKey(columns);

    return data.map((item) => {
        const internalId = genarateDFSClientId(item.id);
        const clientName = `${getWithDefault(item, 'firstName')} ${getWithDefault(item, 'lastName')}`;
        const isProspect = false;

        const communication = ['additionalChannelToUsePortal', 'additionalChannelToUseEmail', 'additionalChannelToUsePaper'].reduce(
            (accu, communicationPath) => {
                if (get(communicationPath, item)) {
                    accu.push(t(translationCommunicationMap[communicationPath]));
                }

                return accu;
            },
            [],
        ).join(', ');

        return {
            id: item.id,
            primaryEmail: getWithDefault(item, 'email'),
            addressLineThree: getWithDefault(item, 'street'),
            postcode: getWithDefault(item, 'postCode'),
            location: getWithDefault(item, 'city'),
            dateOfBirth: getFormattedDate(getWithDefault(item, 'birthDate')),
            language: getWithDefault(findOptionById(optionLanguage, parseInt(item.language, 10)), 'label'),
            ahvNo: getWithDefault(item, 'ahvNo'),
            gender: getWithDefault(findOptionById(optionGender, item.gender), 'label'),
            communication: communication.length === 0 ? NA : communication,
            primaryPhoneNumber: getWithDefault(item, 'primaryPhoneNumber'),
            internalId: {
                label: internalId,
                link: `/client/${internalId}`,
                state: { clientName, isProspect },
            },
            externalId: getWithDefault(item, 'customerNumber'),
            name: clientName,
            nationality: getWithDefault(findOptionById(optionCountry, item.countryOfNationality), 'label'),
            country: getWithDefault(findOptionById(optionCountry, item.country), 'label'),

            actions: {
                value: 'action',
                actions: [
                    {
                        text: isProspect ? t('clients.onBoardingToWealth') : t('clients.clientOverview'),
                        onClick: isProspect
                            ? onBoarding({ internalId, clientName })
                            : clientOverview({ internalId, clientName }),
                    },
                ],
            },
        };
    });
};
