import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFzAndFzpEnabled } from '../hooks/rest/useGetFzAndFzpEnabled';
import { useClientProfile } from '../prodivers/clientProfile';
import { useCreatePortfolioActions, useCreatePortfolioSelectors, useCreatePortfolioState } from './CreatePortfolio';
import { useProductsList } from '../hooks/useProducts';
import { useGetCheckIfParentalAuthority } from '../hooks/rest/useGetCheckIfParentalAuthority';
import { OPTION_LIST } from '../constants/constants';
import { usePortfolioProcessLayoutActions } from './PortfolioProcessLayout/usePortfolioProcessLayoutActions';
import { useOptionList } from '../hooks/useOptionList';
import { usePortfolioProcessNavigation } from '../hooks/helpers/usePortfolioProcessNavigation';
import { useGetContactGroups } from '../hooks/rest/useGetContactGroups';
import { getRestrictedClientMessage } from '../widgets/ProductOffers/utils';

export const useProductOffers = () => {
    const { ...information }: any = useClientProfile();
    const { clientId } = useClientProfile();

    const { i18n: { language } } = useTranslation();

    const { changePlan } = useCreatePortfolioState();

    const {
        isPageLoadingSelector,
        pageErrorsSelector,
        currentClientSelector,
        selectedProductGroupSelector,
        selectedProductTypeSelector,
        is3bProductGroupSelector,
        selectedProductGroupIdSelector,
    } = useCreatePortfolioSelectors();
    const {
        setSelectedProductIdAction,
        setConnectionGroupIdAction,
        saveInvestorProfileClientAction,
    } = useCreatePortfolioActions();
    const {
        continueLoadingAction,
    } = usePortfolioProcessLayoutActions();
    const [showThreeBGroupsModal, setShowThreeBGroupsModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedMainInvestor, setSelectedMainInvestor] = useState<number | null>(clientId as number);
    const { data: contactGroups, refetch: contactGroupsRefetch } = useGetContactGroups({ contactId: clientId });

    const { lists: [fatfGreylist, countries] } = useOptionList([OPTION_LIST.fatfGreylist, OPTION_LIST.country]);
    const { data: isFzAndFzpAlreadyApplied } = useGetFzAndFzpEnabled({ clientId });

    const clientFitFailMessage = useMemo(
        () => getRestrictedClientMessage({ data: currentClientSelector, selectedProductType: selectedProductTypeSelector }),
        [currentClientSelector, selectedProductTypeSelector],
    );

    const {
        data: isParentalAuthorityAvailable,
        isLoading: isParentalAuthorityLoading,
        error: getParentalAuthorityErrors,
    } = useGetCheckIfParentalAuthority({
        clientId,
        isEnabled: (is3bProductGroupSelector && currentClientSelector && currentClientSelector.age < 18 && !clientFitFailMessage),
    });

    const parentalAuthorityErrorMessage = useMemo(() => (
        isParentalAuthorityAvailable === false ? 'portfolio.createNewPortfolio.3bParentalAuthorityRequired' : undefined
    ), [isParentalAuthorityAvailable]);

    const nationalityValidation = useMemo(() => {
        const countryOfNationality = countries?.find(f => f.id === currentClientSelector?.nationality);

        if (fatfGreylist?.some(s => s.label === countryOfNationality?.label && is3bProductGroupSelector)) {
            return 'portfolio.createNewPortfolio.nationalityOnFatfGrayList';
        }

        return null;
    }, [countries, fatfGreylist, is3bProductGroupSelector, currentClientSelector, language]);

    const infoLabelData = useMemo(() => {
        if (!!clientFitFailMessage || !!parentalAuthorityErrorMessage || !!nationalityValidation) {
            return {
                isError: !!clientFitFailMessage,
                message: clientFitFailMessage || parentalAuthorityErrorMessage || nationalityValidation,
            };
        }

        return null;
    }, [clientFitFailMessage, parentalAuthorityErrorMessage, nationalityValidation, language]);

    // Effects
    useEffect(() => {
        continueLoadingAction(isParentalAuthorityLoading);
    }, [isParentalAuthorityLoading]);

    const {
        isLoadingProducts,
        groupedProducts,
        errorProducts,
        getProducts,
    } = useProductsList(clientId);

    const isLoading = useMemo(() => (information?.isLoading || isLoadingProducts || isPageLoadingSelector), [information?.isLoading || isLoadingProducts || isPageLoadingSelector]);
    const pageErrors = useMemo(() => (errorProducts || pageErrorsSelector || getParentalAuthorityErrors), [errorProducts || pageErrorsSelector || getParentalAuthorityErrors]);
    const continueInProgress = useMemo(() => (isParentalAuthorityLoading || saving), [isParentalAuthorityLoading || saving]);
    const continueDisabled = useMemo(
        () => (!selectedProductGroupIdSelector || selectedProductGroupIdSelector === -1 || !!clientFitFailMessage || continueInProgress || isLoading || !!pageErrors),
        [selectedProductGroupIdSelector, clientFitFailMessage, continueInProgress, pageErrors, isLoading],
    );

    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    const onThreeBConnectionGroupSelected = useCallback((groupId: string, isJa?: boolean) => {
        if (isJa) {
            if (selectedMainInvestor) {
                saveInvestorProfileClientAction(selectedMainInvestor);
            } else {
                saveInvestorProfileClientAction(clientId as number);
            }
        }
        setConnectionGroupIdAction(groupId);

        stepComplete();
    }, [selectedProductGroupSelector, selectedMainInvestor, clientId]);

    const handle3bSelectedProduct = useCallback(async () => {
        const { data } = await contactGroupsRefetch();

        if (data?.length > 1) {
            setShowThreeBGroupsModal(true);
        } else {
            onThreeBConnectionGroupSelected(data?.[0]?.groupId);
        }
    }, [onThreeBConnectionGroupSelected]);

    const onContinue = useCallback(async () => {
        setSaving(true);
        (is3bProductGroupSelector ? handle3bSelectedProduct : stepComplete)();
        setSaving(false);
    }, [is3bProductGroupSelector, handle3bSelectedProduct]);

    const { stepComplete, setStep } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        pageErrors,
        continueInProgress,
        continueDisabled,
    });

    const handleChangePlanFlow = useCallback(async () => {
        const { data } = await contactGroupsRefetch();

        setConnectionGroupIdAction(data?.[0]?.groupId);
        setStep({
            stepKey: 'AdvisoryTypeSelection',
        });
    }, [contactGroupsRefetch, setStep, setConnectionGroupIdAction]);

    useEffect(() => {
        if (changePlan) {
            handleChangePlanFlow();
        }
    }, [changePlan, handleChangePlanFlow]);

    return {
        clientId,
        isLoading,
        pageErrors,
        groupedProducts,
        isFzAndFzpAlreadyApplied,
        infoLabelData,
        onContinue,
        setSelectedProductId: setSelectedProductIdAction,
        selectedProductId: selectedProductGroupIdSelector,
        onThreeBConnectionGroupSelected,
        contactGroups,
        showThreeBGroupsModal,
        setShowThreeBGroupsModal,
        onChangeInvestorProfileClient: (_clientId) => setSelectedMainInvestor(parseInt(_clientId, 10)),
    };
};
