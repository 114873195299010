import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {memberIdSelector} from 'redux-store/auth/authSelectors';
import {getClientId} from 'utils';
import ServiceManager from 'services/ServiceManager';
import {Loader} from 'ui-library';
import use3bStepper from 'pages/ClientOverview/pages/Portfolios/hooks/use3bStepper';
import {useNavigate} from 'react-router-dom';
import {isThreeBProduct} from 'hooks/isThreeBProduct';
import {useCreatePortfolio} from '../../../context';
import {validate} from '../validation';
import {IsAllDataNull, validateSelect} from '../../../../../../../../../validation/validation';
import {
    STATUS,
} from '../../../../../../../../../components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';

export const useFinalization = ({ dfsClientId }) => {
    const navigate = useNavigate();
    const { i18n: { language }, t } = useTranslation();
    const {newPortfolio, layout: { setData: setLayoutData, setPageErrors }, clearNewPortfolio} = useCreatePortfolio();
    const isThreeB = isThreeBProduct(newPortfolio);
    const {openingStepNumber} = use3bStepper();
    const [formData, setFormData] = useState({
        isConsultationConducted: false,
        transactionByExternalReferrer: false,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const clientId = getClientId(dfsClientId);
    const memberId = useSelector(memberIdSelector);
    const [error, setError] = useState(undefined);

    const onContinue = useCallback(async () => {
        setIsSubmitted(true);
        const errors = validate(formData);

        setError(errors);

        if (IsAllDataNull(errors) === false) {
            return;
        }

        try {
            setPageErrors();
            const investmentApplicationId = newPortfolio?.applicationData?.investmentApplicationId;

            setSending(true);

            const { data: planAppData } = await ServiceManager.customInvestmentService('getInvestmentApplication', [{investmentApplicationId}]);

            if (planAppData.status !== STATUS.in_progress) {
                setPageErrors(t('portfolio.createNewPortfolio.opening.applicationHasAlreadyCompleted'));

                return;
            }

            await ServiceManager.customInvestmentService(
                'finalizeNewPortfolio',
                [{
                    ...formData,
                    investmentApplicationId,
                    documentLanguage: formData.documentLanguage,
                    isConsultationConducted: formData.isConsultationConducted,
                    transactionByExternalReferrer: formData.transactionByExternalReferrer,
                }],
            );

            // call iban reservation only if single app
            if (!newPortfolio?.applicationData?.investmentDescription?.containerId) {
                await ServiceManager.customInvestmentService('makeIbanReservation', [{investmentApplicationId}]);
            }

            if (!isThreeB) {
                await ServiceManager.customInvestmentService('generateDocuments', [{investmentApplicationId}]);
            }

            clearNewPortfolio();
            navigate(`/client/${dfsClientId}/dashboard`);
        } catch (err) {
            setPageErrors(err.message);
        } finally {
            setSending(false);
            setIsSubmitted(false);
        }
    }, [language, clientId, memberId, formData, isThreeB]);
    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/${isThreeB ? 'overview' : 'quality-check'}`);
    }, []);

    const yesNoOption = useMemo(() => ([
        { label: t('clientDashboard.yes'), id: true },
        { label: t('clientDashboard.no'), id: false },
    ]), []);

    const languagesOption = useMemo(() => ([
        { label: 'DE', id: 'de' },
        { label: 'FR', id: 'fr' },
        { label: 'IT', id: 'it' },
        { label: 'EN', id: 'en' },
    ]), []);

    const passData = useCallback((value, key) => {
        setError({
            ...error,
            [key]: validateSelect(value),
        });
        setFormData({
            ...formData,
            [key]: value,
        });
    }, [formData]);

    useEffect(() => {
        setFormData({
            ...formData,
            isConsultationConducted: false,
            transactionByExternalReferrer: false,
            documentLanguage: language.toLocaleLowerCase(),
        });
    }, [language]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: isThreeB ? openingStepNumber : 9,
            onBack,
            onContinue,
            disabled: false,
            inProgress: false,
        });
    }, [onBack, onContinue, isThreeB, openingStepNumber]);

    useEffect(() => {
        setLayoutData({
            continueLabel: isSending ? <Loader /> : t('portfolio.createNewPortfolio.opening.generateRequest'),
            saveInProgress: isSending,
        });

        return () => {
            setLayoutData({
                continueLabel: undefined,
            });
        };
    }, [isSending]);

    return {
        t,
        onBack,
        onContinue,
        yesNoOption,
        languagesOption,
        passData,
        isSubmitted,
        isSending,
        data: formData,
        error,
    };
};
