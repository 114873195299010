import { noop } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import { OPTION_LIST } from '../../constants/constants';
import { useOptionList } from '../../hooks/useOptionList';

import './MailingAddress.css';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';
import { FIELD_LENGTH } from '../../pages/ClientOverview/constants';
import {
    Button,
    Column,
    FormGroup,
    Icon,
    Infobox,
    Loader,
    Modal,
    notification,
    Row,
    Select,
    TextInput,
} from '../../ui-library';
import { ICON_TYPE_DELETE, ICON_TYPE_EDIT, ICON_TYPE_PLUS_BUTTON } from '../../ui-library/components/Icon';
import { checkNullData } from '../../utils';
import { validateMultipleInputsLength } from '../../validation/validation';
import Preloader from '../Preloader';

export const TARGET_FIELDS = [
    'mailRecipientGender',
    'mailRecipientDateOfBirth',
    'mailRecipientAddressLine1',
    'mailRecipientLanguage',
    'mailRecipientAddressLine5',
    'mailRecipientFirstName',
    'mailRecipientLastName',
    'mailRecipientAddressLine2',
    'mailRecipientAddressLine3',
    'mailRecipientAddressLine4',
    'mailRecipientAddressCountry',
];

function MailingAddress({
    initData,
    onSubmit,
    required = false,
    error,
}) {
    const { t } = useTranslation();
    const [isVisible, setVisible] = useState(false);
    const [isDataSending, setDataSending] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [generalError, setGeneralError] = useState();

    const onAdd = useCallback(() => {
        setVisible(true);
    }, []);

    const {
        lists: [optionGender, optionCountry, optionLanguage],
        isLoading: isOptionsLoading,
    } = useOptionList([OPTION_LIST.gender, OPTION_LIST.country, OPTION_LIST.language]);

    const optionGenderAdapted = useMemo(() => (
        optionGender?.map(({
            id,
            label,
        }) => ({
            value: id,
            label,
        })) ?? []
    ), [optionGender]);

    const optionCountryAdapted = useMemo(() => (
        optionCountry?.map(({
            id,
            label,
        }) => ({
            value: id,
            label,
        })) ?? []
    ), [optionCountry]);

    const optionLanguageAdapted = useMemo(() => (
        optionLanguage?.map(({
            id,
            label,
        }) => ({
            value: id,
            label,
        })) ?? []
    ), [optionLanguage]);

    /* get only target fields */
    const initDataAdapted = useMemo(() => (
        TARGET_FIELDS.reduce((accu, fieldName) => {
            accu[fieldName] = initData?.[fieldName];

            return accu;
        }, {})
    ), [initData]);

    const isDataAvailable = useMemo(() => (
        !!TARGET_FIELDS.find((fieldName) => !checkNullData(initData?.[fieldName]))
    ), [initData]);

    const schema = useMemo(() => object()
        .shape({
            mailRecipientGender: number()
                .typeError(t('validation.mandatoryField'))
                .required(t('validation.mandatoryField')),
            mailRecipientAddressCountry: number()
                .typeError(t('validation.mandatoryField'))
                .required(t('validation.mandatoryField')),
            mailRecipientLanguage: number()
                .typeError(t('validation.mandatoryField'))
                .required(t('validation.mandatoryField')),
            mailRecipientAddressLine5: string()
                .nullable()
                .required(t('validation.mandatoryField'))
                .max(FIELD_LENGTH.city, t('onboardingFlow.personalPage.validateInputLength', { length: FIELD_LENGTH.city })),
            mailRecipientFirstName: string()
                .nullable()
                .max(FIELD_LENGTH.firstName, t('onboardingFlow.personalPage.validateInputLength', { length: FIELD_LENGTH.firstName }))
                .required(t('validation.mandatoryField')),
            mailRecipientLastName: string()
                .nullable()
                .max(FIELD_LENGTH.lastName, t('onboardingFlow.personalPage.validateInputLength', { length: FIELD_LENGTH.lastName }))
                .required(t('validation.mandatoryField')),
            mailRecipientAddressLine1: string()
                .nullable()
                .max(FIELD_LENGTH.street, t('onboardingFlow.personalPage.validateInputLength', { length: FIELD_LENGTH.street })),
            mailRecipientAddressLine2: string()
                .nullable(),
            mailRecipientAddressLine3: string()
                .nullable()
                .required(t('validation.mandatoryField')),
            mailRecipientAddressLine4: number()
                .typeError(t('onboardingFlow.personalPage.validationNumber'))
                .required(t('validation.mandatoryField')),
        }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        register,
        reset,
        setError,
    } = useForm({
        resolver,
        defaultValues: initDataAdapted,
    });

    const data = watch();

    const onOk = useCallback(async () => {
        try {
            setDataSending(true);
            const isLengthValid = validateMultipleInputsLength(FIELD_LENGTH.firstAndLastNameCombined, 'clientValidation.firstAndLastNameLengthCombined', data.mailRecipientFirstName, data.mailRecipientLastName);

            if (isLengthValid) {
                setGeneralError(t(isLengthValid, { max: FIELD_LENGTH.firstAndLastNameCombined }));
                setError('mailRecipientFirstName', { message: ' ' });
                setError('mailRecipientLastName', { message: ' ' });

                return;
            }
            setGeneralError(null);
            setError('mailRecipientFirstName', { message: '' });
            setError('mailRecipientLastName', { message: '' });

            await onSubmit(data);

            setVisible(false);

            reset(data);
        } catch (e) {
            setGeneralError(t('advisoryDashboard.error'));
        } finally {
            setDataSending(false);
        }
    }, [data, t, setError]);

    const deleteMailAddress = useCallback(async () => {
        try {
            setDeleting(true);
            await onSubmit({}, 1);
            reset({});
        } catch (e) {
            notification.open({
                content: t('MailingAddress.deleteMailingAddress.error'),
                type: 'error',
            });
        } finally {
            setDeleting(false);
        }
    }, [data]);

    const handleDelete = useCallback(() => {
        Modal.confirm({
            title: t('MailingAddress.confirm.delete.title'),
            content: t('MailingAddress.confirm.delete.content'),
            okText: t('confirmation.confirm'),
            onOk: deleteMailAddress,
            cancelText: t('confirmation.cancel'),
        });
    }, [data]);

    const onCancel = useCallback(() => {
        setGeneralError(undefined);
        setVisible(false);
    }, []);

    const presentationalDataElement = useMemo(() => (
        isDataAvailable
            ? (
                <p>
                    {`${initDataAdapted?.mailRecipientAddressLine3 ?? ''}, ${initDataAdapted?.mailRecipientAddressLine5 ?? ''}, ${(optionCountry?.find(({ id }) => initDataAdapted.mailRecipientAddressCountry === id))?.label ?? ''}`}
                    {' '}
                    {isDeleting ? (
                        <Loader/>
                    ) : (
                        <Button type="link" onClick={handleDelete}>
                            <Icon type={ICON_TYPE_DELETE}/>
                        </Button>
                    )}
                </p>
            )
            : (<></>)
    ), [isDataAvailable, isDeleting, initData, optionCountry]);

    return (
        <div className="MailingAddress">
            <FormGroup underline={false}>
                <legend>
                    {required && (
                        <Infobox warning showIcon={false} className="mb-16">
                            {t('clientDetails.mailingAddress.juniorMailAddressRequiredMessage')}
                        </Infobox>
                    )}
                    <span className={error ? 'error' : ''}>
                        {t('MailingAddress.button.mailingAddress')}
                        {required && <span>&nbsp;*</span>}
                    </span>
                    <Button type="link" onClick={onAdd} className="action-button">
                        <Icon type={isDataAvailable ? ICON_TYPE_EDIT : ICON_TYPE_PLUS_BUTTON}/>
                    </Button>
                </legend>
                {presentationalDataElement}
                {isVisible && (
                    <Modal
                        className="MailingAddress__MailingAddressDialog"
                        title={t('dynamicCRM.mailRecipientFieldset')}
                        okText={t('confirmation.confirm')}
                        cancelText={t('confirmation.cancel')}
                        footer={(
                            <span>
                                <Button
                                    type="primary"
                                    disabled={isDataSending || isOptionsLoading}
                                    onClick={handleSubmit(onOk)}
                                >
                                    {isDataSending ? <Loader/> : t('clientDetails.mailingAddress.Submit')}
                                </Button>
                                <Button type="secondary" onClick={onCancel}>
                                    {t('clientDetails.mailingAddress.Cancel')}
                                </Button>
                            </span>
                        )}
                        width="80%"
                        visible={isVisible}
                        onOk={onOk}
                        onCancel={onCancel}
                    >
                        <Preloader isLoading={isOptionsLoading}>
                            <Row>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientFirstName')}
                                        data-cy="mailRecipientFirstName"
                                        name="mailRecipientFirstName"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientFirstName')}
                                        error={errors?.mailRecipientFirstName?.message}
                                        required
                                    />
                                </Column>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientLastName')}
                                        data-cy="mailRecipientLastName"
                                        name="mailRecipientLastName"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientLastName')}
                                        error={errors?.mailRecipientLastName?.message}
                                        required
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="6">
                                    <Controller
                                        data-cy="mailRecipientGender"
                                        name="mailRecipientGender"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                                                label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientGender')}
                                                options={optionGenderAdapted}
                                                error={errors?.mailRecipientGender?.message}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                                <Column size="6">
                                    <Controller
                                        data-cy="mailRecipientLanguage"
                                        name="mailRecipientLanguage"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                                                label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientLanguage')}
                                                options={optionLanguageAdapted}
                                                error={errors?.mailRecipientLanguage?.message}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine1')}
                                        data-cy="mailRecipientAddressLine1"
                                        name="mailRecipientAddressLine1"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine1')}
                                        error={errors?.mailRecipientAddressLine1?.message}
                                    />
                                </Column>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine2')}
                                        data-cy="mailRecipientAddressLine2"
                                        name="mailRecipientAddressLine2"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine2')}
                                        error={errors?.mailRecipientAddressLine2?.message}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine3')}
                                        data-cy="mailRecipientAddressLine3"
                                        name="mailRecipientAddressLine3"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine3')}
                                        error={errors?.mailRecipientAddressLine3?.message}
                                        required
                                    />
                                </Column>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine4')}
                                        data-cy="mailRecipientAddressLine4"
                                        name="mailRecipientAddressLine4"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine4')}
                                        error={errors?.mailRecipientAddressLine4?.message}
                                        required
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column size="6">
                                    <TextInput
                                        {...register('mailRecipientAddressLine5')}
                                        data-cy="mailRecipientAddressLine5"
                                        name="mailRecipientAddressLine5"
                                        label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressLine5')}
                                        error={errors?.mailRecipientAddressLine5?.message}
                                        required
                                    />
                                </Column>
                                <Column size="6">
                                    <Controller
                                        name="mailRecipientAddressCountry"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                                                label={t('dynamicCRM.communicationMethods.mailRecipient.mailRecipientAddressCountry')}
                                                options={optionCountryAdapted}
                                                error={errors?.mailRecipientAddressCountry?.message}
                                                required
                                            />
                                        )}
                                    />
                                </Column>
                            </Row>
                            {generalError && <Infobox error>{generalError}</Infobox>}
                        </Preloader>
                    </Modal>
                )}
            </FormGroup>
        </div>
    );
}

MailingAddress.propTypes = {
    initData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
};

MailingAddress.defaultProps = {
    initData: {},
    onSubmit: noop,
    onDelete: noop,
};

export default MailingAddress;
