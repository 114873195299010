import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useChangePlan} from '../../../../ChangeStrategyLegacy/context';
import {usePortfolio} from '../../../../../../../../../domain/Portfolio';

export const useAdvisorySetupChangePlan = () => {
    const navigate = useNavigate();
    const {dfsClientId, portfolioId} = useParams();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy-legacy`, [dfsClientId, portfolioId]);

    const {
        layout: { setData: setLayoutData, setPageErrors },
        advisoryDocument: {setData: setAdvisoryData, data: advisoryData},
    } = useChangePlan();

    const [shouldInitiateAdvisoryDocument, setShouldInitiateAdvisoryDocument] = useState(advisoryData.isAdvisoryInitiated);

    const {portfolio: { dataRaw: portfolio }} = usePortfolio();

    const onContinue = useCallback(() => {
        navigate(`${baseUrl}/${shouldInitiateAdvisoryDocument === 1 ? 'advisory-document' : 'personal-details'}`);
    }, [shouldInitiateAdvisoryDocument]);

    const onBack = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 1,
        });
    }, []);

    useEffect(() => {
        setAdvisoryData({isAdvisoryInitiated: shouldInitiateAdvisoryDocument});
    }, [shouldInitiateAdvisoryDocument]);

    useEffect(() => {
        setLayoutData({
            onContinue,
            onBack,
            disabled: !shouldInitiateAdvisoryDocument,
        });
    }, [onContinue, onBack, shouldInitiateAdvisoryDocument]);
    useEffect(() => {
        setLayoutData({ saveInProgress: false});
    }, []);

    return {shouldInitiateAdvisoryDocument, setShouldInitiateAdvisoryDocument};
};
