import React, { useMemo, useState } from 'react';
import { Select, Title } from 'ui-library';
import PbNumber from '../PbNumber/PbNumber';
import LabeledValue from './components/LabeledValue/LabaledValue';

import './agent-information-base.css';

function AgentInformationBase({
    t,
    isManualPBRestricted = false,
    isAdvisorCanBeSelected = true,
    productDesc,
    issueCommissionSelected,
    issueCommissionChange,
    issueCommissionOptions,
    pbSelected,
    pbChange,
    validation,
    changeVSTNumber,
    vstNumber,
    generalAgencies,
    selectedGeneralAgency,
    setSelectedGeneralAgency,
    agentsList,
    selectedAgent,
    setSelectedAgent,
    handleSearchAgents,
    searchAgentsInProgress,
    isZic,
    isPk,
    zurichEmployee,
}) {
    const [agentInfoPerPbNumber, setAgentInfoPerPbNumber] = useState(null);

    const generalAgenciesOptions = useMemo(() => [{ label: t('position.pleaseSelect'), value: '' }, ...(generalAgencies?.map(i => ({ label: i.name, value: i.id })) || [])], [generalAgencies]);
    const selectedAgencyFull = useMemo(() => generalAgencies?.find(f => f.id === parseInt(selectedGeneralAgency, 10)), [selectedGeneralAgency, generalAgencies]);

    const agentsListOptions = useMemo(() => [{ value: '', label: t('position.pleaseSelect') }, ...agentsList.map(i => ({ value: i.id, label: `${i.firstName} ${i.lastName}` }))], [agentsList]);
    const selectedAgentFull = useMemo(() => agentsList.find(f => f.id === parseInt(selectedAgent, 10)), [selectedAgent, agentsList]);

    return (
        <div className="fi-verticals">
            <div>
                <Title type={1}>
                    {productDesc}
                </Title>
                <div className="row">
                    <div className="col-md-8">
                        <Title type={2}>{t('portfolio.createNewPortfolio.agentInformation.advisor')}</Title>
                        <div className="form agent-info">
                            {!isZic ? (
                                <Select
                                    data-cy="commission"
                                    required
                                    disabled={zurichEmployee || isPk}
                                    value={issueCommissionSelected}
                                    error={validation?.commission}
                                    options={issueCommissionOptions}
                                    label={t('portfolio.createNewPortfolio.agentInformation.issueCommission')}
                                    placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                                    onChange={issueCommissionChange}
                                />
                            ) : <div/>}
                            <div/>
                            <PbNumber
                                isManualPBRestricted={isManualPBRestricted}
                                value={pbSelected}
                                error={validation?.pbNumber}
                                onSelect={pbChange}
                                setAgentInfo={setAgentInfoPerPbNumber}
                                label={t('portfolio.createNewPortfolio.agentInformation.pbNumber')}
                                required
                                includeVstNumber
                                onVstNumberChange={changeVSTNumber}
                                vstNumberError={validation?.vstNumber}
                                vstNumberValue={vstNumber}
                            />
                            <span className="agent-name">{agentInfoPerPbNumber}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Title type={2}>{t('portfolio.createNewPortfolio.agentInformation.advisorInformation')}</Title>
                <div className="row">
                    <div className="col-md-4">
                        <dl className="DataList DataList--singleCol">
                            <Select
                                data-cy="agentInfo"
                                disabled={!isAdvisorCanBeSelected}
                                options={agentsListOptions}
                                label={t('portfolio.createNewPortfolio.agentInformation.advisor')}
                                required
                                value={selectedAgent}
                                placeholder={t('position.pleaseSelect')}
                                onChange={(val) => setSelectedAgent(val)}
                                error={validation?.agent}
                                hasSearch
                                asyncSearch={handleSearchAgents}
                                searchInProgress={searchAgentsInProgress}
                            />
                            {selectedAgentFull && (
                                <>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.firstName')}>{selectedAgentFull.firstName}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.lastName')}>{selectedAgentFull.lastName}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.street')} >{selectedAgentFull.streetAddress || '-'}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.location')} >{`${selectedAgentFull.postalCode || '-'} / ${selectedAgentFull.city || '-'}`}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.phone')} >{selectedAgentFull.phoneNumber || '-'}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.email')} >{selectedAgentFull.email || '-'}</LabeledValue>
                                </>
                            )}
                        </dl>
                    </div>
                    <div className="col-md-6">

                        <dl className="DataList DataList--singleCol">
                            <Select
                                onChange={(val) => setSelectedGeneralAgency(val)}
                                label={t('portfolio.createNewPortfolio.agentInformation.generalAgency')}
                                placeholder={t('position.pleaseSelect')}
                                required
                                data-cy="generalAgency"
                                value={selectedGeneralAgency}
                                options={generalAgenciesOptions}
                                hasSearch
                                width={450}
                                error={validation?.generalAgency}
                            />
                            {selectedAgencyFull && (
                                <>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.bank.name')}>{selectedAgencyFull.name}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.street')} >{selectedAgencyFull.address}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.location')} >{`${selectedAgencyFull.zip} / ${selectedAgencyFull.city}`}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.phone')} >{selectedAgencyFull.phone}</LabeledValue>
                                    <LabeledValue label={t('portfolio.createNewPortfolio.agentInformation.advisor.gaVstNumber')} >{selectedAgencyFull.vstNumber}</LabeledValue>
                                </>
                            )}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgentInformationBase;
