import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBaseAgentInformation } from '../../../../../components/AgentInformation/hooks/useBaseAgentInformation';
import { useCreatePortfolio } from '../../../context';

export const useAgentInformation = ({
    dfsClientId, stepNavBarActive = 6, prevLink = 'goal-setup', nextLink = 'overview', saveFeeData, isPk,
}) => {
    const navigate = useNavigate();
    const {
        newPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors },
    } = useCreatePortfolio();

    const {
        t,
        isManualPBRestricted,
        isAdvisorCanBeSelected,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        isValid,
        validation,
        setContinuesValidation,
        changeVSTNumber,
        vstNumber,
        isLoading,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    } = useBaseAgentInformation({
        dfsClientId,
        newPortfolio,
    });

    const onContinue = useCallback(async () => {
        setContinuesValidation(true);
        if (isValid) {
            try {
                const payload = {
                    issueCommission: issueCommissionSelected || '0',
                    pbNumber: String(pbSelected),
                    vstNumber,
                    investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
                    agencyId: selectedGeneralAgency,
                    agentId: selectedAgent,
                };

                let feeOverview;

                if (saveFeeData && !isPk) {
                    feeOverview = await saveFeeData();
                }
                const result = await saveNewPortfolio({
                    data: payload,
                    method: 'saveAgentData',
                    additionalData: {
                        agentInformation: {
                            pbSelected,
                            issueCommissionSelected,
                            vstNumber,
                            selectedGeneralAgency,
                            selectedAgent,
                        },
                        feeOverview,
                    },
                });

                if (!result) return;
                navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/${nextLink}`);
            } catch (err) {
                setPageErrors(err.message);
            }
        }
    }, [
        dfsClientId, newPortfolio, pbSelected, vstNumber, saveNewPortfolio,
        issueCommissionSelected, isValid, selectedGeneralAgency, selectedAgent, saveFeeData, isPk,
    ]);

    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/${prevLink}`);
    }, [dfsClientId]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive,
            onBack,
            onContinue,
            disabled: isLoading,
        });
    }, [onBack, onContinue, isLoading]);

    return {
        t,
        isManualPBRestricted,
        isAdvisorCanBeSelected,
        validation,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        changeVSTNumber,
        vstNumber,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    };
};
