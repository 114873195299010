import ChartType from 'components/ChartType/ChartType';
import Preloader from 'components/Preloader';
import {
    INVESTOR_PROFILE_CONTACT_ID, PIE, PRODUCT_EXTERNAL_ID, strategyTypes,
} from 'constants/constants';
import { useFormatting } from 'locale';
import { useClientProfile } from 'prodivers/clientProfile';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { allocationPercentFormat } from 'utils/formatting';
import {
    FormGroup, Title, notification,
} from 'ui-library';
import { usePortfolio } from 'domain/Portfolio';
import { useNavigate, useParams } from 'react-router-dom';
import { useChangePlan } from '../../context';
import { useProductAllocation } from '../../../../hooks/useProductAllocation';
import ServiceManager from '../../../../../../../../services/ServiceManager';
import { riskCategoriesByLang } from '../../../../constants';
import RiskScale from '../../../../components/RiskScale/RiskScale';

import './ChangeOverview.css';
import { useCachedAPI } from '../../../../../../../../hooks/useCachedAPI';
import { adaptModelType } from '../../../../adapters/adaptModelPortfolio';
import { adaptAllocations } from '../../../SinglePortfolioView/pages/Strategy/adapters/adaptProposalData';
import { getClientId } from '../../../../../../../../utils';
import { riskCategories } from '../../../../../../constants';
import { useChangeStrategy3bStepper } from '../../constants';

const adaptDescription = (description) => description.split('\n').reduce((akku, item) => {
    const trimItem = item.trim();

    if (trimItem.length > 0) {
        akku.push(
            <p className="paragraph">
                {item.trim()}
            </p>,
        );
    }

    return akku;
}, []);

function ChangeOverview() {
    const navigate = useNavigate();
    const { portfolioId, dfsClientId } = useParams();
    const clientId = useMemo(() => getClientId(dfsClientId), [dfsClientId]);
    const { i18n: { language }, t } = useTranslation();
    const [isSending, setSending] = useState(false);
    const [isIndividualDataForOldPlanLoading, setIndividualDataForOldPlanLoading] = useState(true);
    const [individualPortfolioDataForOldPlan, setIndividualPortfolioDataForOldPlan] = useState({ body: [], footer: {} });
    const [oldModelPortfolio, setOldModelPortfolio] = useState();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy`, [dfsClientId, portfolioId]);
    const { portfolio: { dataRaw: portfolio } } = usePortfolio();
    const {
        newProductExternalId,
        strategy: {
            data: {
                newModelPortfolio: changedModelPortfolio, strategyName, strategyId, investmentHorizon, individualPortfolioPositions: individualPortfolioDataForNewPlan, individualPortfolio,
                rebalancing, withdrawalOption, newWithdrawalData,
            },
            removeData,
        }, layout: { setData: setLayoutData, setPageErrors },
        strategies,
        isThreeBProduct: is3bProduct,
        advisoryDocument: { data: advisoryData },
    } = useChangePlan();

    const productExternalId = global.sessionStorage.getItem(PRODUCT_EXTERNAL_ID);

    const { overviewStepNumber } = useChangeStrategy3bStepper(productExternalId);

    const [newModelPortfolio, setNewModelPortfolio] = useState(changedModelPortfolio);
    const oldProduct = useMemo(() => (oldModelPortfolio), [oldModelPortfolio]);
    const newProduct = useMemo(() => (newModelPortfolio), [newModelPortfolio]);
    const newProductId = useMemo(() => (newModelPortfolio?.Id), [newModelPortfolio?.Id]);

    useEffect(() => {
        setNewModelPortfolio(changedModelPortfolio);
    }, [changedModelPortfolio]);

    const selectedNewRisk = useMemo(() => {
        const modelPortfolioType = adaptModelType(newModelPortfolio);

        let strategy = strategyName;

        if (modelPortfolioType === strategyTypes.INDIVIDUAL) {
            const risk = Object.values(riskCategories).find(f => f.Risk === newModelPortfolio?.CalculatedRiskScore);

            strategy = strategies?.find(f => f.Id === risk.Id);
        } else {
            strategy = strategies?.find(f => f.Id === strategyId);
        }

        return strategy?.Name;
    }, [strategyName, newModelPortfolio?.Attributes, newModelPortfolio?.CalculatedRiskScore, strategies]);
    const oldStrategyName = useMemo(() => (portfolio?.RiskCategory?.Name), [portfolio?.RiskCategory?.Name]);
    const riskCategoriesList = useMemo(() => (riskCategoriesByLang(t)), [t]);

    const {
        getLastApplicationByPortfolio,
        getInvestmentApplication,
        getModelPortfolio,
        getPortfolioProposal,
    } = useCachedAPI();

    const getIndividualData = useCallback(async () => {
        try {
            setIndividualDataForOldPlanLoading(true);

            const lastApplicationByPortfolio = await getLastApplicationByPortfolio([{ portfolioId, language }]);

            const investmentApplicationId = lastApplicationByPortfolio?.investmentApplicationId;
            const investmentApplicationData = await getInvestmentApplication([{ investmentApplicationId }]);

            const portfolioProposalId = investmentApplicationData?.investmentDescription?.portfolioProposalId;
            const mpId = investmentApplicationData?.investmentDescription?.selectStrategy?.modelPortfolioId;
            const modelPortfolioData = await getModelPortfolio([mpId, { language }]);

            const newModelPortfolioId = changedModelPortfolio?.Id;
            const newModelPortfolioData = await getModelPortfolio([newModelPortfolioId, { language }]);

            setOldModelPortfolio(modelPortfolioData);
            setNewModelPortfolio({ ...newModelPortfolioData, CalculatedRiskScore: changedModelPortfolio?.CalculatedRiskScore });

            const portfolioType = adaptModelType(modelPortfolioData);
            const isIndividual = portfolioType === strategyTypes.INDIVIDUAL;

            if (isIndividual) {
                const portfolioProposal = await getPortfolioProposal([clientId, portfolioProposalId, { language }]);

                setIndividualPortfolioDataForOldPlan(adaptAllocations(portfolioProposal?.Positions));
            }
        } catch (error) {
            setPageErrors(error);
        } finally {
            setIndividualDataForOldPlanLoading(false);
        }
    }, [portfolioId, clientId, language, changedModelPortfolio?.Id]);

    // Client Provider
    const { ...information } = useClientProfile();
    const { getFormattedXAxisFormat } = useFormatting();

    const {
        allocations: newAllocations,
        isLoading: isNewAllocationsLoading,
        error: newProductAllocationError,
        riskScore: newPlanScore,
    } = useProductAllocation({ productId: parseInt(newProductId, 10), individualPortfolioData: individualPortfolioDataForNewPlan });

    const {
        allocations: oldAllocations,
        isLoading: isOldAllocationsLoading,
        error: oldProductAllocationError,
        riskScore: oldPlanScore,
    } = useProductAllocation({ productId: parseInt(oldProduct?.Id, 10), individualPortfolioData: individualPortfolioDataForOldPlan });

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        try {
            setSending(true);
            const { data } = await ServiceManager.customInvestmentService('changePlanApplication', [{
                strategyId,
                investmentHorizon,
                modelPortfolioId: newProductId,
                portfolioId,
                individualPortfolio,
                rebalancing,
                isNewFlow: true,
                ...(newProductExternalId ? { ProductExternalId: newProductExternalId } : {}),
                ...(is3bProduct ? {
                    withdrawalOption,
                    ...(withdrawalOption ? { ...newWithdrawalData } : {}),
                } : {}),

            }]);

            await ServiceManager.customInvestmentService('saveAdvisoryDocumentCompletedFlag', [{ investmentApplicationId: data.investmentApplicationId, data: { advisoryDocumentId: advisoryData?.document?.advisoryDocumentId } }]);

            sessionStorage.removeItem(PRODUCT_EXTERNAL_ID);
            sessionStorage.removeItem(INVESTOR_PROFILE_CONTACT_ID);
            removeData();
            if (data?.investmentDescription?.containerId) {
                navigate(`/client/${dfsClientId}/container/${data?.investmentDescription?.containerId}`);
            } else if (data.investmentApplicationId) {
                navigate(`/client/${dfsClientId}/application/${data.investmentApplicationId}`);
            } else {
                notification.open({
                    content: `${t('contactGroups.somethingWentWrong')}`,
                    type: 'error',
                });
            }
        } catch (err) {
            setPageErrors(err.toString());
        } finally {
            setSending(false);
        }
    }, [dfsClientId, portfolioId, investmentHorizon,
        strategyId, newProductId, individualPortfolio,
        rebalancing, removeData, withdrawalOption, newWithdrawalData, is3bProduct, newProductExternalId]);

    const onBack = useCallback(() => {
        navigate(-1);
    }, [baseUrl]);

    useEffect(() => {
        if (portfolioId && clientId && language && changedModelPortfolio?.Id) {
            getIndividualData();
        }
    }, [portfolioId, clientId, language, changedModelPortfolio?.Id]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: is3bProduct ? overviewStepNumber : 4,
        });
    }, [overviewStepNumber, is3bProduct, productExternalId]);

    useEffect(() => {
        setLayoutData({
            onContinue,
            disabled: isSending || information?.isLoading || isOldAllocationsLoading,
        });
    }, [onContinue, isSending, information?.isLoading, isOldAllocationsLoading]);

    useEffect(() => {
        setLayoutData({
            onBack,
        });
    }, [onBack]);

    useEffect(() => {
        setLayoutData({ saveInProgress: isSending });
    }, [isSending]);

    useEffect(() => {
        setPageErrors(newProductAllocationError || oldProductAllocationError);
    }, [newProductAllocationError, oldProductAllocationError]);

    return (
        <div className="change-overview">
            <div >
                <div className="container">
                    <div className="chart-wrapper" hidden={information?.isLoading}>
                        <Preloader
                            isLoading={information?.isLoading || isOldAllocationsLoading || isIndividualDataForOldPlanLoading}
                        >
                            <Title type={2}>{t('portfolio.changeStrategy.previousInvestmentStrategy')}</Title>
                            <Title type={3}>{oldProduct?.Name}</Title>
                            <Title type={3}>{oldStrategyName}</Title>
                            <div className="fi-strategy-layout fi-strategy-layout--pie-chart">
                                <div className="fi-pie-chart">
                                    <ChartType
                                        data={oldAllocations}
                                        chartView={PIE}
                                        percentFormat={allocationPercentFormat}
                                        xAxisFormat={getFormattedXAxisFormat}
                                    />
                                </div>
                                <div className="fi-product-description">
                                    {oldProduct?.Description && adaptDescription(oldProduct?.Description)}
                                </div>
                                <FormGroup>
                                    <RiskScale scales={riskCategoriesList} activeIndex={oldPlanScore} />
                                </FormGroup>
                            </div>
                        </Preloader>
                    </div>
                    <div hidden={information?.isLoading}>
                        <Preloader
                            isLoading={information?.isLoading || isNewAllocationsLoading}
                        >
                            <Title type={2}>{t('portfolio.changeStrategy.newInvestmentStrategy')}</Title>
                            <Title type={3}>{newProduct?.Name}</Title>
                            <Title type={3}>{selectedNewRisk}</Title>
                            <div className="fi-strategy-layout fi-strategy-layout--pie-chart">
                                <div className="fi-pie-chart">
                                    <ChartType
                                        data={newAllocations}
                                        chartView={PIE}
                                        percentFormat={allocationPercentFormat}
                                        xAxisFormat={getFormattedXAxisFormat}
                                    />
                                </div>
                                <div className="fi-product-description">
                                    {newProduct?.Description && adaptDescription(newProduct?.Description)}
                                </div>
                                <FormGroup>
                                    <RiskScale scales={riskCategoriesList} activeIndex={newPlanScore} />
                                </FormGroup>
                            </div>
                        </Preloader>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeOverview;
