/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, {
    FC, Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button, Checkbox, Icon, Loader, Title,
} from 'ui-library';

import { InvestmentDepot } from '../../datasource/useInvestDepots/useInvestDepots';
import { useFormatting } from '../../locale';

import './InvestDepot.css';

const InvestDepots:FC<{datasource: () => any}> = ({ datasource }) => {
    const { t } = useTranslation();
    const { dfsClientId } = useParams();
    const navigate = useNavigate();
    const {
        data, isLoading, isJoint, handleProfileSelection, setIsTouched,
    } = datasource();

    const { getAmountWithDecimals } = useFormatting();
    const [selectedDepots, setSelectedDepots] = useState<Record<string, number>>({});

    useEffect(() => {
        setSelectedDepots(data?.filter(dta => dta?.checked)?.reduce((acc, curr) => ({
            ...acc,
            [curr.portfolioId]: curr.value,
        }), {}));
    }, [data]);

    const openDepotDetails = useCallback((portfolioId: number) => {
        navigate(`/client/${dfsClientId}/portfolios/${portfolioId}/tab/overview`);
    }, [navigate]);

    const onDepotSelect = useCallback((event: { target: { checked: boolean; }; }, item: InvestmentDepot) => {
        setIsTouched(true);
        handleProfileSelection({
            checked: event?.target?.checked,
            item: {
                portfolioId: item?.portfolioId, value: item.value, checked: event?.target?.checked,
            },
        });
        setSelectedDepots(prev => {
            if (item.portfolioId in prev) {
                const nextPrev = { ...prev };

                delete nextPrev[item.portfolioId];

                return nextPrev;
            }

            return {
                ...prev, [item.portfolioId]: item.value,
            };
        });
    }, []);

    const totalAmountSelected = useMemo(() => (selectedDepots ? Object?.keys(selectedDepots)?.reduce((acc, cur) => +acc + +selectedDepots[cur], 0) : 0), [selectedDepots]);

    const renderItem = useMemo(() => data?.map((item: InvestmentDepot) => (
        <Fragment key={item.portfolioId}>
            {/* @ts-ignore */}
            <Checkbox label="" checked={item?.checked} onChange={(event) => onDepotSelect(event, item)}/>
            <div className="item">
                <Button type="link" onClick={() => openDepotDetails(item.portfolioId)}>
                    <Title type={4}>
                        {item?.modelPortfolioName}
                    </Title>
                    {item.name}
                    {isJoint && <p>{t('connections.groupName.ja')}</p>}
                    {/* @ts-ignore */}
                    <Icon type="open" size={16} />
                </Button>
                <p className="amount">
                    {getAmountWithDecimals(item.value)}
                </p>
            </div>
        </Fragment>
    )), [data]);

    if (isLoading) {
        return <Loader/>;
    }

    if (!data?.length) {
        return <section><p>{t('investDepots.noDepots')}</p></section>;
    }

    return (
        <section className="invest-depot">
            <p className="span">{t('investDeposts.pleaseSelect')}</p>
            <p />
            <p className="title">{t('investDepots.depot')}</p>
            <p className="title">{t('investDepots.value')}</p>
            <p/>
            {renderItem}
            <p className="span">
                {t(`investDepots.${isJoint ? 'ja' : 'single'}`)}
            </p>
            {selectedDepots && Object?.keys(selectedDepots)?.length > 0 ? (
                <section className="totals">
                    <p className="label">{t('investDepots.totalAmount')}</p>
                    <p className="amount">
                        {getAmountWithDecimals(totalAmountSelected)}
                    </p>
                </section>
            ) : null}
        </section>
    );
};

export default InvestDepots;
