import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {isEmpty} from 'lodash';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useChangePlan} from '../../../../ChangeStrategyLegacy/context';
import {useAdvisoryDocumentBase} from './useAdvisoryDocumentBase';

export const useAdvisoryDocumentChangePlan = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {
        layout: { setData: setLayoutData, setPageErrors },
        advisoryDocument: {setData: setAdvisoryData, data: advisoryData},
        contactGroupId,
    } = useChangePlan();

    const {
        isLoading,
        document,
        setDocument,
        getDocument,
    } = useAdvisoryDocumentBase({contactGroupId});

    const {dfsClientId, portfolioId} = useParams();

    const [error, setError] = useState(null);

    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/${portfolioId}/change-strategy-legacy`, [dfsClientId, portfolioId]);

    const onContinue = useCallback(async () => {
        if (isEmpty(document)) {
            setError(t('advisoryDocument.step.noDocumentError'));

            return;
        }

        setAdvisoryData({...advisoryData, document});

        navigate(`${baseUrl}/personal-details`);
    }, [document, t, baseUrl, advisoryData]);

    const onBack = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 1,
            onBack,
            onContinue,
            disabled: isLoading,
        });
    }, [onBack, onContinue, isLoading]);

    return {
        isLoading,
        document,
        getDocument,
        setDocument,
        error,
        setError,
        contactGroupId,
    };
};
