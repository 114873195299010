import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APPLICATION_CONTAINER_ID } from 'constants/constants';
import { notification } from 'ui-library';
import { usePortfolioProcessNavigation } from '../hooks/helpers/usePortfolioProcessNavigation';
import { useCreatePortfolioActions, useCreatePortfolioSelectors, useCreatePortfolioState } from './CreatePortfolio';
import { useManagerProfile } from '../prodivers/managerProfile';

export enum steps {
    YesNo,
    AdvisoryProvider
}
export const useAdvisoryProcess = () => {
    const [isSending, setSending] = useState(false);
    const { hasAccessPolicy } = useManagerProfile();
    const { dfsClientId } = useParams();
    const [step, setStep] = useState(steps.YesNo);
    const navigate = useNavigate();

    const { t } = useTranslation();
    const items = useMemo(() => [
        {
            id: 2,
            name: t('advisoryDocumentInit.step.no'),
        },
        {
            id: 1,
            name: t('advisoryDocumentInit.step.yes'),
            disabled: !hasAccessPolicy('EnableAdvisoryProcess'),
        },
    ], [t, hasAccessPolicy]);

    const [initiateAdvisoryProcess, setInitiateAdvisoryProcess] = useState<number | null>(null);
    const { changePlan } = useCreatePortfolioState();
    const { contactGroupIdSelector, isAdvisoryProcessSelector, isAdvisoryDataLoading } = useCreatePortfolioSelectors();
    const {
        setShouldInitiateAdvisoryProcessAction,
        setUploadedAdvisoryDocumentAction,
        startAdvisoryProcessAction,
        advisoryProcessInProgressAction,
    } = useCreatePortfolioActions();

    const startNewAdvisoryProcess = useCallback(async () => {
        try {
            setSending(true);
            await startAdvisoryProcessAction();
            stepComplete();
        } catch (error) {
            notification.open({ content: t('general.somethingWentWrong'), type: 'error' });
        } finally {
            setSending(false);
        }
    }, []);

    const handleOnContinue = useCallback(async () => {
        if (step === steps.YesNo && initiateAdvisoryProcess === 1) {
            setShouldInitiateAdvisoryProcessAction(1);
            setStep(steps.AdvisoryProvider);
        } else if (step === steps.AdvisoryProvider && initiateAdvisoryProcess === 1) {
            await startNewAdvisoryProcess();
        } else if (initiateAdvisoryProcess === 2) {
            stepComplete();
            unlockNavigationAction();
        }
    }, [initiateAdvisoryProcess, step]);

    const handleOnBack = useCallback(() => {
        if (isAdvisoryProcessSelector) {
            setStep(steps.YesNo);
            setShouldInitiateAdvisoryProcessAction(0);
        } else {
            navigate(`/client/${dfsClientId}`);
        }
    }, [isAdvisoryProcessSelector]);

    const { stepComplete, unlockNavigationAction } = usePortfolioProcessNavigation({
        preventNavigationLock: true,
        continueDisabled: !initiateAdvisoryProcess || isSending,
        continueInProgress: isSending,
        saveOnContinue: handleOnContinue,
        saveOnBack: handleOnBack,
        enableAutoStepComplete: false,
    });

    const setShouldInitiateAdvisoryProcess = (val) => {
        setInitiateAdvisoryProcess(val);
        sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
    };

    useEffect(() => {
        if (changePlan) {
            setShouldInitiateAdvisoryProcessAction(1);
            setStep(steps.AdvisoryProvider);
        }
        advisoryProcessInProgressAction(false);
        setUploadedAdvisoryDocumentAction(null);
        global.sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
    }, [changePlan]);

    return {
        items,
        step,
        initiateAdvisoryProcess,
        startNewAdvisoryProcess,
        shouldInitiateAdvisoryProcess: isAdvisoryProcessSelector,
        setShouldInitiateAdvisoryProcess,
        contactGroupIdSelector,
        setUploadedAdvisoryDocumentAction,
        stepComplete,
        isAdvisoryDataLoading,
    };
};
