/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Preloader from 'components/Preloader';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    DatePicker, NumberInput, Title, TextInput, Select,
} from 'ui-library';

import { compareDates, formatDateWithoutLocalConversion } from 'utils/datetime';
import { validateIban } from 'utils/validation';

const WithdrawalPlanComp = (props) => {
    const { t } = useTranslation();
    const {
        data,
        errorData,
        isRegularTransferPlan,
        optionWithdrawalPeriodicity,
        isLoading,
        isOptionsLoading,
        isAzp,
        isAzpI,
        isAzpD,
        isPk,
        isZiv,
        isZifd,
        trigger,
        setErrorData,
        disableDateHandler,
        control,
    } = props || {};

    const withdrawalPeriodicityOptions = useMemo(() => optionWithdrawalPeriodicity?.map((option) => ({ value: option?.id, label: option?.label })), [optionWithdrawalPeriodicity]);

    return (
        <div>
            <Preloader isLoading={isLoading || isOptionsLoading} >
                <div className="shopping-cart--withdrawalPlanGrid">
                    <div className="form">
                        <fieldset className="setup-section">

                            {isAzp && (
                                <>
                                    <div className="form">
                                        <Controller
                                            name="amount"
                                            control={control}
                                            render={({ field: { onChange, ...field } }) => (
                                                <NumberInput
                                                    {...field}
                                                    placeholder={t('3b.withdrawalPlan.investmentAmountPlaceholder')}
                                                    thousandSeparator="’"
                                                    decimalSeparator="."
                                                    label={t('3b.withdrawalPlan.investmentAmount')}
                                                    value={data?.amount ?? null}
                                                    onChange={(value: number) => {
                                                        onChange(value);
                                                        trigger('amount');
                                                    }}
                                                    error={errorData?.amount?.message}
                                                    required
                                                />
                                            )}
                                        />

                                        <div className="withdrawals-start">
                                            <Controller
                                                name="withdrawalsStartDate"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        /* @ts-ignore */
                                                        label={t('3b.withdrawalPlan.withdrawalsStart')}
                                                        value={data?.startDate && formatDateWithoutLocalConversion(data.startDate, 'DD.MM.YYYY')}
                                                        placeholder={t('3b.withdrawalPlan.withdrawalsStartPlaceholder')}
                                                        disabledDate={disableDateHandler}
                                                        error={errorData?.withdrawalsStartDate?.message}
                                                        format="DD.MM.YYYY"
                                                        required
                                                    />
                                                )}
                                            />

                                            <span>
                                                {t('3b.withdrawalPlan.withdrawalsStartNote')}
                                                    &nbsp;
                                                {isAzpI && t('validation.dateNotWithinTwelveMonths')}
                                                {isAzpD && t('validation.dateWithinTwelveMonths')}
                                            </span>
                                        </div>
                                        <Controller
                                            name="duration"
                                            control={control}
                                            render={({ field }) => (
                                                <NumberInput
                                                    {...field}
                                                    data-cy="duration"
                                                    placeholder={t('3b.withdrawalPlan.durationPlaceholder')}
                                                    label={t('3b.withdrawalPlan.duration')}
                                                    value={data?.duration}
                                                    error={errorData?.duration?.message}
                                                    required
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="frequency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    /* @ts-ignore */
                                                    placeholder={t('3b.kyc.pleaseSelect')}
                                                    label={t('3b.withdrawalPlan.periodicity')}
                                                    value={data?.frequency}
                                                    options={withdrawalPeriodicityOptions}
                                                    error={errorData?.frequency?.message}
                                                    required
                                                />
                                            )}
                                        />
                                    </div>
                                </>
                            )}

                            {(isZifd || isZiv) && (
                                <>
                                    <div className="form">
                                        <Controller
                                            name="amount"
                                            control={control}
                                            render={({ field: { onChange, ...field } }) => (
                                                <NumberInput
                                                    {...field}
                                                    placeholder={t('3b.withdrawalPlan.withdrawalAmountPlaceholder')}
                                                    thousandSeparator="’"
                                                    decimalSeparator="."
                                                    label={t('3b.withdrawalPlan.amount')}
                                                    prefix="CHF "
                                                    value={data?.amount}
                                                    onChange={(value: number) => {
                                                        onChange(value);
                                                        trigger('amount');
                                                    }}
                                                    error={errorData?.amount?.message}
                                                    required
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="frequency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    /* @ts-ignore */
                                                    placeholder={t('3b.kyc.pleaseSelect')}
                                                    label={t('3b.withdrawalPlan.frequency')}
                                                    value={data?.frequency}
                                                    options={withdrawalPeriodicityOptions}
                                                    error={errorData?.frequency?.message}
                                                    required
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="startDate"
                                            control={control}
                                            render={({ field: { onChange, ...field } }) => (
                                                <DatePicker
                                                    {...field}
                                                    /* @ts-ignore */
                                                    label={t('3b.withdrawalPlan.withdrawalsStart')}
                                                    value={data?.startDate || null}
                                                    disabledDate={(current) => current && (compareDates(new Date(), current))}
                                                    onChange={(value) => {
                                                        const date = moment(value).format('MM/YYYY');

                                                        onChange(date);
                                                    }}
                                                    error={errorData?.startDate?.message}
                                                    format="MM/YYYY"
                                                    picker="month"
                                                    required
                                                />
                                            )}
                                        />
                                    </div>
                                </>
                            )}

                            {isPk && isRegularTransferPlan && (
                                <>
                                    <div className="form">
                                        <Controller
                                            name="amount"
                                            control={control}
                                            render={({ field }) => (
                                                <NumberInput
                                                    {...field}
                                                    placeholder={t('3b.withdrawalPlan.investmentAmountPlaceholder')}
                                                    thousandSeparator="’"
                                                    decimalSeparator="."
                                                    label={t('3b.withdrawalPlan.transferAmount')}
                                                    value={data?.amount}
                                                    error={errorData?.amount?.message}
                                                    required
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="frequency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    data-cy="transferFrequency"
                                                    /* @ts-ignore */
                                                    placeholder={t('3b.kyc.pleaseSelect')}
                                                    label={t('3b.withdrawalPlan.transferFrequency')}
                                                    value={data?.frequency}
                                                    options={withdrawalPeriodicityOptions}
                                                    error={errorData?.frequency?.message}
                                                    required
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="startDate"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    /* @ts-ignore */
                                                    label={t('3b.withdrawalPlan.transferStart')}
                                                    value={data?.startDate}
                                                    disabledDate={(current) => current && (compareDates(new Date(), current))}
                                                    error={errorData?.startDate?.message}
                                                    format="MM/YYYY"
                                                    picker="month"
                                                    required
                                                />
                                            )}
                                        />
                                        <div className="transfer-destination-form">
                                            <Title type={3} tagName="legend">
                                                {t('3b.withdrawalPlan.setupTransferDestinationTitle')}
                                            </Title>
                                            <Controller
                                                name="targetPortfolio"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        data-cy="targetPortfolio"
                                                        /* @ts-ignore */
                                                        placeholder={t('3b.kyc.pleaseSelect')}
                                                        label={t('3b.withdrawalPlan.targetPortfolio')}
                                                        value={data?.targetPortfolio}
                                                        options={withdrawalPeriodicityOptions}
                                                        error={errorData?.targetPortfolio?.message}
                                                        required
                                                    />
                                                )}
                                            />

                                        </div>
                                    </div>
                                </>
                            )}
                        </fieldset>

                        {!isPk && (
                            <fieldset className="bank-section">
                                <Title type={3} tagName="legend">
                                    {t('3b.withdrawalPlan.bankTitle')}
                                </Title>
                                <div className="form">
                                    <Controller
                                        name="iban"
                                        control={control}
                                        render={({ field: { onChange, ...field } }) => (
                                            <TextInput
                                                {...field}
                                                data-cy="iban"
                                                label={t('3b.withdrawalPlan.bankIban')}
                                                value={data?.iban}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    onChange(e.target.value);
                                                    setErrorData(
                                                        'iban', { message: '' },
                                                    );
                                                }}
                                                error={errorData?.iban?.message}
                                                onBlur={async (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setErrorData('iban', {
                                                        message: await validateIban(e?.target?.value) as string,
                                                    });
                                                }}
                                                required
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="firstName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                value={data?.firstName}
                                                data-cy="firstName"
                                                label={t('clientDetails.firstName')}
                                                error={errorData?.firstName?.message}
                                                required
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="lastName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                data-cy="lastName"
                                                value={data?.lastName}
                                                label={t('clientDetails.lastName')}
                                                error={errorData?.lastName?.message}
                                                required
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="bankName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                {...field}
                                                data-cy="bankName"
                                                value={data?.bankName}
                                                label={t('3b.withdrawalPlan.bankName')}
                                                error={errorData?.bankName?.message}
                                                required
                                            />
                                        )}
                                    />

                                    <div className="shopping-cart--withdrawal-options-bankZip">
                                        <div className="form-group">
                                            <label>{`${t('3b.withdrawalPlan.bankZipCode')}/${t('3b.withdrawalPlan.bankCity')}`}</label>
                                            <div className="shopping-cart--withdrawal-options-bankZip-inputs">
                                                <Controller
                                                    name="zipCode"
                                                    control={control}
                                                    render={({ field: { onChange, ...field } }) => (
                                                        <TextInput
                                                            {...field}
                                                            value={data?.zipCode}
                                                            data-cy="zipCode"
                                                            error={errorData?.zipCode?.message}
                                                            onChange={(value: number) => {
                                                                onChange(value);
                                                                trigger('zipCode');
                                                            }}
                                                            required
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextInput
                                                            {...field}
                                                            value={data?.city}
                                                            data-cy="city"
                                                            error={errorData?.city?.message}
                                                            required
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                        )}
                    </div>
                </div>
            </Preloader>
        </div>
    );
};

export default WithdrawalPlanComp;
