export const adaptAnswers = ({
    data, values, groupId, isEmptyGroup = false,
}) => ({
    ...data,
    Groups: data?.Groups?.reduce((acc, group) => {
        if (!groupId || group?.Id === groupId) {
            acc.push({
                ...group,
                Steps: group.Steps.map((step) => ({
                    ...step,
                    Questions: step.Questions.map((question) => {
                        const answer = values?.[group.Id]?.[question.Id];
                        let answerToSet;

                        if (typeof answer === 'string') answerToSet = { Value: answer };
                        else if (typeof answer === 'number') answerToSet = { Ids: [answer] };
                        else if (!answer && isEmptyGroup) answerToSet = { Ids: [] };
                        else answerToSet = { Ids: answer };

                        return answer != null || (!answer && isEmptyGroup) ? { ...question, Value: answerToSet } : question;
                    }),
                })),
            });
        }

        return acc;
    }, []),
});
