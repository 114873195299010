import { QUALITY_CHECK_DATA } from 'constants/constants';
import AdapterError from 'errors/AdapterError';
import { useCallback, useEffect, useState } from 'react';
import { getItemFromSS } from 'utils/storage';

export const useFormData = ({ serverData }) => {
    // Form data
    const [data, setData] = useState({});
    const [isInitDone, setInitDone] = useState(false);

    const getDataFromSS = useCallback(() => getItemFromSS(QUALITY_CHECK_DATA), [getItemFromSS]);
    const initializeData = useCallback(({ groupIds }) => {
        if (!groupIds) throw new AdapterError('There is no groupIds for initializeData function');
        const newData = groupIds.reduce((acc, key) => ({ ...acc, [key]: {} }), {});

        setData(newData);
        sessionStorage.setItem(QUALITY_CHECK_DATA, JSON.stringify(newData));
    }, []);
    const saveData = useCallback(({ groupId, questions }) => {
        if (!groupId) throw new AdapterError('There is no groupId for saveData function');
        const newData = { ...data };

        if (!newData[groupId]) {
            newData[groupId] = {};
        }

        questions?.forEach(question => {
            const { questionId, value } = question;

            if (questionId) {
                newData[groupId][questionId] = value;
            } else {
                newData[groupId] = {
                    ...newData[groupId],
                    value,
                };
            }
        });

        setData(newData);
        sessionStorage.setItem(QUALITY_CHECK_DATA, JSON.stringify(newData));
    }, [data]);
    const clearData = useCallback(() => {
        setData(null);
        sessionStorage.removeItem(QUALITY_CHECK_DATA);
    }, []);

    useEffect(() => {
        if (!serverData) {
            return;
        }

        const group = serverData?.Groups?.[1];
        const questions = group?.Steps?.[0]?.Questions;

        const serverValues = {
            [group?.Id]: {
                ...questions?.reduce((capacitor, question) => {
                    if (question?.Value) {
                        if (question.Type === 'SingleAnswer') {
                            capacitor[question?.Id] = question?.Value?.Ids?.[0];
                        } else if (question.Type === 'FreeText') {
                            capacitor[question?.Id] = question?.Value?.Value;
                        } else {
                            capacitor[question?.Id] = question?.Value?.Ids;
                        }
                        // capacitor[question?.Id] = question.Type === 'SingleAnswer' ? question?.Value?.Ids?.[0] : question?.Value?.Ids;
                    }

                    return capacitor;
                }, {}),
            },
        };
        const dataFromSS = getDataFromSS();
        const mergedData = {
            [group?.Id]: {
                ...serverValues?.[group?.Id],
                ...dataFromSS?.[group?.Id],
            },
        };

        if (dataFromSS || serverValues) {
            setData(mergedData);
        }
        setInitDone(true);
    }, [getDataFromSS, serverData]);

    return {
        data,
        getDataFromSS,
        initializeData,
        saveData,
        clearData,
        isInitDone,
    };
};
