import React from 'react';
import {
    Button, Table, Title,
} from 'ui-library';
import RiskScale from '../../../../components/RiskScale/RiskScale';
import { InvestmentStrategyAllocations } from '../../../../components/InvestmentStrategyAllocations';
import {
    ICON_TYPE_INFOBOX,
} from '../../../../../../../../ui-library/components/Icon';
import Icon from '../../../../../../../../ui-library/components/Icon/Icon';

import { columnDefs } from './columnDefs';
import Popover from '../../../../../../../../ui-library/components/Popover';

const IndividualStrategy = ({
    onChangePlan,
    onChangePlanSimplified,
    allocations,
    strategyName,
    t,
    riskCategories,
    strategy,
    getFormattedXAxisFormat,
    isPortfolioChangeable,
    ongoingPlan,
    portfolioProducts,
    isRebalancing,
    riskName,
}) => (
    <>
        <div className="col-xs-12 col-lg-6">
            <div className="fi-verticals">
                <dl className="Strategy__Summary">
                    <div>
                        <dt className="Strategy__Summary__Key">{t('portfolioDetail.strategy.chosenInvestmentRisk')}</dt>
                        <dd className="Strategy__Summary__Value">
                            <div>{riskName}</div>
                        </dd>
                    </div>
                    <div>
                        <dt className="Strategy__Summary__Key">{t('portfolioDetail.strategy.chosenInvestmentStrategy')}</dt>
                        <dd className="Strategy__Summary__Value">
                            <div>{strategyName}</div>
                        </dd>
                    </div>
                    <div className="Strategy__Portfolio">
                        <dt className="Strategy__Summary__Key">{t('portfolioDetail.strategy.modelPortfolio')}</dt>
                        <dd>
                            <Table
                                data={portfolioProducts}
                                columns={columnDefs(t)}
                                mini={false}
                            />
                        </dd>
                        <p className="Strategy__rebalance">
                            Rebalance:
                            <span>
                                {isRebalancing ? t('portfolioDetail.strategy.rebalancingOn') : t('portfolioDetail.strategy.rebalancingOff')}
                                <Popover content={<div style={{ padding: '10px', maxWidth: '300px' }}>{t('portfolioDetail.strategy.rebalancingTooltip')}</div>} trigger="hover">
                                    <Icon type={ICON_TYPE_INFOBOX} className="search-icon" size={16} />
                                </Popover>
                            </span>
                        </p>
                    </div>
                </dl>
            </div>
        </div>
        <div className="col-xs-12 col-lg-6">
            <div className="fi-verticals Strategy__Description">
                <div className="Strategy__Chart">
                    <InvestmentStrategyAllocations
                        allocations={allocations}
                        getFormattedXAxisFormat={getFormattedXAxisFormat}
                    />
                </div>
                <div>
                    <dt className="Strategy__Summary__Key">{t('portfolio.createNewPortfolio.risk')}</dt>
                    <dd className="Strategy__Summary__Value">
                        <div>
                            <RiskScale scales={riskCategories} activeIndex={strategy}/>
                        </div>
                    </dd>
                </div>
                {isPortfolioChangeable && (
                    <>
                        <div>
                            <Title type={3}>
                                {t('portfolioDetail.strategy.changeStrategyTitle')}
                            </Title>
                            <p>
                                {t('portfolioDetail.strategy.changeStrategyText')}
                            </p>
                            <div className="button-group">
                                <Button
                                    data-cy="changePlanBtn"
                                    disabled={ongoingPlan}
                                    onClick={globalThis?.AppConfig?.ADVISORY_PROCESS_FEATURE_FLAG === 'true' ? onChangePlanSimplified : onChangePlan}
                                >
                                    {t('portfolioDetail.strategy.changePlanBtn')}
                                </Button>
                            </div>
                        </div>
                    </>
                )}

            </div>
        </div>
    </>
);

export default IndividualStrategy;
