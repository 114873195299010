import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ClientApplicationOverview from './ClientApplicationOverview';
import ApplicationDetailsPage from './ApplicationDetailsPage';

const ApplicationOverviewRouter = () => (
    <Routes >
        {
            globalThis?.AppConfig?.ADVISORY_PROCESS_FEATURE_FLAG === 'true' ? (
                <>
                    <Route path="application/:applicationId/" element={<ApplicationDetailsPage />} />
                    <Route path="container/:containerId/" element={<ApplicationDetailsPage />} />
                </>
            ) : (
                <>
                    <Route path="application-legacy/:applicationId/" element={<ClientApplicationOverview/>} />
                    <Route path="container-legacy/:containerId/" element={<ClientApplicationOverview/>} />
                </>
            )
        }

        <Route
            path="*"
            element={<Navigate to="/404" replace />}
        />
    </Routes>
);

ApplicationOverviewRouter.displayName = 'Router';

export default ApplicationOverviewRouter;
