import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import React from 'react';
import {
    Checkbox, FormGroup, RadioButtonGroup, Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import { ACCESS_POLICY } from 'constants/constants';
import { useManagerProfile } from 'prodivers/managerProfile';
import { Controller } from 'react-hook-form';
import { generateData } from './utils/generateData';
import { NotEditablePersonalData } from '../../components/UneditablePersonalData';
import { useFormatting } from '../../locale';

function PersonalDetailsForm({
    data,
    isDependantOnAffiliatedWithPensionFund,
    onRetiredButStillEmployedChange,
    onEditPersonalData,
    missingData,
    optionCountry,
    optionGender,
    radioButtonGroupValues,
    showEmploymentContinuationCheck,
    retiredRef,
    option,
    isJA,
    occupation: {
        occupationListOptions,
        handleSearchOccupations,
        searchInProgress,
        loadMoreItems,
        hasMoreOccupations,
    },
    form: {
        setValue,
        control,
        errors,
    },
}) {
    const { t } = useTranslation();
    const { getFormattedDate } = useFormatting();

    const { hasAccessPolicy } = useManagerProfile();

    return (
        <form className="personalDetails__singleForm" style={!isJA ? { border: 'none' } : {}}>
            <NotEditablePersonalData
                editPersonalData={onEditPersonalData}
                data={data}
                errorData={missingData}
                optionCountry={optionCountry}
                optionGender={optionGender}
            />

            {isDependantOnAffiliatedWithPensionFund
                && (
                    <Title type={2}>
                        {t('onboardingFlow.personalPage.subheader')}
                    </Title>
                )}

            <div className="personalDetailsTable data-summary-wrapper">

                <div className="form">
                    {isDependantOnAffiliatedWithPensionFund
                        && (
                            <>
                                <fieldset className="form-group">
                                    <legend
                                        className="inputLabel"
                                    >
                                        {t('onboardingFlow.personalPage.affiliatedPensionFund')}
                                    </legend>
                                    <Controller
                                        name="affiliatedPensionFund"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioButtonGroup
                                                {...field}
                                                data-cy="affiliatedPensionFund"
                                                options={radioButtonGroupValues}
                                                error={errors?.affiliatedPensionFund?.message}
                                                required
                                            />
                                        )}
                                    />

                                </fieldset>
                                {showEmploymentContinuationCheck && (
                                    <fieldset>
                                        <legend
                                            className="inputLabel"
                                        >
                                            {t('onboardingFlow.personalPage.retiredButStillEmployed')}
                                        </legend>
                                        <Controller
                                            name="employmentContinuation"
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    ref={retiredRef}
                                                    checked={field.value}
                                                    label={t('onboardingFlow.personalPage.retiredButStillEmployedLabel')}
                                                    error={errors?.employmentContinuation?.message}
                                                    onChange={(e) => {
                                                        onRetiredButStillEmployedChange(e);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                    </fieldset>
                                )}
                            </>
                        )}
                    <FormGroup className="form-group--half">
                        <Controller
                            name="occupation"
                            control={control}
                            render={({ field }) => (
                                <SelectElement
                                    {...field}
                                    data-cy="occupation"
                                    label={t('onboardingFlow.personalPage.occupation')}
                                    placeholder={t('onboardingFlow.personalPage.occupation')}
                                    options={occupationListOptions}
                                    error={errors?.occupation?.message}
                                    required
                                    hasSearch
                                    asyncSearch={handleSearchOccupations}
                                    searchInProgress={searchInProgress}
                                    loadMoreItems={loadMoreItems}
                                    hasMoreItems={hasMoreOccupations}
                                />
                            )}
                        />
                    </FormGroup>
                    <fieldset className="form-group--multiple">
                        <Title type={4} tagName="legend">
                            {t('onboardingFlow.personalPage.domicileDeclaration')}
                        </Title>
                        <Controller
                            name="taxDomicile"
                            control={control}
                            render={({ field }) => (
                                <SelectElement
                                    {...field}
                                    data-cy="taxDomicile"
                                    label={t('onboardingFlow.personalPage.taxDomicile')}
                                    placeholder={t('onboardingFlow.personalPage.taxDomicile')}
                                    options={optionCountry}
                                    error={errors?.taxDomicile?.message}
                                    required
                                    hasSearch
                                />
                            )}
                        />
                        <Controller
                            name="riskDomicile"
                            control={control}
                            render={({ field }) => (
                                <SelectElement
                                    {...field}
                                    data-cy="riskDomicile"
                                    label={t('onboardingFlow.personalPage.riskDomicile')}
                                    placeholder={t('onboardingFlow.personalPage.riskDomicile')}
                                    options={optionCountry}
                                    error={errors?.riskDomicile?.message}
                                    required
                                    hasSearch
                                />
                            )}
                        />
                    </fieldset>
                </div>
            </div>

            <Title type={2}>
                {t('onboardingFlow.personalPage.subheaderSecond')}
            </Title>
            <div className="personalDetailsTable data-summary-wrapper">
                <div className="personalDetailsTable data-summary-wrapper">
                    <div className="data-summary">
                        <Controller
                            name="member"
                            control={control}
                            render={({ field }) => (
                                <ButtonRadioElement
                                    {...field}
                                    data-cy="member"
                                    label={t('onboardingFlow.personalPage.member')}
                                    error={errors?.member?.message}
                                    nameData={field.name}
                                    items={option}
                                    passData={(value) => {
                                        if (value) setValue('destinedLife', false);
                                        field.onChange(value);
                                    }}
                                    required
                                />
                            )}
                        />
                        {data?.member && (
                            <p className="data-summary-item-info">
                                {t('onboardingFlow.personalPage.dateOfExpiry')}
                                {' '}
                                {getFormattedDate(generateData())}
                            </p>
                        )}
                    </div>

                    <div className="data-summary">
                        <Controller
                            name="destinedLife"
                            control={control}
                            render={({ field }) => (
                                <ButtonRadioElement
                                    {...field}
                                    data-cy="destinedLife"
                                    label={t('onboardingFlow.personalPage.destinedLife')}
                                    error={errors?.destinedLife?.message}
                                    nameData={field.name}
                                    items={option}
                                    passData={(value) => {
                                        if (value) setValue('member', false);
                                        field.onChange(value);
                                    }}
                                    required
                                />
                            )}
                        />
                        {data?.destinedLife && (
                            <p className="data-summary-item-info">
                                {t('onboardingFlow.personalPage.dateOfExpiry')}
                                {' '}
                                {getFormattedDate(generateData())}
                            </p>
                        )}
                    </div>

                    {hasAccessPolicy(ACCESS_POLICY.EnableZurichEmployeeToggle) && (
                        <div className="data-summary">
                            <Controller
                                name="zurichMA"
                                control={control}
                                render={({ field }) => (
                                    <ButtonRadioElement
                                        {...field}
                                        data-cy="zurichMA"
                                        label={t('onboardingFlow.personalPage.zurichMA')}
                                        error={errors?.zurichMA?.message}
                                        nameData={field.name}
                                        items={option}
                                        passData={field.onChange}
                                        required
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
}

export default PersonalDetailsForm;
