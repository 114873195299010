import React, {
    forwardRef, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../utils';
import './Checkbox.css';
import { CHECKBOX_SIZE_DEFAULT, CHECKBOX_SIZES } from './constants';
import withTheme from '../../hocs/withTheme';
import uid from '../../utils/uid';
import FormControlMessage from '../FormControlMessage/FormControlMessage';

function Checkbox(props) {
    const {
        error, label, isInQuestionnaire, description, checked, onChange, innerRef: ref,
        defaultChecked, disabled, size, id, ...rests
    } = props;

    const [value, setValue] = useState(checked || defaultChecked);

    useEffect(() => {
        setValue(checked || defaultChecked);
    }, [checked, setValue]);

    const classNameCheckboxWrapper = getClassNames(
        'checkbox-wrapper',
        { [`checkbox-${size}`]: size },
        { 'checkbox-error': error },
        { 'checkbox-wrapper-disabled': disabled },
    );

    const classNameCheckbox = getClassNames(
        'checkbox',
        { 'checkbox-checked': value },
    );

    const onChangeHandlar = useCallback((event) => {
        setValue(event.target.checked);
        if (typeof onChange === 'function') {
            onChange(event);
        }
    }, [onChange, setValue]);

    const uIdCheckbox = id || uid('checkbox');

    return (
        <div className={getClassNames('checkbox', { 'checkbox-wrapper-disabled': disabled, 'form-group_invalid': error })}>
            <input
                {...rests}
                ref={ref}
                type="checkbox"
                tabIndex={0}
                aria-checked={value}
                className="checkbox-input"
                onChange={onChangeHandlar}
                checked={value}
                disabled={disabled}
                id={uIdCheckbox}
            />
            <div className="checkbox-inner">
                <label className="checkbox-label" htmlFor={uIdCheckbox}>
                    <span className={label?.includes('\n') ? 'label_with_new_line' : ''}>{label}</span>
                </label>
                {description && <span className="checkbox-description">{description}</span>}
                {error && !isInQuestionnaire && <FormControlMessage>{error}</FormControlMessage>}
            </div>

        </div>
    );
}

Checkbox.propTypes = {
    /** Define a checkbox label */
    label: PropTypes.string.isRequired,
    /** Define a checkbox description */
    description: PropTypes.string,
    /** Get focus when component mounted */
    autoFocus: PropTypes.bool,
    /** The size of checkbox */
    size: PropTypes.oneOf(CHECKBOX_SIZES),
    /** Disable checkbox */
    disabled: PropTypes.bool,
    /** Specifies the initial state: whether or not the checkbox is selected */
    defaultChecked: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    /** Enable error view */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** @ignore */
    id: PropTypes.string,
    /** The callback function that is triggered when the state changes. Function(e:Event) */
    onChange: PropTypes.func,
    /** Specifies whether the checkbox is selected. */
    checked: PropTypes.bool,
    /** @ignore */
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.objectOf,
        }),
    ]),
    /** Specifies whether the checkbox will show an error message. If false it will,
     * if true it will not since one message for all checkboxes will be shown */
    isInQuestionnaire: PropTypes.bool
};

Checkbox.defaultProps = {
    description: null,
    autoFocus: false,
    size: CHECKBOX_SIZE_DEFAULT,
    disabled: false,
    defaultChecked: false,
    error: false,
    checked: false,
    onChange: (event) => event,
    innerRef: null,
    isInQuestionnaire: false,
    id: '',
};

export default forwardRef((props, ref) => withTheme(Checkbox)({ ...props, innerRef: ref }));
