import { useCallback, useMemo, useState } from 'react';
import { SELECETED_PRODUCT_TYPE } from 'pages/ClientOverview/constants';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import { THREEBTYPES } from 'constants/constants';
import useCommonAllocationDataProvider from '../../context/useCommonAllocationDataProvider.';

const nonThreeBSteps = [
    { number: 1, name: 'portfolio.changeStrategy.personalDetails' },
    { number: 2, name: 'portfolio.changeStrategy.investorProfile' },
    { number: 3, name: 'portfolio.changeStrategy.strategy' },
    { number: 4, name: 'portfolio.changeStrategy.overview' },
];

const threeBSteps = {
    advisoryDocument: 'portfolio.createNewPortfolio.advisoryDocument',
    personalDetails: 'portfolio.changeStrategy.personalDetails',
    investorProfile: 'portfolio.changeStrategy.investorProfile',
    strategy: 'portfolio.changeStrategy.strategy',
    withdrawalPlan: 'portfolio.createNewPortfolio.withdrawalPlan',
    overview: 'portfolio.changeStrategy.overview',

};

export const getSteps = ({ isThreeB, productExternalId }) => {
    const { steps3b } = useChangeStrategy3bStepper(productExternalId);

    return isThreeB ? steps3b : nonThreeBSteps;
};

export const CHANGE_STRATEGY_CACHE_KEY = 'CHANGE_STRATEGY_NEW_DATA';

export const useChangeStrategy3bStepper = (productExternalId) => {
    const { setupWithdrawalPlan: isWithdrawal } = useCommonAllocationDataProvider();
    const { isAzp } = useThreeBProductType({ product: { externalId: global.sessionStorage.getItem(SELECETED_PRODUCT_TYPE) } });

    const [overviewStepNumber, setOverviewStepNumber] = useState();
    const addStep = useCallback((stepAkku, stepName) => {
        const step = { number: stepAkku.length + 1, name: stepName };

        stepAkku.push(step);

        return step;
    }, []);

    const steps3b = useMemo(() => {
        const stepAkku = [];

        if (productExternalId !== THREEBTYPES.zifd) {
            addStep(stepAkku, threeBSteps.advisoryDocument);
        }

        addStep(stepAkku, threeBSteps.personalDetails);
        addStep(stepAkku, threeBSteps.investorProfile);
        addStep(stepAkku, threeBSteps.strategy);

        if (isWithdrawal || isAzp) {
            addStep(stepAkku, threeBSteps.withdrawalPlan);
        }

        const { number: overviewNumber } = addStep(stepAkku, threeBSteps.overview);

        setOverviewStepNumber(overviewNumber);

        return stepAkku;
    }, [isWithdrawal, isAzp]);

    return { steps3b, overviewStepNumber };
};
