import React from 'react';
import PropTypes from 'prop-types';
import { createUniqueKey } from '../../../utils';

function Legend(props) {
    const { dataExternal, legendLabels } = props;

    return (
        <div className="projection-legend">
            {
                dataExternal?.map(
                    (item, index) => (
                        <div className={`projection-legend-item legend-external-item-${index}`} key={createUniqueKey(index, item.name)}>
                            <div className="legend-point" />
                            <div className="legend-name">
                                {item.name}
                                {": "}
                                <span className='legend-value'>{item?.value}</span>
                            </div>
                        </div>
                    ),
                )
            }
            {
                legendLabels?.map(
                    (item, index) => (
                        <div className={`projection-legend-item legend-item-${index}`} key={createUniqueKey(index, item)}>
                            <div className="legend-point" />
                            <div className="legend-name">
                                {item.name}
                                {": "}
                                <span className='legend-value'>{item?.value}</span>
                            </div>
                        </div>
                    ),
                )
            }
        </div>
    );
}

Legend.propTypes = {
    dataExternal: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
    })),
    legendLabels: PropTypes.arrayOf(PropTypes.string),
};

Legend.defaultProps = {
    dataExternal: [],
    legendLabels: [],
};

export default Legend;
