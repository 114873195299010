import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    isAnyOfThreeBProductTypes,
} from 'hooks/isThreeBProduct';
import useCommonAllocationDataProvider
    from 'pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import {PRODUCTS} from 'constants/constants';
import Preloader from 'components/Preloader';
import {
    useAdvisoryDocument,
} from 'pages/ClientOverview/pages/Portfolios/pages/ChangeStrategyLegacy/hooks/useAdvisoryDocument';
import SM from 'services/ServiceManager';
import {useContact} from 'domain/ClientService';
import {formatDate} from 'utils/datetime';
import {getModel} from 'datasource/usePersonalDetails/models/getModel';
import {useFormatting} from 'locale';
import {isEmpty} from 'lodash';
import {useProductsList} from 'hooks/useProducts';
import {usePersonalDetails} from '../hooks/usePersonalDetails';
import ChangeStrategyContext from './context';
import {useRiskProfile} from '../hooks/useRiskProfile';
import {useStrategy} from '../hooks/useStrategy';
import {usePortfolio} from '../../../../../../../domain/Portfolio';
import {usePlanWizardLayout} from '../../../components/PlanWizardLayout';
import {useModelList} from '../../../hooks/useModelList';

const ChangeStrategyProvider = ({
    clientId,
    portfolioId,
    productKey,
    children,
}) => {
    const [newProductExternalId, setNewProductExternalId] = useState();
    const {getFormattedDate} = useFormatting();
    const {portfolio: {dataRaw: portfolio, isLoading}} = usePortfolio();

    const {
        products,
        getProducts,
    } = useProductsList();

    const productId = useMemo(() => {
        if (productKey) {
            if (products.length) {
                const newProd = products.find((prod) => prod?.settings?.productKey?.toLowerCase() === productKey?.toLowerCase());

                if (portfolio?.Product?.Id !== newProd?.id) {
                    setNewProductExternalId(newProd?.externalId);
                }

                return newProd?.id;
            }

            return undefined;
        }

        return portfolio?.Product?.Id;
    }, [productKey, products, portfolio?.Product?.Id]);

    const layout = usePlanWizardLayout();
    const personalDetails = usePersonalDetails();
    const advisoryDocument = useAdvisoryDocument();
    const riskProfile = useRiskProfile();
    const strategy = useStrategy({portfolioId: portfolio?.Id});
    const {strategies} = useModelList({productId});
    const {setSelectedProductType} = useCommonAllocationDataProvider();
    const [portfolioOwners, setPortfolioOwners] = useState([]);
    const [mainOwnerDetails, setMainOwnerDetails] = useState(null);
    const [contactGroupAddress, setContactGroupAddress] = useState(null);
    const [isLoadingJointAccountPersonalData, setLoadingJointAccountPersonalData] = useState(false);
    const [jointAccountDetails, setJointAccountDetails] = useState(null);

    const {getContact} = useContact({contactId: null});

    useEffect(() => {
        if (productKey) {
            getProducts();
        }
    }, [productKey]);

    useEffect(() => {
        if (portfolio?.RiskCategory?.Name) layout.setData({risk: portfolio?.RiskCategory?.Name});
    }, [portfolio?.RiskCategory?.Name]);

    const isThreeBProduct = useMemo(() => isAnyOfThreeBProductTypes(portfolio?.Product?.ExternalId), [portfolio?.Product?.ExternalId]);
    const isJointAccount = useMemo(() => portfolioOwners.length === 2, [portfolioOwners.length]);
    const jointAccountClientId = useMemo(() => portfolioOwners.find(f => f.id !== mainOwnerDetails?.clientId)?.id, [mainOwnerDetails?.clientId, portfolioOwners]);

    const mainOwner = useMemo(() => {
        const minOwnerId = Math.min(...portfolioOwners.map(i => i.id));

        return portfolioOwners.find(f => f.id === minOwnerId);
    }, [portfolioOwners]);

    const getMainOwnerDetails = useCallback(async () => {
        if (!mainOwner?.id) {
            return;
        }
        const mainOwnerData = await getContact({contactId: mainOwner.id});

        layout.setData({
            clientName: mainOwnerData?.details?.personalInformation?.displayName,
            clientAge: Number(formatDate(new Date(), 'YYYY')) - Number(formatDate(new Date(mainOwnerData?.details?.personalInformation?.dateOfBirth), 'YYYY')),
            isCorrectFlow: true,
        });

        if (getFormattedDate && typeof getFormattedDate === 'function') {
            setMainOwnerDetails(getModel(mainOwnerData, getFormattedDate));
        }
    }, [mainOwner?.id, getFormattedDate]);

    const getContactGroupDetails = useCallback(async () => {
        if (!portfolio?.ContactGroupId) {
            return;
        }
        const response = await SM.customClientService('getContactGroupDetails', [{groupId: portfolio?.ContactGroupId}]);

        const owners = Object.keys(response.data?.owners)?.map(key => ({
            id: parseInt(key, 10),
            name: response.data?.owners?.[key],
        }));

        setContactGroupAddress(response.data?.contactGroupAddress);
        setPortfolioOwners(owners);
    }, [portfolio?.ContactGroupId]);

    const getJointAccountDetails = useCallback(async () => {
        try {
            if (!isJointAccount || !jointAccountClientId || !isEmpty(jointAccountDetails)) {
                return;
            }
            setLoadingJointAccountPersonalData(true);

            const data = await getContact({contactId: jointAccountClientId});

            if (typeof getFormattedDate === 'function') {
                setJointAccountDetails(getModel(data, getFormattedDate));
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingJointAccountPersonalData(false);
        }
    }, [isJointAccount, portfolioOwners, jointAccountClientId, getFormattedDate]);

    useEffect(() => {
        setSelectedProductType(isThreeBProduct ? PRODUCTS.bbb : null);
    }, [isThreeBProduct]);

    useEffect(() => {
        (async () => getContactGroupDetails())();
    }, [getContactGroupDetails]);

    useEffect(() => {
        (async () => getMainOwnerDetails())();
    }, [getMainOwnerDetails]);

    useEffect(() => {
        (async () => getJointAccountDetails())();
    }, [getJointAccountDetails]);

    const state = {
        clientId,
        portfolioId,
        personalDetails,
        advisoryDocument,
        layout,
        riskProfile,
        strategy,
        strategies,
        isThreeBProduct,
        portfolioOwners,
        isJointAccount,
        contactGroupId: portfolio?.ContactGroupId,
        productDescription: portfolio?.Product?.Description,
        mainOwnerDetails,
        contactGroupAddress,
        getContactGroupDetails,
        jointAccountDetails,
        productId,
        newProductExternalId,
    };

    return (
        <ChangeStrategyContext.Provider
            value={{...state}}
        >
            <Preloader isLoading={isLoading || isLoadingJointAccountPersonalData}>
                {children}
            </Preloader>
        </ChangeStrategyContext.Provider>
    );
};

ChangeStrategyProvider.propTypes = {
    clientId: PropTypes.number.isRequired,
    portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    productKey: PropTypes.string,
};

ChangeStrategyProvider.defaultProps = {
    productKey: undefined,
};

export default ChangeStrategyProvider;
