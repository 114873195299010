import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import { validateInputString, validateSelect } from 'validation/validation';
import React from 'react';
import {
    Checkbox, FormGroup, RadioButtonGroup, Title,
} from 'ui-library';
import { ACCESS_POLICY } from 'constants/constants';
import { useManagerProfile } from 'prodivers/managerProfile';
import { generateData } from './utils/generateData';

import 'ui-library/components/Button/Button.css';
import 'ui-library/components/DatePicker/DatePicker.css';
import 'ui-library/components/Label/Label.css';
import 'ui-library/components/TextInput/TextInput.css';
import { EditablePersonalData } from '../../../../components/EditablePersonalData';
import './PersonalDetails.css';

function PersonalDetailsForm({
    data, editPersonalData, errorData, t, optionCountry, optionGender, setData, onDateClick, passData, setErrorData, changeMobileMask, productSettings, radioButtonGroupValues, showEmploymentContinuationCheck, retiredRef, onRetiredButStillEmployedChange, occupationListOptions, handleSearchOccupations, searchInProgress, loadMoreItems, hasMoreOccupations, option, getFormattedDate, isJA = false,
}) {
    const { hasAccessPolicy } = useManagerProfile();

    return (
        <div className="personalDetails__singleForm" style={!isJA ? { border: 'none' } : {}}>
            <EditablePersonalData
                data={data}
                editPersonalData={editPersonalData}
                errorData={errorData}
                t={t}
                optionCountry={optionCountry}
                optionGender={optionGender}
                setData={setData}
                onDateClick={onDateClick}
                passData={passData}
                setErrorData={setErrorData}
                changeMobileMask={changeMobileMask}
            />

            { productSettings?.isDependantOnAffiliatedWithPensionFund
    && (
        <Title type={2}>
            {t('onboardingFlow.personalPage.subheader')}
        </Title>
    )}

            <div className="personalDetailsTable data-summary-wrapper">

                <div className="form">
                    { productSettings?.isDependantOnAffiliatedWithPensionFund
           && (
               <>
                   <fieldset className="form-group">
                       <legend
                           className="inputLabel"
                       >
                           {t('onboardingFlow.personalPage.affiliatedPensionFund')}
                       </legend>
                       <RadioButtonGroup
                           data-cy="affiliatedPensionFund"
                           value={data?.affiliatedPensionFund}
                           options={radioButtonGroupValues}
                           onChange={(e) => {
                               setData({ ...data, affiliatedPensionFund: `${e?.target?.value}` });
                               setErrorData({
                                   ...errorData,
                                   affiliatedPensionFund: validateInputString(e?.target?.value),
                               });
                           }}
                           error={errorData?.affiliatedPensionFund}
                           required
                       />

                   </fieldset>
                   {showEmploymentContinuationCheck && (
                       <fieldset>
                           <legend
                               className="inputLabel"
                           >
                               {t('onboardingFlow.personalPage.retiredButStillEmployed')}
                           </legend>
                           <Checkbox
                               ref={retiredRef}
                               label={t('onboardingFlow.personalPage.retiredButStillEmployedLabel')}
                               checked={data?.employmentContinuation}
                               onChange={onRetiredButStillEmployedChange}
                               error={errorData?.employmentContinuation}
                           />
                       </fieldset>
                   )}
               </>
           )}
                    <FormGroup className="form-group--half">
                        <SelectElement
                            data-cy="occupation"
                            label={t('onboardingFlow.personalPage.occupation')}
                            placeholder={t('onboardingFlow.personalPage.occupation')}
                            options={occupationListOptions}
                            value={String(data?.occupation)}
                            onChange={(val) => {
                                setData({ ...data, occupation: `${val}` });
                                setErrorData({
                                    ...errorData,
                                    occupation: validateSelect(val),
                                });
                            }}
                            error={errorData?.occupation}
                            required
                            hasSearch
                            asyncSearch={handleSearchOccupations}
                            searchInProgress={searchInProgress}
                            loadMoreItems={loadMoreItems}
                            hasMoreItems={hasMoreOccupations}
                        />
                    </FormGroup>
                    <fieldset className="form-group--multiple">
                        <Title type={4} tagName="legend">
                            {t('onboardingFlow.personalPage.domicileDeclaration')}
                        </Title>
                        <SelectElement
                            data-cy="taxDomicile"
                            label={t('onboardingFlow.personalPage.taxDomicile')}
                            placeholder={t('onboardingFlow.personalPage.taxDomicile')}
                            options={optionCountry}
                            value={String(data?.taxDomicile)}
                            onChange={(val) => {
                                setData({ ...data, taxDomicile: `${val}` });
                                setErrorData({
                                    ...errorData,
                                    taxDomicile: validateSelect(val),
                                });
                            }}
                            error={errorData?.taxDomicile}
                            required
                            hasSearch
                        />
                        <SelectElement
                            data-cy="riskDomicile"
                            label={t('onboardingFlow.personalPage.riskDomicile')}
                            placeholder={t('onboardingFlow.personalPage.riskDomicile')}
                            options={optionCountry}
                            value={String(data?.riskDomicile)}
                            onChange={(val) => {
                                setData({ ...data, riskDomicile: `${val}` });
                                setErrorData({
                                    ...errorData,
                                    riskDomicile: validateSelect(val),
                                });
                            }}
                            error={errorData?.riskDomicile}
                            required
                            hasSearch
                        />
                    </fieldset>
                </div>
            </div>

            <Title type={2}>
                {t('onboardingFlow.personalPage.subheaderSecond')}
            </Title>
            <div className="personalDetailsTable data-summary-wrapper">
                <div className="personalDetailsTable data-summary-wrapper">
                    <div className="data-summary">
                        <ButtonRadioElement
                            data-cy="member"
                            label={t('onboardingFlow.personalPage.member')}
                            value={data?.member}
                            error={errorData?.member}
                            nameData="member"
                            items={option}
                            passData={passData}
                            required
                        />
                        {data?.member && (
                            <p className="data-summary-item-info">
                                {t('onboardingFlow.personalPage.dateOfExpiry')}
                                {' '}
                                {getFormattedDate(generateData())}
                            </p>
                        )}
                    </div>

                    <div className="data-summary">
                        <ButtonRadioElement
                            data-cy="destinedLife"
                            label={t('onboardingFlow.personalPage.destinedLife')}
                            value={data?.destinedLife}
                            error={errorData?.destinedLife}
                            nameData="destinedLife"
                            items={option}
                            passData={passData}
                            required
                        />
                        {data?.destinedLife && (
                            <p className="data-summary-item-info">
                                {t('onboardingFlow.personalPage.dateOfExpiry')}
                                {' '}
                                {getFormattedDate(generateData())}
                            </p>
                        )}
                    </div>

                    {hasAccessPolicy(ACCESS_POLICY.EnableZurichEmployeeToggle) && (
                        <div className="data-summary">
                            <ButtonRadioElement
                                data-cy="zurichMA"
                                label={t('onboardingFlow.personalPage.zurichMA')}
                                value={data?.zurichMA}
                                error={errorData?.zurichMA}
                                nameData="zurichMA"
                                items={option}
                                passData={passData}
                                required
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PersonalDetailsForm;
