import { validateData } from 'utils/formatting';

export const adapt = ({ data, getFormattedNumber, getFormattedDate }) => {
    const getCustomAttributes = (value) => {
        try {
            return JSON.parse(value);
        } catch {
            return null;
        }
    };

    return data?.Positions?.map((item) => {
        const attributes = getCustomAttributes(item.CustomAttributes);

        return {
            name: validateData(item?.Security?.Name),
            currency: validateData(item?.Security?.Currency?.CurrencyCode),
            units: getFormattedNumber(item?.Quantity, {
                maximumFractionDigits: 6,
                minimumFractionDigits: 6,
            }),
            rateDate: getFormattedDate(attributes?.RateDate),
            performancePtge: attributes?.PerformancePtge ? `${getFormattedNumber(attributes?.PerformancePtge, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })}%` : '0.00%',
            buyPrice: typeof item?.BuyPrice === 'number' ? getFormattedNumber(item?.BuyPrice, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }) : '0.00',
            valuation: getFormattedNumber(item?.ValuationPrice, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
            pl: typeof item?.MonetaryPerformance === 'number' ? `${getFormattedNumber(item?.MonetaryPerformance * 100, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
            })}%` : '0.0%',
            allocation: `${getFormattedNumber(item?.Allocation * 100, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
            })}%`,
            marketValue: getFormattedNumber(item?.InvestmentValueSecurityCurrency, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }),
        };
    });
};
