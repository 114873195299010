import PropTypes from 'prop-types';
import React from 'react';
import { formatCurrency, formatProjectionDate } from 'utils/formatting';

const ProjectionTooltip = ({
    point: {
        x,
        y,
    },
    series: { name },
    currency,
    t,
    getAmountWithDecimals,
}) => (
    <div>
        <div className="tooltip-row">
            {name}
            :
            <span className="value">
                {getAmountWithDecimals
                    ? getAmountWithDecimals(y, currency)
                    : formatCurrency(y, currency)}
            </span>
        </div>
        <div className="tooltip-row">
            {formatProjectionDate(x, t)}
        </div>
    </div>
);

ProjectionTooltip.propTypes = {
    point: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
    series: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    currency: PropTypes.string,
    t: PropTypes.func,
    getAmountWithDecimals: PropTypes.func,
};

ProjectionTooltip.defaultProps = {
    currency: '',
    t: (str) => str,
    getAmountWithDecimals: () => {
    },
};

export default ProjectionTooltip;
