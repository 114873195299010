import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClientId } from 'utils';
import { JAUsersType } from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { useGetDocumentsByContactId } from 'hooks/rest/useGetDocumentsByContactId';
import { useGetAdvisoryDocumentByAdvisoryId } from 'hooks/rest/useGetAdvisoryDocumentByAdvisoryId';
import { addUnits, dateFormatZurich, formatDate } from 'utils/datetime';
import { useGetGeneralDocuments } from 'hooks/rest/useGetGeneralDocuments';
import { useGetProofOfAssetsDocs } from 'hooks/rest/useGetProofOfAssetsDocs';
import { useGetAdvisory } from 'hooks/rest/useGetAdvisory';
import { useGetDocumentsByContainerId } from 'hooks/rest/useGetDocumentsByContainerId';

export const use3bApplicationDetails = ({
    investmentAppsData,
}) => {
    const { containerId, dfsClientId } = useParams();
    const { i18n: { language } } = useTranslation();

    const [hasMounted, setHasMounted] = useState(false);

    const contactId = getClientId(dfsClientId);

    const advisoryDocId = useMemo(() => investmentAppsData?.find(appData => appData?.investmentDescription?.advisoryDocumentId)?.investmentDescription?.advisoryDocumentId, [investmentAppsData]);
    const isAdvisoryCase = !!advisoryDocId;
    const { data: advisoryDetailsData, isLoading: isAdvisoryDataLoading } = useGetAdvisory({ id: advisoryDocId });

    const isOldApplicationWithAdvisory = useMemo(() => advisoryDetailsData?.status === 1 && advisoryDetailsData?.metadata === null, [advisoryDetailsData?.status, advisoryDetailsData?.metadata]);
    const isNonAdvisoryOldApplicationWithContainerId = useMemo(() => investmentAppsData?.some(application => application?.investmentDescription?.kycDone === false), [investmentAppsData]);
    const showGeneralDocumentsAndProofOfAssetsSection = useMemo(() => !isOldApplicationWithAdvisory && !isNonAdvisoryOldApplicationWithContainerId && !!containerId, [isOldApplicationWithAdvisory, isNonAdvisoryOldApplicationWithContainerId, containerId]);
    const {
        data: applicationsDocumentsData,
        isLoading: isApplicationDocsLoading,
    } = useGetDocumentsByContainerId({ containerId });

    const {
        data: oldApplicationsDocumentsData,
        isLoading: isOldApplicationDocsLoading,
    } = useGetDocumentsByContactId({ investmentApplicationId: investmentAppsData?.[0]?.investmentApplicationId, contactId, isEnabled: !containerId });

    const {
        data: documentDataForOldAdvisory,
        isLoading: isDocumentDataForOldAdvisoryLoading,
    } = useGetAdvisoryDocumentByAdvisoryId({ advisoryId: advisoryDocId, isEnabled: isOldApplicationWithAdvisory });

    const {
        data: generalDocumentsData,
        isLoading: isGetGeneralDocumentsLoading,
    } = useGetGeneralDocuments({ containerId, language, isEnabled: showGeneralDocumentsAndProofOfAssetsSection && hasMounted });

    useEffect(() => {
        if (!isAdvisoryDataLoading && investmentAppsData) {
            setHasMounted(true);
        }
    }, [isAdvisoryDataLoading, investmentAppsData]);

    const contactGroupId = investmentAppsData?.[0]?.investmentDescription?.contactGroupId;
    const isJA = investmentAppsData?.[0]?.isJointAccount;
    const jaUsers:JAUsersType = useMemo(() => {
        const owners = investmentAppsData?.[0]?.owners;

        return Object.keys(investmentAppsData?.[0]?.owners).map((key) => ({
            id: parseInt(key, 10),
            fullName: owners[key],
        }));
    }, [isJA]);
    const {
        data: proofOfAssetsDocsData,
        isLoading: isProofOfAssetsDocsDataLoading,
    } = useGetProofOfAssetsDocs({
        contactGroupId, containerId, language, isEnabled: showGeneralDocumentsAndProofOfAssetsSection && hasMounted,
    });

    const advisoryData = useMemo(() => {
        const sumOfPlannedInvestmentAmounts = investmentAppsData.reduce(
            (akku, item) => {
                const changeInvestmentAmount = item?.investmentDescription?.changeInvestmentAmount;

                if (item?.changePlan) {
                    if (changeInvestmentAmount) {
                        return changeInvestmentAmount?.isSell ? (akku - changeInvestmentAmount?.value) : (akku + changeInvestmentAmount?.value);
                    }

                    return akku;
                }

                return akku + (item?.plannedInvestmentAmount ?? 0);
            },
            0,
        );

        return {
            advisoryNo: advisoryDetailsData?.advisoryNumber ?? '-',
            createdAt: advisoryDetailsData?.date ? formatDate(advisoryDetailsData?.date, dateFormatZurich) : '-',
            validTill: advisoryDetailsData?.date ? addUnits(advisoryDetailsData?.date, '1', 'y', dateFormatZurich) : '-',
            investmentAmount: sumOfPlannedInvestmentAmounts,
        };
    }, [
        investmentAppsData, advisoryDetailsData?.advisoryNumber,
        advisoryDetailsData?.date,
    ]);

    return {
        isJA,
        jaUsers,
        isApplicationDocsLoading: isApplicationDocsLoading || isOldApplicationDocsLoading,
        isGetGeneralDocumentsLoading,
        isProofOfAssetsDocsDataLoading,
        isAdvisoryCase,
        generalDocumentsData,
        contactGroupId,
        proofOfAssetsDocsData,
        advisoryDocId,
        advisoryDetailsData,
        advisoryData,
        applicationsDocumentsData: applicationsDocumentsData || oldApplicationsDocumentsData,
        documentDataForOldAdvisory,
        isDocumentDataForOldAdvisoryLoading,
        showGeneralDocumentsAndProofOfAssetsSection,
    };
};
