import moment from 'moment';

type AdditionalData = {
    firstName?: string;
    lastName?: string;
    isAzp?: boolean;
  }

export const getModel = (data, additionalData? : AdditionalData) => {
    const { firstName, lastName, isAzp } = additionalData || {};
    const presentationData = {
        amount: isAzp ? data?.withdrawal?.investmentAmount : data?.withdrawal?.withdrawalAmount,
        frequency: data?.withdrawal?.periodicity.toString(),
        targetPortfolio: data?.withdrawal?.targetPortfolio?.toString(),
        startDate: moment(data?.withdrawal?.startDate).format('MM/YYYY'),
        withdrawalsStartDate: data?.withdrawal?.startDate,
        iban: data?.paymentInstruction?.iban ?? '',
        firstName: data?.paymentInstruction?.firstName ?? firstName ?? '',
        lastName: data?.paymentInstruction?.lastName ?? lastName ?? '',
        bankName: data?.paymentInstruction?.bankName ?? '',
        zipCode: data?.paymentInstruction?.zipCity ?? '',
        city: data?.paymentInstruction?.city ?? '',
        duration: data?.withdrawal?.duration,
    };

    return presentationData;
};
