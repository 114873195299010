import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getClientId } from 'utils';

import { validateIban } from 'utils/validation';
import { ErrorData, UseWithdrawalPlanReturn } from 'widgets/WithdrawalPlan/types';
import { useWithdrawalPlanBase } from 'widgets/WithdrawalPlan/hooks/useWithdrawalPlanBase';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import { useProductsList } from 'hooks/useProducts';

import HandlerError from '../../../errors/HandlerError';
import handlerRequestCanceling from '../../../utils/handlerRequestCanceling';
import {
    useCreatePortfolioSelectors,
} from '../../../datasource/CreatePortfolio';

interface UseShoppingCartWithdrawalPlanReturn extends UseWithdrawalPlanReturn {
    onContinue: (data?: any) => void;
}

export const useWithdrawalPlanChangePlan = ({
    portfolio, withdrawalOption, paymentInstruction, onChangeStrategy,
}): UseShoppingCartWithdrawalPlanReturn => {
    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);
    const [pageErrors, setPageErrors] = useState<ErrorData | null>(null);
    const [submitting, setSubmitting] = useState(false);

    const { getProducts, products } = useProductsList(contactId);

    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    const selectedProduct = products?.find(
        ({ id }) => portfolio?.existingProduct?.id === id,
    );

    const productSettings = useMemo(
        () => selectedProduct?.settings,
        [selectedProduct],
    );

    const {
        currentClientSelector,
    } = useCreatePortfolioSelectors();

    const {
        isAzp, isZiv, isZifd, isPk, isAzpI, isAzpD,
    } = useThreeBProductType({ product: portfolio?.existingProduct });

    const {
        data,
        setData,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        trigger,
        setErrorData,
        isRegularTransferPlan,
        setIsRegularTransferPlan,
        memberId,
        option,
        isAtLeastOneProductOpened,
        setLoading,
        isLoading,
        optionWithdrawalPeriodicity,
        isOptionsLoading,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setPlanCalculationLoading,
        control,
        handleSubmit,
        register,
    } = useWithdrawalPlanBase({
        isAzp,
        isPk,
        isZiv,
        isZifd,
        productSettings,
        modelPortfolioId: portfolio?.modelPortfolioId?.toString() || '',
        strategyId: portfolio?.investmentApplication?.investmentDescription?.selectStrategy?.strategyId?.toString() || '',
        investmentApplicationId: portfolio?.investmentApplication?.investmentApplicationId?.toString(),
        productExternalId: portfolio?.existingProduct?.externalId,
        productConfiguration: portfolio?.investmentApplication?.investmentDescription?.productConfiguration,
        setPageErrors,
    });

    useEffect(() => {
        const firstName = currentClientSelector?.name?.split(' ')[0];
        const lastName = currentClientSelector?.name?.split(' ')[1];

        const adaptedData = {
            amount: withdrawalOption?.withdrawalAmount?.toFixed(2),
            frequency: withdrawalOption?.periodicity,
            targetPortfolio: withdrawalOption?.targetPortfolio?.toString(),
            startDate: moment(withdrawalOption?.startDate).format('MM/YYYY'),
            withdrawalsStartDate: withdrawalOption?.startDate,
            iban: paymentInstruction?.iban ?? '',
            firstName: paymentInstruction?.firstName ?? firstName ?? '',
            lastName: paymentInstruction?.lastName ?? lastName ?? '',
            bankName: paymentInstruction?.bankName ?? '',
            zipCode: paymentInstruction?.zipCity ?? '',
            city: paymentInstruction?.city ?? '',
            duration: withdrawalOption?.duration,
        };

        setData(adaptedData);
    }, [currentClientSelector?.name, isAzp, withdrawalOption, paymentInstruction]);

    const onContinue = useCallback(
        async (dta) => {
            try {
                const ibanError = await validateIban(dta?.iban);

                if (ibanError) {
                    setErrorData('iban', {
                        message: ibanError as string,
                    });

                    return;
                }

                setSubmitting(true);
                const withdrawalData = {
                    withdrawalOption: true,
                    withdrawal: {
                        withdrawalAmount: dta?.amount,
                        periodicity: dta?.frequency,
                        startDate: moment(data?.startDate, 'MM/YYYY').format(moment.defaultFormatUtc),
                        investmentAmount: dta?.amount,
                        // deferralPhaseDuration: 0,
                        // amountAccount: 0,
                        // amountDeposit: 0,
                        // duration: dta?.duration,
                        // plannedEndDate: '2024-11-26T12:23:09.940Z',
                    },
                    paymentInstruction: {
                        iban: dta?.iban,
                        firstName: dta?.firstName,
                        lastName: dta?.lastName,
                        bankName: dta?.bankName,
                        zipCity: dta?.zipCode,
                        city: dta?.city,
                    },
                };

                onChangeStrategy({ withdrawalPlanData: withdrawalData });
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading,
                    }),
                )(err);
            } finally {
                setSubmitting(false);
            }
        },
        [
            contactId,
            memberId,
            isAzp,
            withdrawalPlanData,
            isRegularTransferPlan,
            setPageErrors,
            portfolio?.investmentApplication?.investmentApplicationId,
            onChangeStrategy,
        ],
    );

    return {
        data,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        isRegularTransferPlan,
        optionWithdrawalPeriodicity,
        isLoading,
        isOptionsLoading,
        productExternalId: portfolio?.existingProduct?.externalId,
        productDesc: portfolio?.investmentApplication?.investmentDescription,
        isAzp,
        isPk,
        isZiv,
        isZifd,
        isAzpI,
        isAzpD,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setData,
        setErrorData,
        trigger,
        option,
        isAtLeastOneProductOpened,
        setIsRegularTransferPlan,
        setPlanCalculationLoading,
        control,
        register,
        onContinue: handleSubmit ? handleSubmit(onContinue) : () => {},
    };
};
