import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon } from 'ui-library';

import './ConsultationBox.css';
import { ICON_LONG_ARROW_RIGHT } from 'ui-library/components/Icon';

export const ConsultationBox = ({ dfsClientId, message = '3b.performance.consultationWarning', showButton = true }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <div className="consultation-box">
            <p>{t(message)}</p>
            {showButton && (
                <Button
                    type="primary"
                    onClick={() => navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy/advisory-document-init?returnUrl=${encodeURI(pathname)}`)}
                >
                    <span className="button_label">{t('3b.performance.advisoryProcessLabel')}</span>
                    <Icon type={ICON_LONG_ARROW_RIGHT} size={24}/>
                </Button>
            )}
        </div>
    );
};
