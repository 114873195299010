import Preloader from 'components/Preloader';
import {withMainLayout} from 'hocs';
import {useOptionList} from 'hooks/useOptionList';
import {IsAllDataNull} from 'validation/validation';
import {
    getSecondJAOwnerId,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/PersonalDetails/utils/getSecondJAOwnerId';
import {
    ConnectionAddressWidget,
} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/PersonalDetails/ConnectionAddressWidget';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import SM from 'services/ServiceManager';
import {Title} from 'ui-library';
import {genarateDFSClientId, getClientId} from 'utils';
import {OPTION_LIST} from 'constants/constants';
import {useNavigate, useParams} from 'react-router-dom';
import {useFormatting} from '../../../../../../../../locale';
import {errorModel} from './models/errorModel';
import {saveModel} from './models/saveModel';

import {sendModel} from './models/sendModel';
import 'ui-library/components/Button/Button.css';
import 'ui-library/components/DatePicker/DatePicker.css';
import 'ui-library/components/Label/Label.css';
import 'ui-library/components/TextInput/TextInput.css';
import {useCreatePortfolio} from '../../context';
import {isThreeBProduct, useThreeBProductType} from 'hooks/isThreeBProduct';
import PersonalDetailsForm from './PersonalDetailsForm';
import usePersonalDetails from './usePersonalDetails';
import JAPersonalDetails from './JAPersonalDetails';

import './PersonalDetails.css';

const PersonalDetails = () => {
    const navigate = useNavigate();
    const {t, i18n: {language}} = useTranslation();
    const {getFormattedDate} = useFormatting();
    const {dfsClientId} = useParams();

    const [continueClicked, setContinueClicked] = useState(false);

    const option = [
        {label: t('clientDashboard.yes'), id: true},
        {label: t('clientDashboard.no'), id: false},
    ];

    const radioButtonGroupValues = [
        {
            label: t('onboardingFlow.personalPage.pensionScheme'),
            value: 'true',
            disabled: false,
            error: false,
        }, {
            label: t('onboardingFlow.personalPage.accountHolder'),
            value: 'false',
            disabled: false,
            error: false,
        },
    ];

    const {
        lists: [optionGender, optionCountry],
    } = useOptionList([OPTION_LIST.gender, OPTION_LIST.country]);
    const {
        newPortfolio, saveNewPortfolio, layout: {setData: setLayoutData, setPageErrors},
    } = useCreatePortfolio();

    const {isJA} = useThreeBProductType(newPortfolio);
    const [JAData, setJAData] = useState();
    const [dfsOwnerClientId, setDFSOwnerClientId] = useState();
    const [dfsChildClientId, setDFSChildClientId] = useState();
    const [contactGroupDetails, setContactGroupDetails] = useState();

    const [contactGroupDetailsError, setContactGroupDetailsError] = useState(null);
    const [isLoadingContactGroupDetails, setLoadingContactGroupDetails] = useState(false);

    const isThreeB = isThreeBProduct(newPortfolio);
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio-legacy`, [dfsClientId]);
    const contactGroupId = useMemo(() => newPortfolio?.applicationData?.investmentDescription?.contactGroupId, [newPortfolio?.applicationData?.investmentDescription?.contactGroupId]);

    const {
        data,
        productSettings,
        showEmploymentContinuationCheck,
        setErrorData,
        setSaveContactDetailsInProgress,
        clientId,
        isLoading,
        isLoadingPersonalData,
        saveContactDetailsInProgress,
        error,
        editPersonalData,
        errorData,
        setData,
        onDateClick,
        passData,
        changeMobileMask,
        retiredRef,
        onRetiredButStillEmployedChange,
        occupationListOptions,
        handleSearchOccupations,
        searchInProgress,
        loadMoreItems,
        hasMoreOccupations,

    } = usePersonalDetails({
        newPortfolio, dfsClientId: dfsOwnerClientId, language, setPageErrors, t, getFormattedDate, isJA,
    });

    const onContinue = useCallback(async () => {
        setContinueClicked(true);
        const errors = errorModel({
            data,
            isDependantOnAffiliatedWithPensionFund: productSettings?.isDependantOnAffiliatedWithPensionFund,
            showEmploymentContinuationCheck,
        });

        setErrorData(errors);
        if (IsAllDataNull(errors) === false) {
            return;
        }
        if (isJA && IsAllDataNull(JAData?.errors) === false) {
            return;
        }
        /* Update user details */

        try {
            setSaveContactDetailsInProgress(true);
            await SM.clientService('patchContact', [clientId, sendModel(data)]);

            let tempData = data;

            if (isJA && JAData?.clientId) {
                if (JAData?.clientId < clientId) {
                    tempData = JAData?.data;
                }
                await SM.clientService('patchContact', [JAData?.clientId, sendModel(JAData?.data)]);
            }

            const personalDetailsOnSave = saveModel(tempData);
            const payload = {
                occupation: parseInt(personalDetailsOnSave.occupation, 10),
                memberBevSwitzerland: personalDetailsOnSave.member,
                destinataryVita: personalDetailsOnSave.destinedLife,
                zurichEmployee: personalDetailsOnSave.zurichMA,
                investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId,
            };

            if (productSettings?.isDependantOnAffiliatedWithPensionFund) {
                payload.affiliatedToPensionFund = personalDetailsOnSave.affiliatedPensionFund === 'true';
            }

            const result = await saveNewPortfolio({
                data: payload,
                method: 'savePersonalDetails',
                additionalData: {
                    memberBevSwitzerland: personalDetailsOnSave.member,
                    destinataryVita: personalDetailsOnSave.destinedLife,
                    zurichEmployee: personalDetailsOnSave.zurichMA,
                },
            });

            if (!result) return;
            navigate(`${baseUrl}/investor-profile`);
        } catch (err) {
            setPageErrors(err.message);
        } finally {
            setSaveContactDetailsInProgress(false);
        }
    }, [data, newPortfolio?.applicationData?.investmentApplicationId, productSettings?.isDependantOnAffiliatedWithPensionFund, isJA, JAData]);

    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio-legacy`);
    }, [dfsClientId]);

    useEffect(() => {
        if (isJA && contactGroupId) {
            setLoadingContactGroupDetails(true);
            setContactGroupDetailsError(null);
            const getContactGroupDetails = async () => {
                try {
                    const {data: contactGroupDetailsData} = await SM.customClientService('getContactGroupDetails', [{groupId: contactGroupId}]);

                    setContactGroupDetails(contactGroupDetailsData);
                    if (Object.keys(contactGroupDetailsData?.owners)[1]) {
                        const currentClientId = getClientId(dfsClientId);
                        const jaClientId = getSecondJAOwnerId(contactGroupDetailsData?.owners, currentClientId);

                        setDFSChildClientId(genarateDFSClientId(Math.max(jaClientId, currentClientId)));
                        setDFSOwnerClientId(genarateDFSClientId(Math.min(jaClientId, currentClientId)));
                    }
                } catch (err) {
                    setContactGroupDetailsError(err?.message);
                } finally {
                    setLoadingContactGroupDetails(false);
                }
            };

            getContactGroupDetails();
        } else {
            setDFSOwnerClientId(dfsClientId);
        }
    }, [contactGroupId, dfsClientId, isJA]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: isThreeB ? 4 : 2,
            onBack,
            onContinue,
            disabled: isLoading || isLoadingPersonalData,
        });
    }, [onBack, onContinue, isLoading, isLoadingPersonalData, isThreeB]);

    useEffect(() => {
        setLayoutData({
            saveInProgress: saveContactDetailsInProgress,
        });
    }, [saveContactDetailsInProgress]);

    return (
        <div className="new-portfolio personal-details newStyle">
            <Preloader
                isLoading={isLoading || isLoadingPersonalData || isLoadingContactGroupDetails}
                error={error || contactGroupDetailsError}
            >
                <Title type={1}>
                    {newPortfolio?.product?.description}
                </Title>
                <div className="row">
                    <div className={isJA ? 'col-lg-6' : 'col-lg-12'}>
                        <PersonalDetailsForm
                            data={data}
                            editPersonalData={editPersonalData}
                            errorData={errorData}
                            t={t}
                            optionCountry={optionCountry}
                            optionGender={optionGender}
                            setData={setData}
                            onDateClick={onDateClick}
                            passData={passData}
                            setErrorData={setErrorData}
                            changeMobileMask={changeMobileMask}
                            productSettings={productSettings}
                            radioButtonGroupValues={radioButtonGroupValues}
                            showEmploymentContinuationCheck={showEmploymentContinuationCheck}
                            retiredRef={retiredRef}
                            onRetiredButStillEmployedChange={onRetiredButStillEmployedChange}
                            occupationListOptions={occupationListOptions}
                            handleSearchOccupations={handleSearchOccupations}
                            searchInProgress={searchInProgress}
                            loadMoreItems={loadMoreItems}
                            hasMoreOccupations={hasMoreOccupations}
                            option={option}
                            getFormattedDate={getFormattedDate}
                            isJA={isJA}
                        />
                    </div>
                    {isJA && (

                        <JAPersonalDetails
                            newPortfolio={newPortfolio}
                            language={language}
                            setPageErrors={setPageErrors}
                            t={t}
                            getFormattedDate={getFormattedDate}
                            optionGender={optionGender}
                            optionCountry={optionCountry}
                            radioButtonGroupValues={radioButtonGroupValues}
                            option={option}
                            setJAData={setJAData}
                            dfsClientId={dfsChildClientId}
                            continueClicked={continueClicked}
                        />

                    )}
                </div>
                {isJA && (
                    <ConnectionAddressWidget
                        contactGroupAddress={contactGroupDetails?.contactGroupAddress}
                        isLoading={isLoadingContactGroupDetails}
                        error={contactGroupDetailsError}
                        contactGroupId={contactGroupId}
                    />
                )}
            </Preloader>
        </div>
    );
};

export default withMainLayout(PersonalDetails);
