/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Icon } from 'ui-library';
import { ICON_INFO_SLIM } from 'ui-library/components/Icon';
import './IconWithTooltip.css';
import Tooltip from 'rc-tooltip';

const IconWithTooltip = ({
    description,
    placement = 'top',
    iconSize = 20,
    overlayClassName = '',
    ...rest
}: { description: string; iconSize?: number, overlayClassName?: string; placement?: string; }) => (
    <div className="icon-with-desc">
        <Tooltip
            overlayClassName={`IconWithTooltip-popover Popover ${overlayClassName}`}
            placement={placement}
            trigger="hover"
            arrowContent={<span className="icon-with-tooltip--arrow-content" />}
            overlay={<span className="info-tooltip">{description}</span>}
            destroyTooltipOnHide
            {...rest}
        >
            <Icon type={ICON_INFO_SLIM} size={iconSize}/>
        </Tooltip>
    </div>
);

export default IconWithTooltip;

IconWithTooltip.defaultProps = {
    iconSize: 20,
};
