import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    array, number, object, ObjectSchema,
} from 'yup';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import { AdvisoryRealEstateMetadataPropertyResponse } from 'core/types/api/Api';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useFieldArray, useForm } from 'react-hook-form';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { getPropertyValueMinusMortgage } from 'utils/advisoty';
import { RealEstateInfoFormData } from './useRealEstateData.types';

export const useRealEstateData = () => {
    const { t } = useTranslation();
    const { advisoryIdSelector, advisoryDataSelector, advisoryOptions } = useCreatePortfolioSelectors();
    const { saveAdvisoryDataAction } = useCreatePortfolioActions();
    const [continueButtonLoading, setContinueButtonLoading] = useState(false);

    const realStateData = useMemo(() => advisoryDataSelector?.metadata?.realEstate?.properties || [], [advisoryDataSelector?.metadata?.realEstate?.properties]);

    const schema: ObjectSchema<RealEstateInfoFormData> = useMemo(() => object().shape({
        realEstate: array().of(object().shape({
            propertyListItemId: number().required(t('validation.mandatoryField')).nullable(),
            value: number().required(t('validation.mandatoryField')).nullable(),
            mortgage: number().required(t('validation.mandatoryField')).nullable(),
        })),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: { errors, isDirty }, watch,
    } = useForm({
        resolver,
        defaultValues: {
            realEstate: realStateData?.map(dta => ({
                propertyListItemId: dta?.propertyListItemId,
                value: dta?.value?.toFixed(2),
                mortgage: dta?.mortgage?.toFixed(2),
            })),
        },
    });

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'realEstate',
    });

    const formData = watch();

    const handleAddProperty = useCallback(() => {
        append({
            propertyListItemId: null,
            value: null,
            mortgage: null,
        });
    }, [append]);

    const handleRemoveProperty = useCallback((index: number) => {
        remove(index);
    }, [remove]);

    const advisoryRealEstatePropertyTypes = advisoryOptions?.realEstateProperties || {};

    const getTotalPropertyValue = useCallback(() => getPropertyValueMinusMortgage(formData.realEstate || null), [formData?.realEstate]);

    const saveData = async (data: AdvisoryRealEstateMetadataPropertyResponse[]) => {
        const payload = {
            properties: [...data],
        };

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'real-estate',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        }
    };

    const onSubmit = useCallback(async (dta) => {
        if (isDirty) {
            setContinueButtonLoading(true);

            await saveData(dta?.realEstate?.map(type => ({
                propertyListItemId: type?.propertyListItemId,
                value: +type?.value,
                mortgage: +type?.mortgage,
            })));
        } else {
            setContinueButtonLoading(false);
            unlockNavigationAction();
            stepComplete();
        }
    }, [isDirty]);

    const { stepComplete, unlockNavigationAction } = usePortfolioProcessNavigation({
        saveOnContinue: useCallback(handleSubmit(onSubmit), [onSubmit]),
        continueInProgress: continueButtonLoading,
        enableAutoStepComplete: !isDirty,
    });

    return {
        advisoryRealEstatePropertyTypes,
        fields,
        errors,
        control,
        handleAddProperty,
        handleRemoveProperty,
        getPropertyValueMinusMortgage: getTotalPropertyValue,
    };
};
