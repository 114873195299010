import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { useForm } from 'react-hook-form';
import { number, object, ObjectSchema } from 'yup';
import isNil from 'lodash/isNil';
import { getPropertyValueMinusMortgage } from 'utils/advisoty';
import { getValidationMessage } from 'utils/validation';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import {
    allocationColors,
    CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE,
    DEFAULT_APP_CURRENCY,
    INSTITUTE_LIST_ITEM_ID_FOR_LIFE_INSURANCE,
} from 'constants/constants';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useGetSecurityAllocations } from 'hooks/rest/useGetSecurityAllocations';
import { getLegendTooltip } from 'components/PieChartWIthLegend/helper';
import { DesiredInvestAmountForm } from './useAssetsOverview.types';

const useAssetsOverview = () => {
    const { t, i18n: { language } } = useTranslation();
    const { advisoryIdSelector, advisoryDataSelector } = useCreatePortfolioSelectors();
    const { saveAdvisoryDataAction } = useCreatePortfolioActions();
    const { getFormattedCurrency, getFormattedXAxisFormat } = useFormatting();
    const { numberError } = getValidationMessage();
    const [continueButtonLoading, setContinueButtonLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const advisoryMetaData = advisoryDataSelector?.metadata;

    const showSecurityAllocationChart = useMemo(() => (!!advisoryMetaData?.investments?.includedPortfolios?.length || !!advisoryMetaData?.otherInvestment?.investments?.length), [advisoryMetaData?.investments?.includedPortfolios, advisoryMetaData?.otherInvestment?.investments]);
    const { securityAllocations } = useGetSecurityAllocations({ advisoryId: advisoryIdSelector, enabled: !!advisoryIdSelector && showSecurityAllocationChart });

    const getTotalLoans = useCallback(() => (advisoryMetaData?.incomeLiquidity?.loans?.length ? advisoryMetaData?.incomeLiquidity?.loans?.reduce((acc, curr) => acc + (curr?.amount || 0), 0) : null), [advisoryMetaData?.incomeLiquidity?.loans]);

    const securityAllocationsData = useMemo(() => (securityAllocations?.data ? Object?.keys(securityAllocations?.data)?.map(key => ({
        name: key,
        percent: (securityAllocations?.data?.[key]?.percentage * 100)?.toFixed(2),
        color: allocationColors?.[key],
        value: securityAllocations?.data?.[key]?.value,
        className: key,
        description: getLegendTooltip(key, t),
    })) : []), [securityAllocations?.data, language]);

    const lifeInsuranceZurich = useMemo(() => advisoryMetaData?.otherInvestment?.investments?.filter(investment => investment?.categoryListItemId === CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE && investment?.instituteListItemId === INSTITUTE_LIST_ITEM_ID_FOR_LIFE_INSURANCE), [advisoryMetaData?.otherInvestment?.investments]);
    const lifeInsuranceZurichTotalValues = useMemo(() => (lifeInsuranceZurich?.length ? lifeInsuranceZurich?.reduce((acc, curr) => acc + (curr?.value || 0), 0) : null), [lifeInsuranceZurich]);
    const lifeInsuranceThirdParties = useMemo(() => advisoryMetaData?.otherInvestment?.investments?.filter(investment => investment?.categoryListItemId === CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE && investment?.instituteListItemId !== INSTITUTE_LIST_ITEM_ID_FOR_LIFE_INSURANCE), [advisoryMetaData?.otherInvestment?.investments]);
    const lifeInsuranceThirdPartiesTotalValue = useMemo(() => (lifeInsuranceThirdParties?.length ? lifeInsuranceThirdParties?.reduce((acc, curr) => acc + (curr?.value || 0), 0) : null), [lifeInsuranceThirdParties]);
    const data = useMemo(() => ({
        liquidityForInvestment: { ...advisoryMetaData?.incomeLiquidity, loans: getTotalLoans() },
        pensionSituation: advisoryMetaData?.pensionSituation,
        otherAssets: {
            investDepots: advisoryMetaData?.investments?.includedPortfolios?.reduce((acc, curr) => acc + (curr?.value || 0), 0),
            properties: getPropertyValueMinusMortgage(advisoryMetaData?.realEstate?.properties),
            thirdPartyAssets: advisoryMetaData?.otherInvestment?.investments?.length ? advisoryMetaData?.otherInvestment?.investments?.filter(investment => investment?.categoryListItemId !== CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE)?.reduce((acc, curr) => acc + (curr?.value || 0), 0) : null,
            lifeInsuranceZurich: lifeInsuranceZurichTotalValues,
            lifeInsuranceThirdParties: lifeInsuranceThirdPartiesTotalValue,
        },
        desiredInvestmentAmount: advisoryMetaData?.desiredInvestmentAmount,
        securityAllocations: securityAllocationsData,
    }), [advisoryDataSelector, getTotalLoans, getPropertyValueMinusMortgage, securityAllocations?.data, securityAllocationsData]);

    const totalLiquidityForInvestment = useMemo(() => (data?.liquidityForInvestment?.expectedAssetInflow ?? 0) + (data?.liquidityForInvestment?.totalLiquidity ?? 0) - (data?.liquidityForInvestment?.plannedExpenses ?? 0) - (getTotalLoans() || 0), [data?.liquidityForInvestment]);
    const totalPensionSituation = useMemo(() => (data?.pensionSituation?.pillar3aAmount ?? 0) + (data?.pensionSituation?.lifeInsurance3aVb ?? 0) + (data?.pensionSituation?.otherPillar3aAmount ?? 0) + (data?.pensionSituation?.vbAmount ?? 0) + (data?.pensionSituation?.otherVbAmount ?? 0) + (data?.pensionSituation?.pensionFund ?? 0), [data?.pensionSituation]);
    const totalAmountOfOtherAssets = useMemo(() => ((data?.otherAssets?.investDepots ?? 0) + (data?.otherAssets?.properties ?? 0) + (data?.otherAssets?.thirdPartyAssets ?? 0) + (lifeInsuranceZurichTotalValues ?? 0) + (lifeInsuranceThirdPartiesTotalValue ?? 0)), [data?.otherAssets, lifeInsuranceZurichTotalValues, lifeInsuranceThirdPartiesTotalValue]);
    const schema: ObjectSchema<DesiredInvestAmountForm> = useMemo(() => object().shape({
        desiredInvestmentAmount: number()
            .typeError(numberError)
            .nullable(),
    }), [t]);

    const resolver = useYupValidationResolver(schema);
    const {
        handleSubmit, formState: { errors }, control, reset, watch,
    } = useForm<DesiredInvestAmountForm>({
        resolver,
        defaultValues: { desiredInvestmentAmount: data?.desiredInvestmentAmount || totalLiquidityForInvestment },
    });

    useEffect(() => {
        reset({ desiredInvestmentAmount: data?.desiredInvestmentAmount || totalLiquidityForInvestment });
    }, [data?.desiredInvestmentAmount]);

    const formData = watch();

    const formatValue = useCallback((value?: number, displaySing?: '-' | '+' | '') => {
        if (isNil(value)) {
            return t('assetsOverview.notSpecified');
        }

        const sign = value < 0 ? '-' : displaySing;

        return getFormattedCurrency(value, {
            currency: `${sign ?? ''} ${DEFAULT_APP_CURRENCY}`,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            signDisplay: 'never',
        });
    }, []);

    const closeModal = useCallback(() => {
        setShowModal(false);
        reset();
    }, [reset]);

    const saveData = async (dta) => {
        const payload = { ...dta };

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'asset-overview',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                closeModal();
            }
        }
    };

    const handleInvestmentAmount = useCallback(async (dta) => {
        setContinueButtonLoading(true);

        await saveData({
            calculatedInvestmentAmount: totalLiquidityForInvestment || null,
            desiredInvestmentAmount: dta?.desiredInvestmentAmount,
        });
    }, [saveData, totalLiquidityForInvestment]);

    const onContinue = useCallback(async () => {
        sessionStorage.setItem('totalAmountOfOtherAssets', totalAmountOfOtherAssets.toString());
        await handleInvestmentAmount({
            desiredInvestmentAmount: data?.desiredInvestmentAmount == null ? totalLiquidityForInvestment : data?.desiredInvestmentAmount,
        });
        stepComplete();
    }, [data?.desiredInvestmentAmount, totalLiquidityForInvestment, totalAmountOfOtherAssets]);

    const { stepComplete } = usePortfolioProcessNavigation({
        preventNavigationLock: true,
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
    });

    return {
        data,
        formData,
        control,
        errors,
        showModal,
        closeModal,
        isLoading: continueButtonLoading,
        totalLiquidityForInvestment,
        totalPensionSituation,
        totalAmountOfOtherAssets,
        error: null,
        setShowModal,
        formatValue,
        getFormattedXAxisFormat,
        handleInvestmentAmount: handleSubmit(handleInvestmentAmount),
        showSecurityAllocationChart,
    };
};

export default useAssetsOverview;
