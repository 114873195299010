import React, { useMemo } from 'react';
import {useTranslation} from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import {getBarChartOptions} from './utils';
import {getClassNames} from '../../../utils';
import Legend from './Legend';
import withTheme from '../../../hocs/withTheme';
import './ProjectionChart.css';

function ProjectionChart(props) {
    const {
        min,
        max,
        data,
        dataExternal,
        height,
        className,
        yAxisFormat,
        tooltipFormat,
        yTitle,
        yearsText,
        topLabel,
        bottomLabel,
        middleLabel,
        middleBottomLabel,
        middleTopLabel,
        legendLabels = null,
        returnRates = null,
        colorScheme,
    } = props;
    const containerClassName = getClassNames('ProjectionChart-container', {[`highcharts-${colorScheme}`]: colorScheme});
    const { t } = useTranslation();

    const modifiedLegendLevels = useMemo(() => {
        return returnRates || legendLabels;

    }, [legendLabels, returnRates]);

    return (
        <div className={containerClassName}>
            <HighchartsReact
                containerProps={{
                    className: getClassNames('Highcharts-projection-chart', className),
                }}
                options={getBarChartOptions(
                    min,
                    max,
                    data,
                    dataExternal,
                    height,
                    yAxisFormat,
                    tooltipFormat,
                    {
                        yearsText,
                        yTitle,
                        topLabel,
                        bottomLabel,
                        middleLabel,
                        middleBottomLabel,
                        middleTopLabel,
                    },
                ) as any}
            />
            <Legend dataExternal={dataExternal} legendLabels={modifiedLegendLevels}/>
            <p className="projection-disclaimer">{t('clientDashboard.projectionDisclaimer')}</p>
        </div>
    );
}

export default withTheme(ProjectionChart);
